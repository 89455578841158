import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { onlyNumeric } from '../../../../../../../commonClass/CommonValidation';
import { postUsersData, verifytoken } from '../../../../../../../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../../../../../_actions/loader.actions';
import { ClearLocalStorage } from '../../../../../../../commonClass/Session';
import { hotelRateInventoryOpen } from '../../../../../../../../_actions/user.action';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return date === null ? "" : [day, month, year].join('/');
}

function formFormatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return date === '' ? '' : [year, month, day].join('-');
}

const BlackoutModal = (props) => {
    const {
        title,
        isEdit,
        roomId,
        selectedCellDate,
        RoomAvailabilityDates,
        setOpenModal,
        roomlist,
        mealList,
        dayRangeList,
        HotelBasicId,
        UserId,
    } = props;
    const dispatch = useDispatch();
    const [isSave, setIsSave] = useState(true);
    const [apiError, setApiError] = useState('');

    const [dateRangeValue, setDateRangeValue] = useState('');
    const [closedMessage, setClosedMessage] = useState('')

    useEffect(() => {
        if (selectedCellDate !== null) {
            setDateRangeValue(`${formatDate(new Date(selectedCellDate))}`)
        }
    }, [selectedCellDate])

    const [formValue, setFormValue] = useState({
        RoomType: roomId,
        HotelBasicId: HotelBasicId,
        UserId: UserId,
        FromDateSelect: selectedCellDate || null,
        ToDateSelect: selectedCellDate || null,
        MealType: 0,
        FromDate: formFormatDate(selectedCellDate) + 'T:00:00:00' || null,
        ToDate: formFormatDate(selectedCellDate) + 'T:00:00:00' || null,
        Adult1: "",
        Adult2: "",
        ExtraAdultWithBed: "",
        ChildWithBed: "",
        ChildWithOutBed: "",
        Days: "",
    });
    const [formError, setFormError] = useState({})

    const onChange = (name, value) => {
        setFormError({});
        setIsSave(false);
        setFormValue(form => ({ ...form, [name]: value }))
    }

    const handleDateRange = (update) => {
        setFormError({});
        setIsSave(false);
        setFormValue(form => ({
            ...form,
            FromDate: formFormatDate(update[0]),
            FromDateSelect: update[0],
            ToDate: formFormatDate(update[1]),
            ToDateSelect: update[1],
        }))
        setDateRangeValue(`${formatDate(update[0])} - ${formatDate(update[1])}`);
    }

    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    };

    const onClose = () => {
        isSave ? setOpenModal(false) : setClosedMessage(true);
    }

    const YesProceed = (e) => {
        e.preventDefault()
        setOpenModal(false)
        setClosedMessage(false)
    }

    const NoProceed = (e) => {
        e.preventDefault()
        setClosedMessage(false)
    }

    const validateData = () => {
        let status = true;
        let error = {};

        if (formValue?.FromDateSelect === null || formValue?.ToDateSelect === null) {
            error["date"] = "Cannot leave blank. Input is mandatory.";
            status = false;
        }
        if (formValue?.MealType === 0) {
            error["MealType"] = "Cannot leave blank. Input is mandatory.";
            status = false;
        }
        if (formValue?.Days <= 0) {
            error["Days"] = "Cannot leave blank. Input is mandatory.";
            status = false;
        }
        if (!formValue?.Adult1 || formValue?.Adult1 === 0) {
            error["Adult1"] = "Cannot leave blank. Input is mandatory.";
            status = false;
        }
        if (!formValue?.Adult2 || formValue?.Adult2 === 0) {
            error["Adult2"] = "Cannot leave blank. Input is mandatory.";
            status = false;
        }
        if (!formValue?.ExtraAdultWithBed || formValue?.ExtraAdultWithBed === 0) {
            error["ExtraAdultWithBed"] = "Cannot leave blank. Input is mandatory.";
            status = false;
        }
        if (!formValue?.ChildWithBed || formValue?.ChildWithBed === 0) {
            error["ChildWithBed"] = "Cannot leave blank. Input is mandatory.";
            status = false;
        }
        if (!formValue?.ChildWithOutBed || formValue?.ChildWithOutBed === 0) {
            error["ChildWithOutBed"] = "Cannot leave blank. Input is mandatory.";
            status = false;
        }
        setFormError(error)

        return status;
    }

    const updateBlackout = async () => {
        const blackout = await postUsersData('/master/v1/InsertBulkUpdateInventoryRates', {
            HotelBasicId: formValue?.HotelBasicId,
            HotelRoomId: formValue?.RoomType,
            DayRangesId: formValue?.Days,
            FromDate: formValue?.FromDate,
            ToDate: formValue?.ToDate,
            UserId: formValue?.UserId,
            RatesCollection: [
                {
                    RateTypeId: formValue?.MealType,
                    Adults1: formValue?.Adult1 === "" ? 0 : formValue?.Adult1,
                    Adults2: formValue?.Adult2 === "" ? 0 : formValue?.Adult2,
                    AdultWithBed: formValue?.ExtraAdultWithBed === "" ? 0 : formValue?.ExtraAdultWithBed,
                    ChildWithBed: formValue?.ChildWithBed === "" ? 0 : formValue?.ChildWithBed,
                    ChildWithoutBed: formValue?.ChildWithOutBed === "" ? 0 : formValue?.ChildWithOutBed,
                    IsBlackOut: true
                }
            ]
        });

        if (blackout.status) {
            setOpenModal(false);
            setFormError({})
            setIsSave(true);
            dispatch({ type: 'ALERTING', message: blackout.message });
            dispatch(hotelRateInventoryOpen())
        } else {
            setApiError(blackout.message)
        }
    };

    const handleApply = (e) => {
        e.preventDefault();
        if (validateData()) {
            updateBlackout()
        }
    }
    return (
        <>
            <div className="d-flex justify-content-between align-items-center bulk_update_modal_header">
                <div className="bulk_update_modal_title">
                    {title}
                </div>
                <div role='button' onClick={onClose}>
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>

            {closedMessage &&
                <div className="text-center pb-3">
                    <span className="text-danger">Unsaved data, do you want to apply ? </span>
                    <span role='button' style={{ color: '#0777A9' }} onClick={(e) => YesProceed(e)} > Yes </span>
                    or
                    <span role='button' style={{ color: '#0777A9' }} onClick={(e) => NoProceed(e)} > No</span>.
                </div>
            }
            <form action="" className='py-2'>
                {apiError && <span className='text-danger text-center mt-2'>{apiError}</span>}
                <div className='row'>

                    <div className="col-12 mb-4">
                        <FormControl fullWidth>
                            <Autocomplete
                                clearIcon={false}
                                options={roomlist?.filter((item) => item?.id !== formValue.RoomType)}
                                id="select-roomtype-rates"
                                value={roomlist?.find((obj) => obj?.id === formValue?.RoomType) || null}
                                name='RoomType'
                                onChange={(e, newValue) => onChange("RoomType", newValue?.id)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Room Type" variant="filled"
                                        className={`${formValue?.RoomType <= 0 ? 'red_border' : ''}`}
                                    />
                                )}
                                disabled
                            />
                        </FormControl>
                    </div>

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className={`col-6 lengthfull`}>
                                <div className="text-container">
                                    <TextField
                                        fullWidth
                                        id="select-date"
                                        variant='filled'
                                        label={`Blackout dates`}
                                        // label="Select Date"
                                        value={dateRangeValue}
                                        disabled={selectedCellDate !== null}
                                        onClick={(e) => openDatePicker("selectdate")}
                                        className={`${(formValue?.FromDateSelect !== null && formValue?.ToDateSelect !== null) ? '' : 'red_border'}`}
                                        error={formError?.date ? true : false}
                                        helperText={formError?.date}
                                        autoComplete='off'

                                    />
                                    <div>
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            className='form-control d-none'
                                            id='selectdate'
                                            selected={formValue?.FromDateSelect}
                                            placeholderText="Date Range (From-To)"
                                            selectsRange={true}
                                            autoComplete='off'
                                            showMonthDropdown
                                            startDate={formValue?.FromDateSelect}
                                            endDate={formValue?.ToDateSelect}
                                            onChange={(e) => handleDateRange(e)}
                                            minDate={new Date() < new Date(RoomAvailabilityDates?.StartDate) ? new Date(RoomAvailabilityDates?.StartDate) : new Date()}
                                            maxDate={new Date(RoomAvailabilityDates?.EndDate)}
                                        />
                                    </div>
                                    <span className={`example-custom-input bulkCalendarBtn ${selectedCellDate !== null ? 'disableCalBtn' : ''}`}
                                        onClick={(e) => openDatePicker("select-date")}
                                    ><i className="fa fa-calendar"></i></span>
                                </div>
                            </div>
                            <div className="col-6">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        clearIcon={false}
                                        options={dayRangeList}
                                        id="select-days"
                                        value={dayRangeList?.find((obj) => obj.id === formValue.Days) || null}
                                        name='Days'
                                        onChange={(e, newValue) => onChange("Days", newValue?.id ? newValue?.id : null)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Days" variant="filled"
                                                className={`${(formValue?.Days <= 0) ? 'red_border' : ''}`}
                                                error={formError?.Days ? true : false}
                                                helperText={formError?.Days}
                                                autoComplete='off'
                                            />
                                        )}
                                        autoComplete='off'
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <FormControl fullWidth>
                            <Autocomplete
                                clearIcon={false}
                                options={mealList?.filter((item) => item?.id !== formValue?.MealType)}
                                id="select-mealtype-blackout"
                                value={mealList?.find((obj) => obj?.id === formValue?.MealType) || null}
                                name='MealType'
                                getOptionLabel={(option) => (`${option.label.split(' ')[option.label.split(' ')?.length - 1]?.slice(1, -1)}`)}
                                onChange={(e, newValue) => onChange("MealType", newValue?.id ? newValue?.id : null)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Meal Type" variant="filled"
                                        className={`${formValue?.MealType <= 0 ? 'red_border' : ''}`}
                                        error={formError?.MealType ? true : false}
                                        helperText={formError?.MealType}
                                    />
                                )}
                                autoComplete='off'
                            />
                        </FormControl>
                    </div>


                    <div className="bulk_update_modal_title mb-2    ">
                        Special Rates
                    </div>

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="price-for-1-adult"
                                    variant='filled'
                                    label="Price for 1 Adults"
                                    name='Adult1'
                                    value={formValue?.Adult1 || ''}
                                    onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                                    className={`${formValue?.Adult1 === "" || formValue?.Adult1 === 0 ? 'red_border' : ''}`}
                                    error={formError?.Adult1 ? true : false}
                                    helperText={formError?.Adult1}
                                    inputProps={{ maxLength: 6 }}
                                    autoComplete='off'
                                    disabled={formValue?.MealType <= 0}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="price-for-2-adult"
                                    variant='filled'
                                    label="Price for 2 Adults"
                                    name='Adult2'
                                    value={formValue?.Adult2 || ''}
                                    onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                                    className={`${formValue?.Adult2 === "" || formValue?.Adult2 === 0 ? 'red_border' : ''}`}
                                    error={formError?.Adult2 ? true : false}
                                    helperText={formError?.Adult2}
                                    inputProps={{ maxLength: 6 }}
                                    autoComplete='off'
                                    disabled={formValue?.MealType <= 0}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <TextField
                            fullWidth
                            id="extra-adult-with-bed"
                            variant='filled'
                            label="Extra Adult with bed / mattress"
                            name='ExtraAdultWithBed'
                            value={formValue?.ExtraAdultWithBed}
                            onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                            className={`${formValue?.ExtraAdultWithBed === "" || formValue?.ExtraAdultWithBed === 0 ? 'red_border' : ''}`}
                            error={formError?.ExtraAdultWithBed ? true : false}
                            helperText={formError?.ExtraAdultWithBed}
                            inputProps={{ maxLength: 6 }}
                            autoComplete='off'
                            disabled={formValue?.MealType <= 0}
                        />
                    </div>

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className={`col-6`}>
                                <TextField
                                    fullWidth
                                    id="price-for-child-without-bed"
                                    variant='filled'
                                    label="Extra Child without bed / mattress"
                                    name='ChildWithOutBed'
                                    value={formValue.ChildWithOutBed}
                                    onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                                    className={`${formValue?.ChildWithOutBed === "" || formValue?.ChildWithOutBed === 0 ? 'red_border' : ''}`}
                                    error={formError?.ChildWithOutBed ? true : false}
                                    helperText={formError?.ChildWithOutBed}
                                    inputProps={{ maxLength: 6 }}
                                    autoComplete='off'
                                    disabled={formValue?.MealType <= 0}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="price-for-child-with-bed"
                                    variant='filled'
                                    label="Extra Child with bed / mattress"
                                    name='ChildWithBed'
                                    value={formValue.ChildWithBed}
                                    onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                                    className={`${formValue?.ChildWithBed === "" || formValue?.ChildWithBed === 0 ? 'red_border' : ''}`}
                                    error={formError?.ChildWithBed ? true : false}
                                    helperText={formError?.ChildWithBed}
                                    inputProps={{ maxLength: 6 }}
                                    autoComplete='off'
                                    disabled={formValue?.MealType <= 0}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <button type="button" className='btn bulk_apply_btn btn-primary w-100' onClick={(e) => handleApply(e)}>Apply</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default BlackoutModal

import React, { memo, useMemo } from 'react';
import { useState } from "react";
import "../../../../../../../assets/css/master.css";
import "../../../Hotelmaster.css"
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, Tab, Tabs, TextField } from '@mui/material';


const RoomAmenities = ({ roomAmenities, formValue, setFormValue, formError, setFormError, disabled }) => {
    const [value, setValue] = useState('Room Inclusion');

    const othersData = useMemo(() => roomAmenities.find((amenities) => amenities.roomCategoryName === "Others")?.categoryOptions
        , [roomAmenities])

    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }


    const handleRoomAmenitiesCheckBox = (HotelRoomAmenitiesId, type, value, isOption = false,) => {

        if (type === 'checkbox') {
            if (value) {
                setFormValue((previous) => ({
                    ...previous,
                    HotelRoomAmenitiesCollection: isOption ? [...previous?.HotelRoomAmenitiesCollection, { HotelRoomAmenitiesId, HotelRoomAmenitiesOptionsId: null }]
                        : [...previous?.HotelRoomAmenitiesCollection, { HotelRoomAmenitiesId }]

                }))
            } else {
                setFormValue(
                    (previous) => (
                        {
                            ...previous, HotelRoomAmenitiesCollection: previous?.HotelRoomAmenitiesCollection.filter(
                                (val) => val.HotelRoomAmenitiesId !== HotelRoomAmenitiesId)
                        })
                )
            }
        }
        setFormError({})
    };

    const handleAreaAndDropDown = (HotelRoomAmenitiesId, type, value) => {
        if (type === 'dropdown') {
            setFormValue(
                (previous) => (
                    {
                        ...previous,
                        HotelRoomAmenitiesCollection: previous?.HotelRoomAmenitiesCollection.map(
                            (val) => val.HotelRoomAmenitiesId === HotelRoomAmenitiesId ? ({ ...val, HotelRoomAmenitiesOptionsId: value }) : val)
                    }
                )
            )

        } else if (type === 'textarea') {
            setFormValue(
                (previous) => (
                    {
                        ...previous,
                        HotelRoomAmenitiesCollection: previous?.HotelRoomAmenitiesCollection.map(
                            (val) => val.HotelRoomAmenitiesId === HotelRoomAmenitiesId ? ({ ...val, Remarks: value }) : val)
                    }
                )
            )
        }
        setFormError({})
    }

    const handleMultiSelector = (amenitiesId, type, selectedOption) => {
        let cloneAmenities = [...formValue.HotelRoomAmenitiesCollection]

        cloneAmenities = cloneAmenities.filter((am) => am.HotelRoomAmenitiesId !== amenitiesId);
        if (selectedOption.length > 0) {
            let newMultiSelect = selectedOption.map((selectedOption) => ({ HotelRoomAmenitiesId: amenitiesId, HotelRoomAmenitiesOptionsId: selectedOption.optionID }));
            setFormValue({ ...formValue, HotelRoomAmenitiesCollection: [...cloneAmenities, ...newMultiSelect] })
        } else {
            setFormValue({
                ...formValue,
                HotelRoomAmenitiesCollection: [...cloneAmenities, { HotelRoomAmenitiesId: amenitiesId, HotelRoomAmenitiesOptionsId: null }]
            })
        }
        setFormError({})
    }


    const getSelectedOption = (options, roomAmenitiesId, type = 'select') => {

        let data;
        const selectedOption = formValue.HotelRoomAmenitiesCollection.find((am) => am.HotelRoomAmenitiesId === roomAmenitiesId)
        if (type === 'select') {
            data = options?.find((op) => op.optionID === selectedOption?.HotelRoomAmenitiesOptionsId)
        } else {
            data = selectedOption
        }

        return data || null
    }

    const getMultiSelectedOption = (amenitiesID, Options) => {
        const selectedOption = formValue.HotelRoomAmenitiesCollection.reduce((acc, selectedOption) => {
            if (selectedOption?.HotelRoomAmenitiesId === amenitiesID) {
                Options.forEach(op => {
                    if (selectedOption.HotelRoomAmenitiesOptionsId === op.optionID) {
                        acc = [...acc, { optionID: op.optionID, optionName: op.optionName, isOptionSelected: true }]
                    }
                });
            }
            return acc
        }, []);
        return selectedOption.length > 0 ? selectedOption : []
    }

    const getOptions = (options) => {
        let option = [];
        options.forEach((op) => {
            const isSelected = formValue?.HotelRoomAmenitiesCollection?.some((selectedOption) => selectedOption.HotelRoomAmenitiesOptionsId === op.optionID)
            if (!isSelected) {
                option = [...option, op]
            }
        })
        return option
    }
    console.log(formValue, 'formValue')
    return (
        <div className="container-fluid mt-2 heading_color input_mui">
            <div className='row'>
                <div className='col-lg-2 col-3'>
                    <Box className='Hotel_listing'
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                    >
                        <Tabs
                            className='w-100'
                            orientation="vertical"
                            value={value}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            {roomAmenities?.length > 0 ? roomAmenities.map((roomamenity, index) => (
                                roomamenity?.roomCategoryName !== 'Others' && (<Tab
                                    onClick={() => setValue(roomamenity?.roomCategoryName)}
                                    label={roomamenity?.roomCategoryName}
                                    value={`${roomamenity?.roomCategoryName}`}
                                    {...a11yProps(index)}
                                />)
                            )) : ""}
                            <Tab label={"Others"} value={'others'} onClick={() => setValue("others")} {...a11yProps(roomAmenities?.length - 1)} />
                        </Tabs>
                    </Box>
                </div>

                <div className='col-lg-10 col-9'>
                    <form action="" className='py-3 my-1'>
                        {roomAmenities?.length > 0 ? roomAmenities.map((roomamenity, index) => (
                            <>
                                {roomamenity?.roomCategoryName !== 'Others' && roomamenity?.roomCategoryName === value && <div value={value} index={index}>
                                    {roomamenity?.roomCategoryName !== 'Others' && <h6 className='mt-3 fw-bold room_header'>{roomamenity?.roomCategoryName}</h6>}
                                    <div className='row'>
                                        {roomamenity.categoryOptions.map((el, index) => (
                                            <>
                                                {roomamenity?.roomCategoryName !== 'Others' && (
                                                    <div className='col-lg-6 col-md-12 my-2' key={el.roomAmenitiesId}>
                                                        <div className='row'>
                                                            <div className='col-6 '>
                                                                <FormControlLabel className='col-4 mx-0 w-100'
                                                                    control={
                                                                        <Checkbox
                                                                            checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === el.roomAmenitiesId)}
                                                                            onChange={(e) => handleRoomAmenitiesCheckBox(el.roomAmenitiesId, 'checkbox', e.target.checked, el?.options?.length > 0 ? true : false)}
                                                                            name={el.roomAmenitiesName}
                                                                            disabled={disabled}
                                                                        />
                                                                    }
                                                                    label={el.roomAmenitiesName}
                                                                />
                                                            </div>

                                                            {el?.options?.length > 0 ?
                                                                <div className='col-6 '>
                                                                    <FormControl className='w-100' variant="filled">
                                                                        <Autocomplete
                                                                            filterSelectedOptions
                                                                            id={`${el.Name}-id`}
                                                                            options={getOptions(el.options)}
                                                                            multiple={el?.isMultipleSelection ? true : false}
                                                                            value={el?.isMultipleSelection ? getMultiSelectedOption(el.roomAmenitiesId, el.options) : getSelectedOption(el.options, el.roomAmenitiesId) || null}
                                                                            getOptionLabel={(options) => options?.optionName}
                                                                            name={el?.Name}
                                                                            onChange={(e, newValue) => {
                                                                                el?.isMultipleSelection ? handleMultiSelector(el.roomAmenitiesId, 'dropdown', newValue) : handleAreaAndDropDown(el.roomAmenitiesId, 'dropdown', newValue?.optionID ? newValue?.optionID : null)
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField {...params}
                                                                                    label={`${el?.roomAmenitiesName}`}
                                                                                    className={`w-100  ${formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === el.roomAmenitiesId) && !getSelectedOption(el.options, el.roomAmenitiesId) ? 'red_border' : ''}`}
                                                                                    variant="filled"
                                                                                    error={formError[el.roomAmenitiesId] ? true : false}
                                                                                    helperText={formError?.[el.roomAmenitiesId]}
                                                                                />
                                                                            )}
                                                                            disabled={!formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === el.roomAmenitiesId) || disabled}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                                : ""}
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </div>}
                            </>
                        )) : ""}

                        {value === 'others' && (<div value={value} index={roomAmenities?.length - 1}>
                            <h6 className='mt-3 fw-bold room_header'>Beds & Blankets</h6>
                            <div className='row'>
                                {othersData?.length > 0 && (othersData.map((am) => <>
                                    {[100, 101, 102].includes(am.roomAmenitiesId) && (
                                        <div className='col-6' key={am.roomAmenitiesId}>
                                            <FormControlLabel className='col-4 mx-0 w-100'
                                                control={
                                                    <Checkbox
                                                        checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === am.roomAmenitiesId)}
                                                        onChange={(e) => handleRoomAmenitiesCheckBox(am.roomAmenitiesId, 'checkbox', e.target.checked)}
                                                        name={am.roomAmenitiesName}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label={am?.roomAmenitiesName}
                                            />
                                        </div>
                                    )}

                                </>))}
                            </div>

                            <h6 className='mt-3 fw-bold room_header'>Safety & Security</h6>
                            <div className='row'>
                                {othersData?.length > 0 && (othersData.map((am) => <>
                                    {[103, 104, 105].includes(am.roomAmenitiesId) && (
                                        <div className='col-6 ' key={am.roomAmenitiesId}>
                                            <FormControlLabel className='col-4 mx-0 w-100'
                                                control={
                                                    <Checkbox
                                                        checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === am.roomAmenitiesId)}
                                                        onChange={(e) => handleRoomAmenitiesCheckBox(am.roomAmenitiesId, 'checkbox', e.target.checked)}
                                                        name={am.roomAmenitiesName}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label={am?.roomAmenitiesName}
                                            />
                                        </div>
                                    )}
                                </>))}
                            </div>

                            <h6 className='mt-3 fw-bold room_header'>Childcare</h6>
                            <div className='row'>
                                {othersData?.length > 0 && (othersData.map((am) => <>
                                    {[106, 107, 108, 109].includes(am.roomAmenitiesId) && (
                                        <div className='col-6' key={am.roomAmenitiesId}>
                                            <FormControlLabel className='col-4 mx-0 w-100'
                                                control={
                                                    <Checkbox
                                                        checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === am.roomAmenitiesId)}
                                                        onChange={(e) => handleRoomAmenitiesCheckBox(am.roomAmenitiesId, 'checkbox', e.target.checked)}
                                                        name={am.roomAmenitiesName}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label={am?.roomAmenitiesName}
                                            />
                                        </div>
                                    )}
                                </>))}
                            </div>

                            <h6 className='mt-3 fw-bold room_header'>Any Other to Add</h6>
                            <div className='row' key={110}>
                                <div className='row'>
                                    <div className='col-2 '>
                                        <FormControlLabel className='col-4 mx-0 w-100'
                                            control={
                                                <Checkbox
                                                    checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === 110) ? true : false}
                                                    onChange={(e) => handleRoomAmenitiesCheckBox(110, 'checkbox', e.target.checked)}
                                                    name={'Yes'}
                                                    disabled={disabled}
                                                />
                                            }
                                            label={'Yes'}
                                        />
                                    </div>
                                    <div className='col-10'>
                                        <TextField
                                            value={getSelectedOption([], 110, 'textarea')?.Remarks || ''}
                                            onChange={(e) =>
                                                handleAreaAndDropDown(110, 'textarea', e.target.value)
                                            }
                                            label="Other Details"
                                            variant="filled"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            inputProps={{ maxLength: 500 }}
                                            className={`w-100  ${!formValue.HotelRoomAmenitiesCollection.find((val) => val.HotelRoomAmenitiesId === 110)?.Remarks ? 'red_border' : ''}`}
                                            disabled={disabled || disabled === true ? disabled : formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === 110) ? false : true}
                                            error={formError['110'] ? true : false}
                                            helperText={formError?.['110']}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </form>
                </div>
            </div>
        </div>
    )
}
export default memo(RoomAmenities)
import React, { Suspense, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import HotelAndRoomImages from './Partial/HotelAndRoomImages';
import HotelAndRoomVideos from './Partial/HotelAndRoomVideos';
import { deleteUserData, getAllData, postUsersData, verifytoken } from '../../../../../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../../../_actions/loader.actions';
import { ClearLocalStorage, GetLocalStorage } from '../../../../../commonClass/Session';
import { ImageDelete, ImageUpload } from '../../../../../../ImageUpload/ImageCommon';

function ImageAndVideo() {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let hotelType = localStorage.getItem('type')

    const [value, setValue] = React.useState("images");
    const [formValue, setFormValue] = useState({
        HotelBasicId: 0,
        UserId: 0,
        HotelMediaCollection: [],
    })

    const [save, setSave] = useState(false)

    const [imageSection, setImageSection] = useState([])
    const [videoSection, setVideoSection] = useState([])
    const [roomlist, setRoomlist] = useState([])

    //#region Back button function
    const handleBack = () => {
        navigate(`/HotelListing/${hotelType}/rooms`, {
            state: {
                disabled: location?.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }
    //#endregion

    //#region Next button function
    const handleNext = () => {
        navigate(`/HotelListing/${hotelType}/rateandinventory`, {
            state: {
                disabled: location?.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }
    //#endregion

    //#region Tab change function
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //#endregion

    //#region Room List API
    const getroomlistbyhotelbasicid = async (id) => {
        const roomlistbyhotelbasicid = await getAllData(`/master/v1/getroomlistbyhotelbasicid?hotelBasicId=${id}`)
        if (roomlistbyhotelbasicid?.status) {
            setRoomlist(roomlistbyhotelbasicid?.data)

        }
    }
    //#endregion

    //#region Images and Video Retrieve API
    const gethotelmediabyhotelbasicid = async (id) => {
        const hotelmediabyhotelbasicid = await getAllData(`/master/v1/gethotelmediabyhotelbasicid?hotelbasicId=${id}`)
        if (hotelmediabyhotelbasicid?.status) {
            setSave(true)
            const mediaData = [...hotelmediabyhotelbasicid?.data]
            const imagesCollection = mediaData?.filter(item => item?.MediaType === false)
            const videosCollection = mediaData?.filter(item => item?.MediaType === true)
            setImageSection(imagesCollection)
            setVideoSection(videosCollection)

        }
        else {
            setImageSection([])
            setVideoSection([])
        }
    }
    //#endregion

    //#region Calling API function in side useEffect with Hotel Basic ID
    useEffect(() => {

        async function fetchData() {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                const user = GetLocalStorage()
                setFormValue((form) => ({ ...form, HotelBasicId: location?.state?.HotelId, UserId: user?.userMasterID }))
                try {
                    dispatch(loaderActions.start())
                    await Promise.all([
                        getroomlistbyhotelbasicid(location?.state?.HotelId),
                        gethotelmediabyhotelbasicid(location?.state?.HotelId)
                    ])

                } catch (error) {
                    console.error(`Error fetching data: ${error}`)
                } finally {
                    dispatch(loaderActions.end())
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                dispatch(loaderActions.end())
            }

        }
        if (location?.state !== null) {
            fetchData()
        }
        else {
            navigate(-1)
        }
    }, [location])
    //#endregion

    //#region Delete File function and API
    const handleDeleteFile = (indexId, mediaId, mediaType) => {
        if (mediaId === 0) {
            if (mediaType === 'image') {
                setImageSection((prev) => prev.filter((item, index) => index !== indexId));
            }
            else {
                setVideoSection((prev) => prev.filter((item, index) => index !== indexId));
            }
        }
        else {
            deletemediabyhotelmediaid(mediaId, mediaType)
        }
    }

    const deletemediabyhotelmediaid = async (mediaid, mediaType) => {
        dispatch(loaderActions.start())
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const deleteData = await deleteUserData(`/master/v1/deletemediabyhotelmediaid?hotelMediaId=${mediaid}`)
            if (deleteData?.status) {
                if (mediaType === 'image') {
                    let deleteImage = imageSection?.find((obj) => obj?.HotelMediaId === mediaid)
                    try {
                        const deleted = await ImageDelete(deleteImage?.FilePath);
                        if (deleted) {
                            gethotelmediabyhotelbasicid(location?.state?.HotelId).then(() => {
                                dispatch(loaderActions.end())
                                dispatch({ type: 'ALERTING', message: deleteData?.message })
                            })
                        }
                    } catch (error) {
                        console.error(`Error Delete Image: ${error}`)
                    }
                }
                else {
                    let deleteVideo = videoSection?.find((obj) => obj?.HotelMediaId === mediaid)
                    try {
                        const deleted = await ImageDelete(deleteVideo?.FilePath);
                        if (deleted) {
                            gethotelmediabyhotelbasicid(location?.state?.HotelId).then(() => {
                                dispatch(loaderActions.end())
                                dispatch({ type: 'ALERTING', message: deleteData?.message })
                            })
                        }
                    } catch (error) {
                        console.error(`Error Delete Video: ${error}`)
                    }
                }

            }
            else {
                dispatch(loaderActions.end())
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            dispatch(loaderActions.end())
        }

    }
    //#endregion

    //#region Save Button function (insert API)
    const handleSave = async (e) => {
        e.preventDefault()
        const images = imageSection?.map((obj, index) => { obj.SeqNo = index + 1; return obj })?.filter((item) => item?.needToUpload)
        const video = videoSection?.map((obj, index) => { obj.SeqNo = index + 1; return obj })?.filter((item) => item?.needToUpload)
        formValue.HotelMediaCollection = [...images, ...video]

        dispatch(loaderActions.start())
        const insertorupdatehotelmedia = await postUsersData('/master/v1/insertorupdatehotelmedia', formValue)
        if (insertorupdatehotelmedia?.status) {
            let uploaded = await Promise.all(
                formValue?.HotelMediaCollection?.map(async (ele) => {
                    if (ele?.needToUpload) {
                        try {
                            let uploaded = await ImageUpload(ele?.Path, ele?.FileName, ele?.File);
                            ele.uploaded = uploaded
                        } catch (error) {
                            console.error(`Error Upload Image/Video: ${error}`)
                            dispatch(loaderActions.end())
                        }
                    }
                    return ele;
                })
            )
            if (uploaded?.filter((item) => item?.uploaded === false)?.length === 0) {
                gethotelmediabyhotelbasicid(location?.state?.HotelId).then(() => dispatch(loaderActions.end()))
                dispatch({ type: 'ALERTING', message: insertorupdatehotelmedia?.message })
            }
            setSave(true)
        }
        else {
            dispatch(loaderActions.end())
        }
    }
    //#endregion

    return (
        <Suspense fallback={<div> ...Loading </div>}>
            <div className="container-fluid mt-2 heading_color input_mui">
                <div className='row my-3'>
                    <div className='col-8'>
                        <h3><i onClick={() => handleBack()} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Images & Videos</h3>
                    </div>
                    <div className='col-4 text-end'>
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={(e) => handleSave(e)} disabled={save || location?.state?.disabled} >Save</button>
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={() => handleNext()} >Next</button>
                    </div>
                </div>
                <div>
                    <div className='w-100 '>
                        <Box sx={{ width: '100%', borderBottom: 2 }}>
                            <Tabs value={value}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                <Tab
                                    onClick={(e) => { handleChange(e, "images") }}
                                    label="Images"
                                    value="images"
                                    sx={{ width: '100%', textAlign: 'center' }}
                                    className='room-tabs'
                                />

                                <Tab
                                    onClick={(e) => { handleChange(e, "videos") }}
                                    value="videos"
                                    label="Videos"
                                    sx={{ width: '100%', textAlign: 'center' }}
                                    className='room-tabs'
                                />
                            </Tabs>
                        </Box>
                        <div className=''>
                            {
                                value === 'images' ?
                                    (
                                        <HotelAndRoomImages
                                            imageSection={imageSection}
                                            setImageSection={setImageSection}
                                            roomlist={[{
                                                HotelRoomId: null,
                                                RoomTypeName: "Hotel Images"
                                            }, ...roomlist]}
                                            mediaType={'image'}
                                            handleDeleteFile={handleDeleteFile}
                                            hotelId={location?.state?.HotelId}
                                            disabled={location?.state?.disabled}
                                            setSave={setSave}
                                        />
                                    ) : (
                                        <HotelAndRoomVideos
                                            videoSection={videoSection}
                                            setVideoSection={setVideoSection}
                                            roomlist={[{
                                                HotelRoomId: null,
                                                RoomTypeName: "Hotel Videos"
                                            }, ...roomlist]}
                                            mediaType={'video'}
                                            handleDeleteFile={handleDeleteFile}
                                            hotelId={location?.state?.HotelId}
                                            disabled={location?.state?.disabled}
                                            setSave={setSave}
                                        />
                                    )
                            }
                        </div>
                    </div>

                </div>
            </div>
        </Suspense>
    )
}

export default ImageAndVideo
import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { onlyNumeric } from '../../../../../../../commonClass/CommonValidation';
import { postUsersData, verifytoken } from '../../../../../../../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../../../../../_actions/loader.actions';
import { ClearLocalStorage } from '../../../../../../../commonClass/Session';
import { hotelRateInventoryOpen } from '../../../../../../../../_actions/user.action';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return date === null ? "" : [day, month, year].join('/');
}

function formFormatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return date === '' ? '' : [year, month, day].join('-');
}

const RatesModal = (props) => {
    const {
        title,
        isEdit,
        roomId,
        selectedCellDate,
        RoomAvailabilityDates,
        setOpenModal,
        roomlist,
        mealId,
        mealList,
        dayRangeList,
        ExtraBeddingAllowed,
        HotelBasicId,
        UserId,
    } = props;
    const dispatch = useDispatch();
    const [isSave, setIsSave] = useState(true);
    const [apiError, setApiError] = useState('');

    const [dateRangeValue, setDateRangeValue] = useState('');
    const [closedMessage, setClosedMessage] = useState('')

    useEffect(() => {
        if (selectedCellDate !== null) {
            setDateRangeValue(`${formatDate(new Date(selectedCellDate))}`)
        }
    }, [selectedCellDate])

    const [formValue, setFormValue] = useState({
        RoomType: roomId,
        HotelBasicId: HotelBasicId,
        UserId: UserId,
        FromDateSelect: selectedCellDate || null,
        ToDateSelect: selectedCellDate || null,
        MealType: mealId,
        FromDate: formFormatDate(selectedCellDate) + 'T:00:00:00' || null,
        ToDate: formFormatDate(selectedCellDate) + 'T:00:00:00' || null,
        Adult1: "",
        Adult2: "",
        ExtraAdultWithBed: "",
        ChildWithBed: "",
        ChildWithOutBed: "",
        Days: "",
    });
    const [formError, setFormError] = useState({})

    const onChange = (name, value) => {
        setFormError({});
        setIsSave(false);
        setFormValue(form => ({ ...form, [name]: value }))
    }

    const handleDateRange = (update, isEdit) => {
        setFormError({});
        setIsSave(false);
        if (isEdit) {
            setFormValue(form => ({
                ...form,
                FromDate: formFormatDate(update),
                FromDateSelect: update,
            }))
            // setDateRangeValue(`${formatDate(update)} - ${formatDate(update)}`)
            setDateRangeValue(`${formatDate(update)}`)
        }
        else {
            setFormValue(form => ({
                ...form,
                FromDate: formFormatDate(update[0]),
                FromDateSelect: update[0],
                ToDate: formFormatDate(update[1]),
                ToDateSelect: update[1],
            }))
            setDateRangeValue(`${formatDate(update[0])} - ${formatDate(update[1])}`)
        }
    }

    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    };

    const onClose = () => {
        isSave ? setOpenModal(false) : setClosedMessage(true);
    }

    const YesProceed = (e) => {
        e.preventDefault()
        setOpenModal(false)
        setClosedMessage(false)
    }

    const NoProceed = (e) => {
        e.preventDefault()
        setClosedMessage(false)
    }

    const validateData = () => {
        let status = true;
        let error = {};

        if (isEdit) {
            if (formValue?.FromDateSelect === null) {
                error["date"] = "Date selection is mandatory.";
                status = false;
            }
        }
        else {
            if (formValue?.FromDateSelect === null || formValue?.ToDateSelect === null) {
                error["date"] = "Date selection is mandatory.";
                status = false;
            }
            if (formValue?.Days <= 0) {
                error["Days"] = "Input is mandatory.";
                status = false;
            }
        }

        if (formValue?.MealType === 0) {
            error["MealType"] = "Input is mandatory.";
            status = false;
        }
        if (!formValue?.Adult1 || formValue?.Adult1 === 0) {
            error["Adult1"] = "Input is mandatory.";
            status = false;
        }
        if (!formValue?.Adult2 || formValue?.Adult2 === 0) {
            error["Adult2"] = "Input is mandatory.";
            status = false;
        }
        setFormError(error)

        return status;
    }

    //#region API getchannelmanagerlist
    const getSelectedDayRate = async () => {
        const selectedDayRates = await postUsersData('/master/v1/gethotelinventoryratesbyinventorydate',
            {
                HotelBasicId: formValue?.HotelBasicId,
                HotelRoomId: formValue?.RoomType,
                RateTypeId: formValue?.MealType,
                InventoryDate: formFormatDate(formValue?.FromDateSelect),
                UserId: formValue?.UserId
            }
        );

        if (selectedDayRates.status) {
            setFormValue((pre) => ({
                ...pre,
                Adult1: selectedDayRates?.data?.Adults1,
                Adult2: selectedDayRates?.data?.Adults2,
                ExtraAdultWithBed: selectedDayRates?.data?.AdultWithBed,
                ChildWithBed: selectedDayRates?.data?.ChildWithBed,
                ChildWithOutBed: selectedDayRates?.data?.ChildWithoutBed,
            }))
        }
    };

    //#region Fetch all API data
    //using Promise.all to run them in parallel
    useEffect(() => {
        const fetchDropDownData = async () => {
            dispatch(loaderActions.start());
            let statusoftoken = await verifytoken();

            if (statusoftoken) {
                try {
                    formValue?.FromDateSelect && formValue?.MealType && await getSelectedDayRate();
                } catch (error) {
                    console.error('Error during fetching data:', error);
                } finally {
                    dispatch(loaderActions.end());
                }
            } else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        };

        isEdit && formValue?.FromDateSelect && formValue?.MealType && fetchDropDownData();
    }, [formValue?.FromDateSelect, formValue?.MealType, isEdit]);

    const updateSelectedDayRate = async () => {
        const inventory = await postUsersData('/master/v1/insertupdatehotelinventoryrates', {
            HotelBasicId: formValue?.HotelBasicId,
            HotelRoomId: formValue?.RoomType,
            InventoryDate: formFormatDate(formValue?.FromDateSelect),
            UserId: formValue?.UserId,
            RatesCollection: [
                {
                    RateTypeId: formValue?.MealType,
                    Adults1: formValue?.Adult1,
                    Adults2: formValue?.Adult2,
                    AdultWithBed: formValue?.ExtraAdultWithBed,
                    ChildWithBed: formValue?.ChildWithBed,
                    ChildWithoutBed: formValue?.ChildWithOutBed
                }
            ]
        });

        if (inventory.status) {
            setOpenModal(false);
            setFormError({})
            setIsSave(true);
            dispatch({ type: 'ALERTING', message: inventory.message });
            dispatch(hotelRateInventoryOpen())
        } else {
            setApiError(inventory.message)
        }
    };
    const updateBulkRate = async () => {
        const bulkRate = await postUsersData('/master/v1/InsertBulkUpdateInventoryRates', {
            HotelBasicId: formValue?.HotelBasicId,
            HotelRoomId: formValue?.RoomType,
            DayRangesId: formValue?.Days,
            FromDate: formValue?.FromDate,
            ToDate: formValue?.ToDate,
            UserId: formValue?.UserId,
            RatesCollection: [
                {
                    RateTypeId: formValue?.MealType,
                    Adults1: formValue?.Adult1 === "" ? 0 : formValue?.Adult1,
                    Adults2: formValue?.Adult2 === "" ? 0 : formValue?.Adult2,
                    AdultWithBed: formValue?.ExtraAdultWithBed === "" ? 0 : formValue?.ExtraAdultWithBed,
                    ChildWithBed: formValue?.ChildWithBed === "" ? 0 : formValue?.ChildWithBed,
                    ChildWithoutBed: formValue?.ChildWithOutBed === "" ? 0 : formValue?.ChildWithOutBed
                }
            ]
        });

        if (bulkRate.status) {
            setOpenModal(false);
            setFormError({})
            setIsSave(true);
            dispatch({ type: 'ALERTING', message: bulkRate.message });
            dispatch(hotelRateInventoryOpen())
        } else {
            setApiError(bulkRate.message)
        }
    };

    const handleApply = (e) => {
        e.preventDefault();
        if (validateData()) {
            if (isEdit) {
                updateSelectedDayRate();
            } else {
                updateBulkRate()
            }
        }
    }
    return (
        <>
            <div className="d-flex justify-content-between align-items-center bulk_update_modal_header">
                <div className="bulk_update_modal_title">
                    {title}
                </div>
                <div role='button' onClick={onClose}>
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>

            {closedMessage &&
                <div className="text-center pb-3">
                    <span className="text-danger">Unsaved data, do you want to proceed ? </span>
                    <span role='button' style={{ color: '#0777A9' }} onClick={(e) => YesProceed(e)} > Yes </span>
                    or
                    <span role='button' style={{ color: '#0777A9' }} onClick={(e) => NoProceed(e)} > No</span>.
                </div>
            }
            <form action="" className='py-2'>
                {apiError && <span className='text-danger text-center mt-2'>{apiError}</span>}
                <div className='row'>
                    {
                        !isEdit &&
                        <div className="col-12 mb-4">
                            <FormControl fullWidth>
                                <Autocomplete
                                    clearIcon={false}
                                    options={roomlist?.filter((item) => item?.id !== formValue.RoomType)}
                                    id="select-roomtype-rates"
                                    value={roomlist?.find((obj) => obj?.id === formValue?.RoomType) || null}
                                    name='RoomType'
                                    onChange={(e, newValue) => onChange("RoomType", newValue?.id)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Room Type" variant="filled"
                                            className={`${formValue?.RoomType <= 0 ? 'red_border' : ''}`}
                                        />
                                    )}
                                    disabled
                                />
                            </FormControl>
                        </div>
                    }

                    <div className="col-12 mb-4">
                        <FormControl fullWidth>
                            <Autocomplete
                                clearIcon={false}
                                options={mealList?.filter((item) => item?.id !== formValue?.MealType)}
                                id="select-mealtype-rates"
                                value={mealList?.find((obj) => obj?.id === formValue?.MealType) || null}
                                name='MealType'
                                onChange={(e, newValue) => onChange("MealType", newValue?.id ? newValue?.id : null)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Meal Type" variant="filled"
                                        className={`${formValue?.MealType <= 0 ? 'red_border' : ''}`}
                                        error={formError?.MealType ? true : false}
                                        helperText={formError?.MealType}
                                    />
                                )}
                                autoComplete='off'
                                disabled={mealId !== 0}
                            />
                        </FormControl>
                    </div>

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className={`${isEdit ? 'col-12 lengthfull' : 'col-6'} `}>
                                <div className="text-container">
                                    <TextField
                                        fullWidth
                                        id="select-date"
                                        variant='filled'
                                        label={`${isEdit ? "Select Date" : "Date Range (From-To)"}`}
                                        // label="Select Date"
                                        value={dateRangeValue}
                                        disabled={selectedCellDate !== null}
                                        onClick={(e) => openDatePicker("selectdate")}
                                        className={`${(isEdit ? formValue?.FromDateSelect !== null : (formValue?.FromDateSelect !== null && formValue?.ToDateSelect !== null)) ? '' : 'red_border'}`}
                                        error={formError?.date ? true : false}
                                        helperText={formError?.date}
                                        autoComplete='off'

                                    />
                                    <div>
                                        {
                                            isEdit ?
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control d-none'
                                                    id='selectdate'
                                                    selected={formValue?.FromDateSelect}
                                                    placeholderText="Select Date"
                                                    autoComplete='off'
                                                    showMonthDropdown
                                                    startDate={formValue?.FromDateSelect}
                                                    onChange={(e) => handleDateRange(e, isEdit)}
                                                    minDate={new Date() < new Date(RoomAvailabilityDates?.StartDate) ? new Date(RoomAvailabilityDates?.StartDate) : new Date()}
                                                    maxDate={new Date(RoomAvailabilityDates?.EndDate)}
                                                />
                                                :
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control d-none'
                                                    id='selectdate'
                                                    selected={formValue?.FromDateSelect}
                                                    placeholderText="Date Range (From-To)"
                                                    selectsRange={true}
                                                    autoComplete='off'
                                                    showMonthDropdown
                                                    startDate={formValue?.FromDateSelect}
                                                    endDate={formValue?.ToDateSelect}
                                                    onChange={(e) => handleDateRange(e, isEdit)}
                                                    minDate={new Date() < new Date(RoomAvailabilityDates?.StartDate) ? new Date(RoomAvailabilityDates?.StartDate) : new Date()}
                                                    maxDate={new Date(RoomAvailabilityDates?.EndDate)}
                                                />
                                        }
                                    </div>
                                    <span className={`example-custom-input bulkCalendarBtn ${selectedCellDate !== null ? 'disableCalBtn' : ''}`}
                                        onClick={(e) => openDatePicker("select-date")}
                                    ><i className="fa fa-calendar"></i></span>
                                </div>
                            </div>
                            {
                                !isEdit &&
                                <div className="col-6">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            clearIcon={false}
                                            options={dayRangeList}
                                            id="select-days"
                                            value={dayRangeList?.find((obj) => obj.id === formValue.Days) || null}
                                            name='Days'
                                            onChange={(e, newValue) => onChange("Days", newValue?.id ? newValue?.id : null)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Days" variant="filled"
                                                    className={`${(formValue?.Days <= 0) ? 'red_border' : ''}`}
                                                    error={formError?.Days ? true : false}
                                                    helperText={formError?.Days}
                                                    autoComplete='off'
                                                />
                                            )}
                                            autoComplete='off'
                                        />
                                    </FormControl>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="price-for-1-adult"
                                    variant='filled'
                                    label="Price for 1 Adult"
                                    name='Adult1'
                                    value={formValue?.Adult1 || ''}
                                    onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                                    className={`${formValue?.Adult1 === "" || formValue?.Adult1 === 0 ? 'red_border' : ''}`}
                                    error={formError?.Adult1 ? true : false}
                                    helperText={formError?.Adult1}
                                    inputProps={{ maxLength: 6 }}
                                    autoComplete='off'
                                    disabled={isEdit && (!formValue?.FromDateSelect || !formValue?.MealType) ? true : false}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="price-for-2-adult"
                                    variant='filled'
                                    label="Price for 2 Adult"
                                    name='Adult2'
                                    value={formValue?.Adult2 || ''}
                                    onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                                    className={`${formValue?.Adult2 === "" || formValue?.Adult2 === 0 ? 'red_border' : ''}`}
                                    error={formError?.Adult2 ? true : false}
                                    helperText={formError?.Adult2}
                                    inputProps={{ maxLength: 6 }}
                                    autoComplete='off'
                                    disabled={isEdit && (!formValue?.FromDateSelect || !formValue?.MealType) ? true : false}
                                />
                            </div>
                        </div>
                    </div>

                    {
                        ExtraBeddingAllowed &&
                        <div className="col-12 mb-4">
                            <TextField
                                fullWidth
                                id="extra-adult-with-bed"
                                variant='filled'
                                label="Extra Adult with  bed / Mattress"
                                name='ExtraAdultWithBed'
                                value={formValue?.ExtraAdultWithBed}
                                onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                                inputProps={{ maxLength: 6 }}
                                autoComplete='off'
                                disabled={isEdit && (!formValue?.FromDateSelect || !formValue?.MealType) ? true : false}
                            />
                        </div>
                    }

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className={`${ExtraBeddingAllowed ? 'col-6' : 'col-12'} `}>
                                <TextField
                                    fullWidth
                                    id="price-for-child-without-bed"
                                    variant='filled'
                                    label="Extra Child without bed / Mattress"
                                    name='ChildWithOutBed'
                                    value={formValue.ChildWithOutBed}
                                    onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                                    inputProps={{ maxLength: 6 }}
                                    autoComplete='off'
                                    disabled={isEdit && (!formValue?.FromDateSelect || !formValue?.MealType) ? true : false}
                                />
                            </div>
                            {
                                ExtraBeddingAllowed &&
                                <div className="col-6">
                                    <TextField
                                        fullWidth
                                        id="price-for-child-with-bed"
                                        variant='filled'
                                        label="Extra Child with bed / Mattress"
                                        name='ChildWithBed'
                                        value={formValue.ChildWithBed}
                                        onChange={(e) => onChange(e.target.name, e.target.value === "" ? null : Number(onlyNumeric(e.target.value)))}
                                        inputProps={{ maxLength: 6 }}
                                        autoComplete='off'
                                        disabled={isEdit && (!formValue?.FromDateSelect || !formValue?.MealType) ? true : false}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="col-12">
                        <button type="button" className='btn bulk_apply_btn btn-primary w-100' onClick={(e) => handleApply(e)}>Apply</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default RatesModal

import React, { Fragment } from 'react';
import "../../../../../../assets/css/master.css";
import "../../Hotelmaster.css"
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';

const Others = (props) => {
    const {
        getData,
        hendlechekbox,
        onChangedropdown,
        checkval,
        formValue,
        formError,
        disabled,
        onChange
    } = props


    const uniqueData = (e, multi) => {
        if (multi === 'multi') {
            const uniqueData = e?.filter((item, index, self) =>
                index === self?.findIndex((t) => (
                    t?.optionName === item?.optionName && t?.optionID === item?.optionID
                ))
            );
            return uniqueData
        }
        else {
            return e
        }
    }

    return (
        <>
            <div class="mt-2 heading_color input_mui">
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Indoor Activities</h4>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => fit?.amenitiesId === 161 || fit?.amenitiesId === 151).map((el, index) =>
                                <div className='col-12 col-xl-6 mb-1' key={el?.amenitiesName + index}>
                                    <div className='row'>
                                        <div className='col-6 align-items-center d-flex'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox id={'check' + el?.amenitiesId} checked={checkval?.HotelAmenities?.find((obj) => obj?.AmenitiesId === el?.amenitiesId)} onChange={() => hendlechekbox(el?.amenitiesId, el?.options)} name={el?.amenitiesName} disabled={disabled} />
                                                }
                                                label={el?.amenitiesName}
                                            />
                                        </div>
                                        {el?.options?.length > 0 &&
                                            <div className='col-6'>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        multiple={el?.isMultipleSelection}
                                                        options={el?.options?.filter(op => !formValue?.HotelAmenitiesCollection?.filter((check) => check?.AmenitiesId === el?.amenitiesId).map(el => el.AmenitiesOptionsId).includes(op.optionID))}
                                                        getOptionLabel={(option) => option.optionName}
                                                        id={'select' + el?.amenitiesId}
                                                        value={el?.isMultipleSelection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : [] : checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection}
                                                        name={el?.amenitiesName}
                                                        onChange={(e, newValue) => onChangedropdown(el?.amenitiesId, newValue ? uniqueData(newValue, el?.isMultipleSelection ? 'multi' : 'single') : null)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={checkval?.HotelAmenitiesoption?.filter((check) => check?.AmenitiesId === el?.amenitiesId)?.length > 0 ? el?.amenitiesName : 'Select'} variant="filled"
                                                                className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                                error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                                helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                            />
                                                        )}
                                                        disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                </form>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Payment Services</h4>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => fit?.amenitiesId === 153 || fit?.amenitiesId === 152).map((el, index) =>
                                <div className='col-12 col-xl-6 mb-1' key={el?.amenitiesName + index}>
                                    <div className='row'>
                                        <div className='col-6 align-items-center d-flex'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox id={'check' + el?.amenitiesId} checked={checkval?.HotelAmenities?.find((obj) => obj?.AmenitiesId === el?.amenitiesId)} onChange={() => hendlechekbox(el?.amenitiesId, el?.options)} name={el?.amenitiesName} disabled={disabled} />
                                                }
                                                label={el?.amenitiesName}
                                            />
                                        </div>
                                        {el?.options?.length > 0 &&
                                            <div className='col-6'>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        multiple={el?.isMultipleSelection}
                                                        options={el?.options?.filter(op => !formValue?.HotelAmenitiesCollection?.filter((check) => check?.AmenitiesId === el?.amenitiesId).map(el => el.AmenitiesOptionsId).includes(op.optionID))}
                                                        getOptionLabel={(option) => option.optionName}
                                                        id={'select' + el?.amenitiesId}
                                                        value={el?.isMultipleSelection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : [] : checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection}
                                                        name={el?.amenitiesName}
                                                        onChange={(e, newValue) => onChangedropdown(el?.amenitiesId, newValue ? uniqueData(newValue, el?.isMultipleSelection ? 'multi' : 'single') : null)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={checkval?.HotelAmenitiesoption?.filter((check) => check?.AmenitiesId === el?.amenitiesId)?.length > 0 ? el?.amenitiesName : 'Select'} variant="filled"
                                                                className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                                error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                                helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                            />
                                                        )}
                                                        disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                </form>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Family & Kids</h4>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => (fit?.amenitiesId >= 154 && fit?.amenitiesId <= 157))?.map((el, index) =>
                                <div className='col-12 col-xl-6 mb-1' key={el?.amenitiesName + index}>
                                    <div className='row'>
                                        <div className='col-6 align-items-center d-flex'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox id={'check' + el?.amenitiesId} checked={checkval?.HotelAmenities?.find((obj) => obj?.AmenitiesId === el?.amenitiesId)} onChange={() => hendlechekbox(el?.amenitiesId, el?.options)} name={el?.amenitiesName} disabled={disabled} />
                                                }
                                                label={el?.amenitiesName}
                                            />
                                        </div>
                                        {el?.options?.length > 0 &&
                                            <div className='col-6'>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        multiple={el?.isMultipleSelection}
                                                        options={el?.options?.filter(op => !formValue?.HotelAmenitiesCollection?.filter((check) => check?.AmenitiesId === el?.amenitiesId).map(el => el.AmenitiesOptionsId).includes(op.optionID))}
                                                        getOptionLabel={(option) => option.optionName}
                                                        id={'select' + el?.amenitiesId}
                                                        value={el?.isMultipleSelection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : [] : checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection}
                                                        name={el?.amenitiesName}
                                                        onChange={(e, newValue) => onChangedropdown(el?.amenitiesId, newValue ? uniqueData(newValue, el?.isMultipleSelection ? 'multi' : 'single') : null)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={checkval?.HotelAmenitiesoption?.filter((check) => check?.AmenitiesId === el?.amenitiesId)?.length > 0 ? el?.amenitiesName : 'Select'} variant="filled"
                                                                className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                                error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                                helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                            />
                                                        )}
                                                        disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                </form>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Pet Essentials</h4>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => (fit?.amenitiesId >= 158 && fit?.amenitiesId <= 159))?.map((el, index) =>
                                <div className='col-12 col-xl-6 mb-1' key={el?.amenitiesName + index}>
                                    <div className='row'>
                                        <div className='col-6 align-items-center d-flex'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox id={'check' + el?.amenitiesId} checked={checkval?.HotelAmenities?.find((obj) => obj?.AmenitiesId === el?.amenitiesId)} onChange={() => hendlechekbox(el?.amenitiesId, el?.options)} name={el?.amenitiesName} disabled={disabled} />
                                                }
                                                label={el?.amenitiesName}
                                            />
                                        </div>
                                        {el?.options?.length > 0 &&
                                            <div className='col-6'>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        multiple={el?.isMultipleSelection}
                                                        options={el?.options?.filter(op => !formValue?.HotelAmenitiesCollection?.filter((check) => check?.AmenitiesId === el?.amenitiesId).map(el => el.AmenitiesOptionsId).includes(op.optionID))}
                                                        getOptionLabel={(option) => option.optionName}
                                                        id={'select' + el?.amenitiesId}
                                                        value={el?.isMultipleSelection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : [] : checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection}
                                                        name={el?.amenitiesName}
                                                        onChange={(e, newValue) => onChangedropdown(el?.amenitiesId, newValue ? uniqueData(newValue, el?.isMultipleSelection ? 'multi' : 'single') : null)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={checkval?.HotelAmenitiesoption?.filter((check) => check?.AmenitiesId === el?.amenitiesId)?.length > 0 ? el?.amenitiesName : 'Select'} variant="filled"
                                                                className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                                error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                                helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                            />
                                                        )}
                                                        disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                </form>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Any Other to Add</h4>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => fit?.amenitiesId === 160)?.map((el, index) =>
                                <Fragment key={el?.amenitiesName + index}>
                                    <div className='col-12 col-lg-3 mb-1'>
                                        <div className='row'>
                                            <div className='col-6 align-items-center d-flex'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox id={'check' + el?.amenitiesId} checked={checkval?.HotelAmenities?.find((obj) => obj?.AmenitiesId === el?.amenitiesId)} onChange={() => hendlechekbox(el?.amenitiesId, el?.options, 'remark')} name={el?.amenitiesName} disabled={disabled} />
                                                    }
                                                    label={el?.amenitiesName}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-9 mb-1'>
                                        <FormControl fullWidth>
                                            <FormControlLabel className='mx-0'
                                                control={
                                                    <TextField
                                                        name='remark'
                                                        inputProps={{ maxLength: 500 }}
                                                        // onkeyprss={() => { setFormError([]); setSave(false) }}
                                                        onChange={(e) => onChange(el?.amenitiesId, e.target.value)}
                                                        variant='filled'
                                                        value={checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.remark ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.remark : ''}
                                                        className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                        id={'text' + el?.amenitiesId}
                                                        label="Please mention here"
                                                        multiline
                                                        rows={4}
                                                        error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                        helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                        disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </Box>
                </form>
            </div>
        </>
    )
}

export default Others
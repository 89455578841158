import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTable from '../../../../../../customControl/CustomTable';
import CreateRoomForm from './partial/CreateRoomForm';
import { getAllData, verifytoken } from '../../../../../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../../../_actions/loader.actions';
import { ClearLocalStorage } from '../../../../../commonClass/Session';


const Rooms = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let hotelType = localStorage.getItem('type');
    const [next, setNext] = useState(true)

    const [roomList, setRoomList] = useState([]);
    const [isCreateRoom, setIsCreateRoom] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(0)

    //#region API getallhoteltypemaster
    const getRoomList = async (HotelId) => {
        dispatch(loaderActions.start())
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const rooms = await getAllData(`/master/v1/getroomlist?HotelBasicId=${HotelId}`);
            if (rooms.status) {
                setRoomList(rooms?.data);
                setNext(false);
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    };

    const OpenRoomForm = (HotelRoomId, type, disabled) => {
        navigate(`/HotelListing/${hotelType}/rooms/createroom`,
            {
                state: {
                    type: type,
                    HotelRoomId: HotelRoomId,
                    roomDisabled: disabled,
                    disabled:location?.state?.disabled,
                    editdisabled: location?.state?.editdisabled,
                    viewtype: location?.state?.viewtype,
                    HotelId: location?.state?.HotelId
                }
            }
        )
    }

    useEffect(() => {
        if (location?.state !== null) {
            if (location?.state?.HotelId) {
                getRoomList(location?.state?.HotelId);
            }else{
                navigate(-1);
            }
        }else{
             navigate(-1);
        }
    }, [])

    const columns = [
        {
            name: 'Room Display Name',
            sortable: true,
            selector: (policy) => policy.RoomDisplayName,
        },
        {
            name: 'Room Type',
            sortable: true,
            selector: (policy) => policy.RoomType,
        },
        {
            name: 'Total Quantity',
            sortable: true,
            selector: (policy) => policy.RoomCount,
        },
        {
            name: 'ETM Allocation',
            sortable: true,
            selector: (policy) => policy.ETMAllocation,
        },

        {
            name: 'Edit',
            selector: (room) => <button
                type='button'
                className='border-0 p-0 bg-body set_disableclr'
                disabled={location.state.disabled}
            ><i className="fa fa-edit" onClick={() => {
                OpenRoomForm(room?.HotelRoomId, 'edit', false)
            }}
                /></button>
        },
        {
            name: 'View',
            selector: (room) =>
                <button
                    type='button'
                    className='border-0 p-0 bg-body set_disableclr'
                    disabled={location.state.disabled}
                >
                    <i className="fa fa-eye"
                        onClick={() => OpenRoomForm(room?.HotelRoomId, 'viwe', true)}
                    />
                </button>
        }
    ];

    const handleNext = () => {
        navigate(`/HotelListing/${hotelType}/images`, {
            state: {
                disabled: location.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }

    const handleBack = () => {
        navigate(`/HotelListing/${hotelType}/amenities`, {
            state: {
                disabled: location.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }

    return (
        <div className="mt-2 heading_color input_mui">
            <div className='row'>
                <div className='col-4'>
                    <h3><i onClick={handleBack} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Rooms</h3>
                </div>

                <div className='col-8 text-end'>
                    <button
                        type="button"
                        className="btn btn-primary mx-1 rounded-pill save_btn"
                        id="btnAdd" onClick={handleNext}
                        disabled={next}
                    >Next</button>
                </div>
            </div>

            {isCreateRoom ?
                (
                    <CreateRoomForm
                        setIsCreateRoom={setIsCreateRoom}
                        selectedRoom={selectedRoom}
                    />
                ) : (
                    <Fragment>
                        <div className='row my-2 ms-2'>
                            <button type="button" className="btn btn-primary mx-1 w-auto " id="btnAdd"
                                onClick={() => {
                                    navigate(`/HotelListing/${hotelType}/rooms/createroom`, {
                                        state: {
                                            type: 'add',
                                            HotelRoomId: 0,
                                            disabled: location?.state?.disabled,
                                            editdisabled: location?.state?.editdisabled,
                                            viewtype: location?.state?.viewtype,
                                            HotelId: location?.state?.HotelId
                                        }
                                    })
                                }}
                                disabled={location?.state?.disabled}
                            >
                                Create Room
                            </button>
                        </div>

                        <CustomTable
                            columns={columns}
                            data={roomList}
                            PdfExport={false}
                            ExportToExcel={false}
                            disabled={true}
                        />

                    </Fragment>
                )}

        </div>

    )
}

export default Rooms
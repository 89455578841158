import * as React from 'react';
import { Modal } from 'react-bootstrap'
import InventoryModal from './bulkmodals/InventoryModal';
import RatesModal from './bulkmodals/RatesModal';
import UnblockRoomModal from './bulkmodals/UnblockRoomModal';
import BlackoutModal from './bulkmodals/BlackoutModal';

export default function BulkUpdateModal(props) {
    const {
        open,
        title,
        isEdit,
        roomId,
        selectedCellDate,
        RoomAvailabilityDates,
        setOpenModal,
        roomlist,
        mealId,
        mealList,
        dayRangeList,
        ExtraBeddingAllowed,
        HotelBasicId,
        UserId,
    } = props;

    const RenderModal = React.useMemo(() => {
        switch (title?.name) {
            case 'Inventory':
                return <InventoryModal
                    title={title?.alias}
                    isEdit={isEdit}
                    selectedCellDate={selectedCellDate}
                    RoomAvailabilityDates={RoomAvailabilityDates}
                    setOpenModal={setOpenModal}
                    roomId={roomId}
                    roomlist={roomlist}
                    dayRangeList={dayRangeList}
                    HotelBasicId={HotelBasicId}
                    UserId={UserId}
                />;
            case 'Rates':
                return <RatesModal
                    title={title?.alias}
                    isEdit={isEdit}
                    selectedCellDate={selectedCellDate}
                    RoomAvailabilityDates={RoomAvailabilityDates}
                    setOpenModal={setOpenModal}
                    roomId={roomId}
                    roomlist={roomlist}
                    mealId={mealId}
                    mealList={mealList}
                    dayRangeList={dayRangeList}
                    ExtraBeddingAllowed={ExtraBeddingAllowed}
                    HotelBasicId={HotelBasicId}
                    UserId={UserId}
                />;
            case 'Blackout':
                return <BlackoutModal
                    title={title?.alias}
                    isEdit={isEdit}
                    selectedCellDate={selectedCellDate}
                    RoomAvailabilityDates={RoomAvailabilityDates}
                    setOpenModal={setOpenModal}
                    roomId={roomId}
                    roomlist={roomlist}
                    mealList={mealList}
                    dayRangeList={dayRangeList}
                    ExtraBeddingAllowed={ExtraBeddingAllowed}
                    HotelBasicId={HotelBasicId}
                    UserId={UserId}
                />;
            case 'Unblock Rooms':
                return <UnblockRoomModal
                    title={title?.alias}
                    roomId={roomId}
                    RoomAvailabilityDates={RoomAvailabilityDates}
                    setOpenModal={setOpenModal}
                    HotelBasicId={HotelBasicId}
                    UserId={UserId}
                />;
            default: return ''
        }
    }, [title, isEdit, selectedCellDate, RoomAvailabilityDates])

    return (
        <React.Fragment>
            <Modal
                show={open}
                onHide={() => setOpenModal(false)}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="sign-in-class"
                centered
            >
                <div className="bulk_update_modal p-4">
                    {RenderModal}
                </div>
            </Modal>
        </React.Fragment>
    );
}

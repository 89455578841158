import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { loaderActions } from '../../../../_actions/loader.actions';
import { postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { ClearLocalStorage } from '../../../commonClass/Session';

const FooterPart3 = (props) => {
    const { footerPart3, UserId, userRights } = props;

    const dispatch = useDispatch();
    const [editDisable, setEditDisable] = useState(false)

    const [formValue, setFormValue] = useState(
        {
            FooterPart1Id: 0,
            FooterText: "",
            UserId: UserId
        }
    )
    const [formError, setFormError] = useState({})

    //#region set retrieve data in formValue
    useEffect(() => {
        if (footerPart3 !== null) {
            setFormValue((form) => ({
                ...form,
                FooterPart1Id: footerPart3?.FooterPart1Id,
                FooterText: footerPart3?.FooterText,
                UserId: UserId
            }));
            setEditDisable(true)
        }
        else {
            setFormValue((form) => ({ ...form, UserId: UserId }));
        }
    }, [footerPart3, UserId])
    //#endregion

    //#region On Change function
    const handleOnChange = (name, value) => {
        setFormError({});
        setEditDisable(false);
        setFormValue((form) => ({ ...form, [name]: value }));
    }
    //#endregion

    //#region Validate Data
    const validateData = () => {
        let status = true;
        let error = {};

        if (formValue?.FooterText === "") {
            error.FooterText = "Kindly enter footer text.";
            status = false;
        }

        if (Object.keys(error)?.length > 0) {
            let id = Object.keys(error)[0];
            document.getElementById(id)?.focus();
        }
        setFormError(error);
        return status;
    }
    //#endregion

    //#region Add button function
    const handleAddClick = async () => {
        if (validateData()) {
            dispatch(loaderActions.start());
            const statusoftoken = await verifytoken();
            if (statusoftoken) {
                const insertorupdatefooterpart3 = await postUsersData('/master/v1/insertorupdatefooterpart3', formValue)
                dispatch({ type: 'ALERTING', message: insertorupdatefooterpart3?.message })
                dispatch(loaderActions.end());
            }
            else {
                dispatch(loaderActions.end());
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        }
    }
    //#endregion

    //#region Clear button function
    const handleClearClick = () => {
        setFormError({})
        setEditDisable(false);
        setFormValue({ ...formValue, FooterText: '' })
    }
    //#endregion

    return (
        <>
            <div className="mt-5 mb-3 row mx-1">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="FooterText" class="col-form-label required">Footer Text</label>
                                        </div>
                                        <div class="col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                            <textarea
                                                name="FooterText"
                                                id="FooterText"
                                                className='form-control'
                                                maxLength={500}
                                                rows={6}
                                                style={{ minHeight: '140px' }}
                                                value={formValue?.FooterText}
                                                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                                            />
                                            {formError?.FooterText && <p className="text-danger mb-0">{formError?.FooterText} </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 d-flex justify-content-center">
                        <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editDisable || (!userRights?.URightAdd && !userRights?.URightEdit)} >Add</button>
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default FooterPart3

import React, { useEffect, useState } from 'react'
import { postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../_actions/loader.actions';
import { ClearLocalStorage } from '../../../commonClass/Session';
import { onlyAlphabets } from '../../../commonClass/CommonValidation';

const FooterPart1 = (props) => {
    const { footerPart1, UserId, userRights } = props;
    const dispatch = useDispatch();
    const [editDisable, setEditDisable] = useState(false)

    const [formValue, setFormValue] = useState(
        {
            FooterPart1Id: 0,
            AddressTitle: "",
            AddressContent: "",
            CommunicationTitle: "",
            CommunicationContent: "",
            SocialMediaTitle: "",
            FacebookLink: "",
            TwitterLink: "",
            InstagramLink: "",
            LinkedinLink: "",
            YoutubeLink: "",
            UserId: 0,
        }
    )
    const [formErrors, setFormErrors] = useState({})

    //#region set retrieve data in formValue
    useEffect(() => {
        if (footerPart1 !== null) {
            setFormValue({ ...footerPart1, UserId: UserId });
            setEditDisable(true)
        }
        else {
            setFormValue((form) => ({ ...form, UserId: UserId }));
        }
    }, [footerPart1, UserId])
    //#endregion

    //#region On Change function
    const handleOnChange = (name, value) => {
        setFormErrors({});
        setEditDisable(false);
        setFormValue((form) => ({ ...form, [name]: value }));
    }
    //#endregion

    //#region Validate Data
    const validateData = () => {
        let status = true;
        let error = {}

        if (formValue?.AddressTitle === "") {
            error.AddressTitle = "Kindly enter address title.";
            status = false;
        }
        if (formValue?.AddressContent === "") {
            error.AddressContent = "Kindly enter address content.";
            status = false;
        }
        if (formValue?.CommunicationTitle === "") {
            error.CommunicationTitle = "Kindly enter communication title.";
            status = false;
        }
        if (formValue?.CommunicationContent === "") {
            error.CommunicationContent = "Kindly enter communication content.";
            status = false;
        }
        if (formValue?.SocialMediaTitle === "") {
            error.SocialMediaTitle = "Kindly enter social media title.";
            status = false;
        }
        if (formValue?.FacebookLink === "") {
            error.FacebookLink = "Please enter a valid Facebook URL.";
            status = false;
        }
        if (formValue?.TwitterLink === "") {
            error.TwitterLink = "Please enter a valid Twitter URL.";
            status = false;
        }
        if (formValue?.InstagramLink === "") {
            error.InstagramLink = "Please enter a valid Instagram URL.";
            status = false;
        }
        if (formValue?.LinkedinLink === "") {
            error.LinkedinLink = "Please enter a valid LinkedIn URL.";
            status = false;
        }
        if (formValue?.YoutubeLink === "") {
            error.YoutubeLink = "Please enter a valid YouTube URL.";
            status = false;
        }

        if (Object.keys(error)?.length > 0) {
            let id = Object.keys(error)[0];
            document.getElementById(id)?.focus();
        }
        setFormErrors(error);
        return status;
    }
    //#endregion

    //#region Add button function
    const handleAddClick = async () => {
        if (validateData()) {
            dispatch(loaderActions.start());
            const statusoftoken = await verifytoken();
            if (statusoftoken) {
                const insertorupdatefooterpart1 = await postUsersData('/master/v1/insertorupdatefooterpart1', formValue)
                dispatch({ type: 'ALERTING', message: insertorupdatefooterpart1?.message })
                dispatch(loaderActions.end());
            }
            else {
                dispatch(loaderActions.end());
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        }
    }
    //#endregion

    //#region Clear button function
    const handleClearClick = () => {
        setFormErrors({})
        setEditDisable(false);
        setFormValue({
            ...formValue,
            AddressTitle: "",
            AddressContent: "",
            CommunicationTitle: "",
            CommunicationContent: "",
            SocialMediaTitle: "",
            FacebookLink: "",
            TwitterLink: "",
            InstagramLink: "",
            LinkedinLink: "",
            YoutubeLink: "",
        })
    }
    //#endregion

    return (
        <>
            <div className="mt-5 mb-3 row mx-1">
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="AddressTitle" className="col-form-label required">Address Title</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="AddressTitle"
                                                    id="AddressTitle"
                                                    maxLength={50}
                                                    value={formValue?.AddressTitle}
                                                    onChange={(e) => handleOnChange(e.target.name, onlyAlphabets(e.target.value))}
                                                />
                                                {formErrors?.AddressTitle && <p className="text-danger mb-0">{formErrors?.AddressTitle} </p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="AddressContent" className="col-form-label required">Address Content</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="AddressContent"
                                                    id="AddressContent"
                                                    maxLength={100}
                                                    value={formValue?.AddressContent}
                                                    onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                                                />
                                                {formErrors?.AddressContent && <p className="text-danger mb-0">{formErrors?.AddressContent} </p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="CommunicationTitle" className="col-form-label required">Communication Title</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="CommunicationTitle"
                                                    id="CommunicationTitle"
                                                    maxLength={50}
                                                    value={formValue?.CommunicationTitle}
                                                    onChange={(e) => handleOnChange(e.target.name, onlyAlphabets(e.target.value))}
                                                />
                                                {formErrors?.CommunicationTitle && <p className="text-danger mb-0">{formErrors?.CommunicationTitle} </p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="CommunicationContent" className="col-form-label required">Communication Content</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="CommunicationContent"
                                                    id="CommunicationContent"
                                                    maxLength={100}
                                                    value={formValue?.CommunicationContent}
                                                    onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                                                />
                                                {formErrors?.CommunicationContent && <p className="text-danger mb-0">{formErrors?.CommunicationContent} </p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="SocialMediaTitle" className="col-form-label required">Social Media Title</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="SocialMediaTitle"
                                                    id="SocialMediaTitle"
                                                    maxLength={20}
                                                    value={formValue?.SocialMediaTitle}
                                                    onChange={(e) => handleOnChange(e.target.name, onlyAlphabets(e.target.value))}
                                                />
                                                {formErrors?.SocialMediaTitle && <p className="text-danger mb-0">{formErrors?.SocialMediaTitle} </p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6"></div>

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="FacebookLink" className="col-form-label required">Facebook Link</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="FacebookLink"
                                                    id="FacebookLink"
                                                    value={formValue?.FacebookLink}
                                                    onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                                                />
                                                {formErrors?.FacebookLink && <p className="text-danger mb-0">{formErrors?.FacebookLink} </p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="TwitterLink" className="col-form-label required">Twitter Link</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="TwitterLink"
                                                    id="TwitterLink"
                                                    value={formValue?.TwitterLink}
                                                    onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                                                />
                                                {formErrors?.TwitterLink && <p className="text-danger mb-0">{formErrors?.TwitterLink} </p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="InstagramLink" className="col-form-label required">Instagram Link</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="InstagramLink"
                                                    id="InstagramLink"
                                                    value={formValue?.InstagramLink}
                                                    onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                                                />
                                                {formErrors?.InstagramLink && <p className="text-danger mb-0">{formErrors?.InstagramLink} </p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="LinkedinLink" className="col-form-label required">LinkedIn Link</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="LinkedinLink"
                                                    id="LinkedinLink"
                                                    value={formValue?.LinkedinLink}
                                                    onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                                                />
                                                {formErrors?.LinkedinLink && <p className="text-danger mb-0">{formErrors?.LinkedinLink} </p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                                <label htmlFor="YoutubeLink" className="col-form-label required">YouTube Link</label>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name="YoutubeLink"
                                                    id="YoutubeLink"
                                                    value={formValue?.YoutubeLink}
                                                    onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                                                />
                                                {formErrors?.YoutubeLink && <p className="text-danger mb-0">{formErrors?.YoutubeLink} </p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                        <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editDisable || (!userRights?.URightAdd && !userRights?.URightEdit)} >Add</button>
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default FooterPart1

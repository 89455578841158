import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../assets/css/master.css"
import { getAllData, postUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { onlyAlphabets } from '../../commonClass/CommonValidation'
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import CustomTable from '../../../customControl/CustomTable';

const FooterMaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [modalCheckShow, setModalCheckShow] = useState(false);
    const [activeId, setActiveId] = useState();
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [formValue, setFormValue] = useState(
        {
            TitleId: 0,
            Title: "",
            IsDisplayUI: false,
            IsActive: false,
            UserId: 0
        }
    );
    const [footerTitleList, setFooterTitleList] = useState([]);
    const [formError, setFormError] = useState([]);

    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    //#region Function for Calling API "gettitlemasterlist" for Table Data
    const gettitlemasterlist = async () => {
        const titlemasterlist = await getAllData("/master/v1/gettitlemasterlist");
        if (titlemasterlist?.status) {
            titlemasterlist?.data?.map((obj) => {
                obj.ActiveString = obj?.IsActive === true ? "Active" : "InActive"
                obj.IsDisplayUIString = obj?.IsDisplayUI === true ? "Yes" : "No"
            })
            setFooterTitleList(titlemasterlist?.data);
        }
        else {
            setFooterTitleList([]);
        }
    }
    //#endregion

    //#region useEffects for API calling and Rights handleing
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            let user = GetLocalStorage()
            setFormValue((form) => ({ ...form, UserId: user?.userMasterID }))
            gettitlemasterlist().then(() => { dispatch(loaderActions.end()) });
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }, []);

    useEffect(() => {
        let pagelink = user?.find(x => x?.PageLink?.toLowerCase() === window?.location?.pathname?.toLowerCase());
        let mid = user?.find(x => x?.MenuId === pagelink?.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])
    //#endregion

    //#region Table Columns
    const columns = [
        {
            name: 'Title',
            sortable: true,
            float: "left",
            selector: (data) => data?.Title,
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (data) => data?.ActiveString
        },
        {
            name: 'Display On UI',
            sortable: true,
            float: "left",
            selector: (data) => data?.IsDisplayUIString
        },
        {
            name: 'Edit',
            selector: (data) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(data.TitleId)}></i></button>
        },
        {
            name: 'View',
            selector: (data) => <i className="fa fa-eye" onClick={() => handleViewMode(data.TitleId)}></i>
        }
    ];
    //#endregion

    //#region Validate Data
    const validateData = () => {
        let status = true;
        let error = {};
        if (formValue.Title === "") {
            error.Title = "Cannot leave blank, input is mandatory.";
            status = false;
        }
        footerTitleList?.map((obj) => {
            if (obj?.TitleId !== formValue.TitleId && obj.Title === formValue.Title?.trim()) {
                error.Title = "Title Already in used. Cannot create duplicate entry.";
                status = false;
            }
        })

        if (Object.keys(error)?.length > 0) {
            let id = Object.keys(error)[0];
            document.getElementById(id)?.focus();
        }
        setFormError(error)
        return status;
    }
    //#endregion

    //#region Add/Update Button Function
    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (validateData()) {
                let data = { ...formValue, Title: formValue.Title?.trim() }
                const insertupdatetitlemaster = await postUsersData("/master/v1/insertupdatetitlemaster", data);
                if (insertupdatetitlemaster?.status) {
                    clear();
                    gettitlemasterlist()
                }
                dispatch({ type: 'ALERTING', message: insertupdatetitlemaster.message })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }
    //#endregion

    //#region Edit Button Function
    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setButtonAddEditText("Update");
        RetriveData(id);
    }
    //#endregion

    //#region View Button Function
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        RetriveData(id);
    }
    //#endregion

    //#region Retrive Data on Edit/View
    function RetriveData(id) {
        setFormError([])

        footerTitleList?.map(obj => {
            if (obj.TitleId === id) {
                formValue.TitleId = obj.TitleId
                formValue.Title = obj.Title
                formValue.IsActive = obj.IsActive
                formValue.IsDisplayUI = obj.IsDisplayUI
                setActiveId(obj.IsActive);
            }
        });
        setFormValue({ ...formValue, formValue })
    }
    //#endregion

    //#region On Change Methods
    const onChange = (name, value) => {
        setFormError([])
        setEditDisabled(true);
        setFormValue({ ...formValue, [name]: value });
    };
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            setFormError([])
            setEditDisabled(true);
            setFormValue((form) => ({ ...form, IsActive: e }))
            setActiveId(!activeId)
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (name, e) => {
        if (e === true) {
            setFormError([])
            setEditDisabled(true);
            if (name === "Active") {
                setFormValue((form) => ({ ...form, IsActive: !activeId }))
                setActiveId(!activeId)
                setModalShow(false);
            }
        }
        else {
            setModalShow(false);
        }
    }

    const handleCheck = (e) => {
        setFormError([])
        setEditDisabled(true);
        setFormValue((form) => ({ ...form, IsDisplayUI: e.target.checked }))
    }
    //#endregion

    //#region handle clear operation
    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setFormError([])
        setFormValue((form) => ({
            ...form,
            TitleId: 0,
            Title: "",
            IsDisplayUI: false,
            IsActive: false
        }))
        setActiveId(false);
        gettitlemasterlist();
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
    }
    //#endregion


    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>Footer Master</h3>
            </div>
            <div className="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="inputPassword6" className="col-form-label required">Create Title</label>
                                        </div>
                                        <div className="col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                            <input
                                                type="text"
                                                id="Title"
                                                name="Title"
                                                className='form-control'
                                                value={formValue.Title}
                                                onChange={(e) => onChange(e.target.name, onlyAlphabets(e.target.value))}
                                                disabled={disabled ? true : false}
                                                placeholder="Create Title"
                                                maxLength={50}
                                            />
                                            {formError?.Title && <div className="text-danger text-start"> {formError?.Title} </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-3'>
                                    <div className='row align-items-center'>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <label className="col-form-label">Active</label>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12" disabled={false}>
                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation("Active", false)}
                                            yesconfirm={() => handleConfirmation("Active", true)}
                                        />
                                    </div>
                                </div>

                                <div className='col-3 align-content-center'>
                                    <div className='row align-items-center'>
                                        <div className="form-check">
                                            <label className="form-check-label" for="IsDisplayUI">
                                                Display On UI
                                            </label>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="IsDisplayUI"
                                                checked={formValue?.IsDisplayUI}
                                                onChange={(e) => handleCheck(e)}
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>

                    <CustomTable
                        columns={columns}
                        data={footerTitleList}
                    />
                </form>
            </div>
        </>
    )
}

export default FooterMaster
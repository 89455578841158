import React from 'react'

function PolicyTitleAndTabButton({ title, ChangeTab, value, disabled }) {

    return (
        <div className='row align-items-center'>
            <div className='col-6'>
                <h6 className='fw-bold room_header '>{title}</h6>
            </div>
            <div className='col-6 d-flex justify-content-end '>
                <div className='plicies-tabs-btn d-flex'>
                    <button
                        className={`btn plicies-tabs-in-btn me-2 curser-pointer ${value === 'Percent' ? 'tab-btn-white' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            ChangeTab('Percent');
                        }}
                        disabled={disabled}
                    >
                        % Percent
                    </button>
                    <button
                        className={`btn plicies-tabs-in-btn ${value === 'FixedAmount' ? 'tab-btn-white' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            ChangeTab('FixedAmount');
                        }}
                        disabled={disabled}
                    >
                        Fixed Amount
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PolicyTitleAndTabButton
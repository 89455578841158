import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { isImagePngJpegJpg } from '../../../commonClass/CommonValidation';
import { getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { loaderActions } from '../../../../_actions/loader.actions';
import { ImageUpload } from '../../../../ImageUpload/ImageCommon';
import RichTextBox from '../../../../customControl/RichTextBox';
import { htmlToText } from 'html-to-text';

function getDateCombination() {
  var today = new Date();
  let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
  let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
  return date + '_' + time;
}

function Pitching({ travelCategoryId, useredit, useradd }) {
  const dispatch = useDispatch();
  const userId = GetLocalStorage();

  const [richboxheight, setRichBoxHeight] = useState('480px');
  const [formvalue, setFormValue] = useState({
    TravelCategoryId: travelCategoryId,
    UserId: userId?.userMasterID,
    WhyChooseUs: "",
    ParaloxImageName: "",
    ParaloxImagePath: "",
    ParaloxImageFile: "",
  });
  const [formError, setFormError] = useState({});


  const getPitching = async (travelCategoryId) => {
    const sectionPitching = await getAllData(`/master/v1/getpitchingbytravelcategoryid?travelCategoryId=${travelCategoryId}`);

    if (sectionPitching?.status) {
      setFormValue({
        TravelCategoryId: travelCategoryId,
        UserId: userId?.userMasterID,
        WhyChooseUs: sectionPitching?.data?.WhyChooseUs,
        ParaloxImageName: sectionPitching?.data?.ParaloxImageName,
        ParaloxImagePath: sectionPitching?.data?.ParaloxImagePath,
        ParaloxImageFile: "",
      })
    }
    dispatch(loaderActions.end());
  }

  //#region List data
  useEffect(() => {
    dispatch(loaderActions.start());
    const fetchData = async () => {
      let statusoftoken = await verifytoken();
      if (statusoftoken) {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
          getPitching(travelCategoryId)
        }
      } else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      }
    }
    fetchData();
  }, []);

  const handlerichtextbox = (e) => {
    const str = e.replace('<p><br></p>', '')
    formvalue.WhyChooseUs = str
    setFormError({});
  }

  const handleUploadImageLoad = (name, e) => {
    setFormError({});
    if (isImagePngJpegJpg(e.target.files[0].name)) {
      const files = e.target.files[0];
      const filecom = getDateCombination();
      const filename = filecom + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');

      setFormValue({
        ...formvalue,
        ParaloxImageName: filename,
        ParaloxImagePath: 'CMS/pitching/' + filename,
        ParaloxImageFile: files,
      })
      e.target.value = null
    }
    else {
      setFormError({
        ...formError, ['ParaloxImage']: 'Please upload valid image such as PNG, JPEG, & JPG only.'
      });
    }
    e.target.value = null
  }

  const richtextboxconfig = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      height:richboxheight,
      width: '100%',
      statusbar: false,
      disablePlugins: ["source"],
      removeButtons: ["about", "image", "link", "select", "video"],
      toolbarAdaptive: false,
      defaultMode: "1",
    }),
    [richboxheight]
  );

  useEffect(() => {
    const richtexteboxheights = document.getElementById("layoutSidenav_content").clientWidth
    if (richtexteboxheights > 1253) {
      setRichBoxHeight('480px')
    } else if(richtexteboxheights<=768){
      setRichBoxHeight('400px')
    }
  }, []);

  const checkValidation = () => {
    let errorMessage = {}
    let isError = true

    const html = htmlToText(formvalue?.WhyChooseUs);
    if (html.trim().length <= 0) {
      errorMessage['WhyChooseUs'] = 'Cannot leave blank, input is mandatory.'
      isError = false
    } else if (!formvalue?.WhyChooseUs?.length > 1000) {
      errorMessage['WhyChooseUs'] = 'Maximum character limit reached 1000.'
      isError = false
    }

    if (!formvalue?.ParaloxImagePath) {
      errorMessage['ParaloxImage'] = 'Cannot leave blank, input is mandatory.'
      isError = false
    } else if (formvalue?.ParaloxImageFile) {
      const files = formvalue?.ParaloxImageFile;
      let fileSize = files.size / 1024;
      fileSize = fileSize / 1024

      if (fileSize > 5) {
        errorMessage['ParaloxImage'] = 'Cannot upload file that exceeds 5 MB.'
        isError = false;
      }
    }
    setFormError(errorMessage);
    return isError;
  }

  const onSubmitPitching = async () => {
    const isValid = checkValidation();
    let statusoftoken = await verifytoken();
    if (statusoftoken) {
      if (isValid) {
        dispatch(loaderActions.start());
        const updatePitching = await postUsersData('/master/v1/insertorupdatepitchingsection', formvalue);

        if (updatePitching.status) {
          formvalue?.ParaloxImageFile && await ImageUpload('CMS/pitching/', formvalue?.ParaloxImageName, formvalue?.ParaloxImageFile);
          dispatch(loaderActions.end());
          dispatch({ type: 'ALERTING', message: updatePitching.message })
        }
        else {
          dispatch(loaderActions.end());
          dispatch({ type: 'ALERTING', message: updatePitching.message })
          return;
        }
      }
    } else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      dispatch(loaderActions.end());
    }
  }

  const clearData = () => {
    setFormError({});
    setFormValue({
      ...formvalue,
      WhyChooseUs: "",
      ParaloxImageName: "",
      ParaloxImagePath: "",
      ParaloxImageFile: "",
    })
  }

  return (
    <div className=' p-4'>
      <div className="container-fluid heading_color ">
        <h4>Pitching</h4>
      </div>
      <div className="container-fluid heading_color">
        <form action="">
          <div className='row'>
            <div className="col-xl-2 col-12 mb-1">
              <label className="col-form-label required">Why choose us ?</label>
            </div>
            <div className="col-xl-10 mb-1 col-12">
              <RichTextBox
                field="Whychooseus"
                fieldvalue={formvalue.WhyChooseUs}
                tabIndex={1}
                OnChangeofRichBox={(e) => handlerichtextbox(e)}
                textconfig={richtextboxconfig}
              />
              {formError.WhyChooseUs && <div className='text-danger text-start'>{formError.WhyChooseUs}</div>}
            </div>
          </div>

          <div className='row mt-3'>
            <div className="col-xl-2 col-12 mb-1">
              <label className="col-form-label required">Paralox image</label>
            </div>
            <div className="col-xl-10 mb-1 col-12">
              <div class="input-group">
                <input
                  type="file"
                  name='paraloxImage'
                  id="paralox-image-id"
                  className="form-control btn_add"
                  accept=".png,.jpg,.jpeg"
                  onChange={(e) => handleUploadImageLoad('paraloxImage', e)}
                />
                <label class="input-group-text" htmlFor="paralox-image-id">Browse</label>
              </div>
              {formvalue.ParaloxImageName && <div className=' text-start'>{formvalue.ParaloxImageName}</div>}
              {formError.ParaloxImage && <div className='text-danger text-start'>{formError.ParaloxImage}</div>}
            </div>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button class="btn btn-primary mx-1" id="btnAdd" type='button'
              onClick={() => onSubmitPitching()}
              disabled={!useredit && !useradd}
            >Add</button>
            <button type="button" class="btn btn-primary mx-1" id="btnClear"
              disabled={!useredit && !useradd}
              onClick={() => clearData()}
            >Clear</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Pitching
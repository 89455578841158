import { useEffect, useState } from "react"
import CustomTable from "../../../customControl/CustomTable"
import HomePageSection from "./Partials/HomePageSection"
import NewsLetter from "./Partials/NewsLetter"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Pitching from "./Partials/Pitching"
import KeyAchievement from "./Partials/KeyAchievement"
import Testimonial from "./Partials/Testimonial"
import Banners from "./Partials/Banners"

const Holiday = () => {
    const [activeTabs, setActiveTabs] = useState('home-page-section');
    const navigate = useNavigate();
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x?.MenuId === pagelink?.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])

    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>Holidays Merchandising</h3>
            </div>
            <div className="container-fluid form_border pb-5 my-3">
                <div className="mt-4 mb-3 row mx-1">
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-lg-1">
                        <div className=" d-flex justify-content-center align-items-center flex-wrap">
                            <div className='container-fluid mt-3 tab_section px-0'>
                                <ul class="nav nav-pills nav-justified mb-3 pt-3" id="pills-tab" role="tablist">

                                    <li class="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <a class={`nav-link ${activeTabs === 'home-page-section' ? 'active' : ''}`}
                                            // id={tab.FAQTABId}
                                            data-bs-toggle="pill"
                                            type="button" role="tab" aria-controls="pills-about"
                                            aria-selected="false"
                                            onClick={() => setActiveTabs('home-page-section')}
                                        ><strong>Home Page Section</strong></a>
                                    </li>

                                    <li class="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <a class={`nav-link ${activeTabs === 'banners' ? 'active' : ''}`}
                                            // id={tab.FAQTABId}
                                            data-bs-toggle="pill"
                                            type="button" role="tab" aria-controls="pills-about"
                                            aria-selected="true"
                                            onClick={() => setActiveTabs('banners')}
                                        ><strong>Banners</strong></a>
                                    </li>

                                    <li class="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <a class={`nav-link ${activeTabs === 'news-letters' ? 'active' : ''}`}
                                            // id={tab.FAQTABId}
                                            data-bs-toggle="pill"
                                            type="button" role="tab" aria-controls="pills-about"
                                            aria-selected="true"
                                            onClick={() => setActiveTabs('news-letters')}
                                        ><strong>Newsletters</strong></a>
                                    </li>

                                    <li class="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <a class={`nav-link ${activeTabs === 'pitching' ? 'active' : ''}`}
                                            // id={tab.FAQTABId}
                                            data-bs-toggle="pill"
                                            type="button" role="tab" aria-controls="pills-about"
                                            aria-selected="true"
                                            onClick={() => setActiveTabs('pitching')}
                                        ><strong>Pitching</strong></a>
                                    </li>

                                    <li class="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <a class={`nav-link ${activeTabs === 'testimonial' ? 'active' : ''}`}
                                            // id={tab.FAQTABId}
                                            data-bs-toggle="pill"
                                            type="button" role="tab" aria-controls="pills-about"
                                            aria-selected="true"
                                            onClick={() => setActiveTabs('testimonial')}
                                        ><strong>Testimonial</strong></a>
                                    </li>

                                    <li class="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <a class={`nav-link ${activeTabs === 'key-achievement' ? 'active' : ''}`}
                                            // id={tab.FAQTABId}
                                            data-bs-toggle="pill"
                                            type="button" role="tab" aria-controls="pills-about"
                                            aria-selected="true"
                                            onClick={() => setActiveTabs('key-achievement')}
                                        ><strong>Key Achievement</strong></a>
                                    </li>


                                </ul>

                                <div className="container-fluid form_border my-3">
                                    {activeTabs === 'home-page-section' ?
                                        <HomePageSection
                                            travelCategoryId={2}
                                            useredit={useredit}
                                            useradd={useradd}
                                        />
                                        : activeTabs === 'banners' ?
                                            <Banners
                                                travelCategoryId={2}
                                                useredit={useredit}
                                                useradd={useradd}
                                            />
                                            : activeTabs === 'news-letters' ?
                                                <NewsLetter
                                                    travelCategoryId={2}
                                                    useredit={useredit}
                                                    useradd={useradd}
                                                />
                                                : activeTabs === 'pitching'
                                                    ? <Pitching
                                                        travelCategoryId={2}
                                                        useredit={useredit}
                                                        useradd={useradd}
                                                    /> : activeTabs === 'testimonial'
                                                        ? <Testimonial
                                                            useredit={useredit}
                                                            useradd={useradd}
                                                        />
                                                        : activeTabs === 'key-achievement'
                                                            ? <KeyAchievement
                                                                useredit={useredit}
                                                                useradd={useradd}
                                                            />
                                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Holiday
import { clearable, hotelRateInventory, hotelrateinventory, tripSupplierCollection, userConstatnt } from "../_constants/user.constant";

export const uservalueaction = {
    useraction, usermenuid
}

function useraction() {
    return { type: userConstatnt.USERDATA }
}
function usermenuid() {
    return { type: userConstatnt.MENUID }
}


export function cleartrue() {
    return { type: clearable.CLEARTRUE }
}
export function clearfalse() {
    return { type: clearable.CLEARFALSE }
}

export function clearerrortrue() {
    return { type: clearable.CLEARERRORTRUE }
}
export function clearerrorfalse() {
    return { type: clearable.CLEARERRORFALSE }
}

export function tripSupplierCollectionYes() {
    return { type: tripSupplierCollection.YES }
}
export function tripSupplierCollectionNo() {
    return { type: tripSupplierCollection.NO }
}

export function hotelRateInventoryOpen() {
    return { type: hotelRateInventory.HOTELRATEINVENTORYOPEN }
}
export function hotelRateInventoryClose() {
    return { type: hotelRateInventory.HOTELRATEINVENTORYCLOSE }
}
import React, { useEffect, useMemo, useState } from 'react'
import RichTextBox from '../../../customControl/RichTextBox'
import { getAllData, postUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from '../../../_actions/loader.actions';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [richboxheight, setRichBoxHeight] = useState(false);
    const [formError, setFormError] = useState([]);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    const [formValue, setFormValue] = useState({
        AboutUsMasterId: 0,
        AboutUs: "",
        UserId: 0,
    })

    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink?.toLowerCase() === window.location.pathname?.toLowerCase())
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);

        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);
        });
    }, [user])

    const richtextboxconfig = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/,
            height: '100%',
            maxHeight: richboxheight === true ? '300' : '500',
            width: '100%',
            statusbar: false,
            disablePlugins: ["source"],
            removeButtons: ["about", "image", "link", "select", "video"],
            toolbarAdaptive: false,
            defaultMode: "1",
        }),
        [richboxheight]
    );

    useEffect(() => {
        const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
        if (richtexteboxheight > 912) {
            setRichBoxHeight(true)
        }
        else {
            setRichBoxHeight(false)
        }
    }, []);


    const getaboutusmaster = async () => {
        const aboutusmaster = await getAllData('/master/v1/getaboutusmaster')
        if (aboutusmaster?.status) {
            setFormValue((form) => ({ ...form, AboutUsMasterId: aboutusmaster?.data?.AboutUsMasterId, AboutUs: aboutusmaster?.data?.AboutUs }))
        }
    }

    useEffect(() => {

        async function fetchData() {
            dispatch(loaderActions.start());
            const statusoftoken = await verifytoken();
            if (statusoftoken) {
                const userdata = GetLocalStorage()
                setFormValue((form) => ({ ...form, UserId: userdata?.userMasterID }))
                try {
                    getaboutusmaster()

                } catch (error) {
                    console.error(`Error fetching data :  + ${error}`)
                } finally {
                    dispatch(loaderActions.end())
                }

            }
            else {
                dispatch(loaderActions.end())
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        }
        fetchData()

    }, [])


    const handleRichTextBoxChange = (e) => {
        const str = e.replace('<p><br></p>', '')
        formValue.AboutUs = str
    }

    const handleAddClick = async () => {
        const statusoftoken = await verifytoken();
        if (statusoftoken) {
            const insertorupdateaboutusmaster = await postUsersData('/master/v1/insertorupdateaboutusmaster', formValue);
            if (insertorupdateaboutusmaster?.status) {
                setFormError([])
                dispatch({ type: 'ALERTING', message: insertorupdateaboutusmaster.message });
            }
            else {
                setFormError((error) => ({ ...error, AboutUs: insertorupdateaboutusmaster.message }))
            }
        }
        else {
            dispatch(loaderActions.end())
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
        }
    }
    const handleClearClick = () => {
        setFormValue({ ...formValue, AboutUs: '' });
        setFormError([]);
        if (document.querySelector('.jodit-wysiwyg') !== null) {
            document.querySelector('.jodit-wysiwyg').innerHTML = '';
        }
    }

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Merchandising</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="inputPassword6" class="col-form-label required">About Us</label>
                                        </div>
                                        <div class="col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                            <RichTextBox
                                                field="AboutUs"
                                                fieldvalue={formValue.AboutUs}
                                                tabIndex={1}
                                                OnChangeofRichBox={(e) => handleRichTextBoxChange(e)}
                                                textconfig={richtextboxconfig}
                                            />
                                            {formError?.AboutUs && <div className="text-danger text-start">{formError?.AboutUs} </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 d-flex justify-content-center">
                        <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd && !useredit}>Add</button>
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default AboutUs

import React, { memo, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import RoomBasicInfo from './RoomBasicInfo';
import RoomAmenities from './RoomAmenities';
import { useDispatch } from 'react-redux';
import { ClearLocalStorage, GetLocalStorage } from '../../../../../../commonClass/Session';
import { getAllData, postUsersData, verifytoken } from '../../../../../../../services/ApiCallingUserServices';
import { loaderActions } from '../../../../../../../_actions/loader.actions';


const CreateRoomForm = ({ setIsCreateRoom, selectedRoom }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    let type = localStorage.getItem('type');
    const userId = GetLocalStorage();


    const [value, setValue] = React.useState("basic-info");
    const [formDetails, setFormDetails] = useState({});
    const [getData, setGetData] = useState([]);
    const [formError, setFormError] = useState({});
    const [roomAmenities, setRoomAmenities] = useState({})

    const [formValue, setFormValue] = useState({
        HotelRoomId: 0,
        HotelBasicId: 0,
        RoomDisplayName: "",
        RoomTypeId: 0,
        BedTypeId: 0,
        RoomDescription: "",
        RoomCount: "",
        ETMAllocation: "",
        AdultCount: 1,
        ChildCount: 0,
        ExtraBeddingAllowed: true,
        ExtraMealProvided: true,
        ExtraMealRates: null,
        SmokingAllowed: true,
        VisitorEntry: true,
        RoomAvailabilityFromDate: "",
        RoomAvailabilityToDate: "",
        RestrictionMinDays: "",
        RestrictionMaxDays: "",
        RoomViewsCollection: [],
        HotelRoomRatesCollection: [
            {
                RateTypeId: 1,
                Adults1: null,
                Adults2: null,
                ExtraAdult: null,
                ExtraChildWithBed: null,
                ExtraChildWithoutBed: null
            },
            {
                RateTypeId: 2,
                Adults1: null,
                Adults2: null,
                ExtraAdult: null,
                ExtraChildWithBed: null,
                ExtraChildWithoutBed: null
            },
            {
                RateTypeId: 3,
                Adults1: null,
                Adults2: null,
                ExtraAdult: null,
                ExtraChildWithBed: null,
                ExtraChildWithoutBed: null
            },
            {
                RateTypeId: 4,
                Adults1: null,
                Adults2: null,
                ExtraAdult: null,
                ExtraChildWithBed: null,
                ExtraChildWithoutBed: null
            }],
        HotelRoomAmenitiesCollection: [],
        UserId: userId?.userMasterID,
        HotelRoomId: location?.state?.HotelRoomId,
        HotelBasicId: location?.state?.HotelId,
    });

    //#region API getallhoteltypemaster
    const getroombasicinfoandamenitiesbyhotelroomid = async (HotelRoomId) => {
        const roomBasicinfoAndAmenitiesDetails = await getAllData(`/master/v1/getroombasicinfoandamenitiesbyhotelroomid?hotelRoomId=${location.state.HotelRoomId}`);
        if (roomBasicinfoAndAmenitiesDetails.status) {
            const { roomBasicInfo, hotelDefaultRoomRates, selectedRoomViewsIds, roomAmenitiesList, rateTypeMaster } = roomBasicinfoAndAmenitiesDetails?.data

            setFormValue({
                RoomDisplayName: location?.state?.HotelRoomId == 0 ? "" : roomBasicInfo[0]?.RoomDisplayName,
                RoomTypeId: location?.state?.HotelRoomId == 0 ? 0 : roomBasicInfo[0]?.RoomTypeId,
                BedTypeId: location?.state?.HotelRoomId == 0 ? 0 : roomBasicInfo[0]?.BedTypeId,
                RoomDescription: location?.state?.HotelRoomId == 0 ? "" : roomBasicInfo[0]?.RoomDescription,
                RoomCount: location?.state?.HotelRoomId == 0 ? "" : roomBasicInfo[0]?.RoomCount ? Number(roomBasicInfo[0]?.RoomCount) : null,
                ETMAllocation: location?.state?.HotelRoomId == 0 ? "" : roomBasicInfo[0]?.ETMAllocation ? Number(roomBasicInfo[0]?.ETMAllocation) : null,
                AdultCount: location?.state?.HotelRoomId == 0 ? 1 : roomBasicInfo[0]?.AdultCount,
                ChildCount: location?.state?.HotelRoomId == 0 ? 0 : roomBasicInfo[0]?.ChildCount,
                ExtraBeddingAllowed: location?.state?.HotelRoomId == 0 ? true : roomBasicInfo[0]?.ExtraBeddingAllowed,
                ExtraMealProvided: location?.state?.HotelRoomId == 0 ? true : roomBasicInfo[0]?.ExtraMealProvided,
                ExtraMealRates: location?.state?.HotelRoomId == 0 ? "" : roomBasicInfo[0]?.ExtraMealRates,
                SmokingAllowed: location?.state?.HotelRoomId == 0 ? true : roomBasicInfo[0]?.SmokingAllowed,
                VisitorEntry: location?.state?.HotelRoomId == 0 ? true : roomBasicInfo[0]?.VisitorEntry,
                RoomAvailabilityFromDate: roomBasicInfo[0]?.RoomAvailabilityFromDate,
                RoomAvailabilityToDate: roomBasicInfo[0]?.RoomAvailabilityToDate,
                RestrictionMinDays: roomBasicInfo[0]?.RestrictionMinDays,
                RestrictionMaxDays: roomBasicInfo[0]?.RestrictionMaxDays,
                RoomViewsCollection: selectedRoomViewsIds,
                HotelRoomRatesCollection: location?.state?.HotelRoomId == 0 ? rateTypeMaster : hotelDefaultRoomRates,
                HotelRoomAmenitiesCollection: getSelectedAminities(roomAmenitiesList),
                UserId: userId?.userMasterID,
                HotelRoomId: location?.state?.HotelRoomId,
                HotelBasicId: location?.state?.HotelId,
            })
            setFormDetails(roomBasicinfoAndAmenitiesDetails?.data)
            setRoomAmenities(roomBasicinfoAndAmenitiesDetails?.data?.roomAmenitiesList)
        }
    };

    const getSelectedAminities = (roomAmenitiesList) => {
        let selectedAminities = [];

        roomAmenitiesList.map((tab) => {
            tab.categoryOptions.map((am) => {
                if (am.isAmenitySelected) {

                    if (am.options.length > 0) {
                        let opSelected = am.options.filter((op) => op?.isOptionSelected)
                        if (opSelected.isOptionSelected || opSelected.length > 0) {
                            if (opSelected.length > 1) {
                                opSelected.map((op) => {
                                    selectedAminities = [
                                        ...selectedAminities,
                                        {
                                            HotelRoomAmenitiesId: am?.roomAmenitiesId,
                                            HotelRoomAmenitiesOptionsId: op?.optionID
                                        }
                                    ]
                                })
                            } else {
                                selectedAminities = [
                                    ...selectedAminities,
                                    {
                                        HotelRoomAmenitiesId: am?.roomAmenitiesId,
                                        HotelRoomAmenitiesOptionsId: opSelected?.[0]?.optionID
                                    }
                                ]
                            }
                        } else {
                            selectedAminities = [
                                ...selectedAminities,
                                { HotelRoomAmenitiesId: am?.roomAmenitiesId }
                            ]
                        }
                    } else {
                        selectedAminities = am?.remarks ? [
                            ...selectedAminities,
                            {
                                HotelRoomAmenitiesId: am?.roomAmenitiesId,
                                Remarks: am.remarks
                            }
                        ] : [
                            ...selectedAminities,
                            { HotelRoomAmenitiesId: am?.roomAmenitiesId }
                        ]

                    }
                }
            })
        })

        return selectedAminities
    }

    const getroomsetupinformation = async () => {
        dispatch(loaderActions.start())
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const user = GetLocalStorage()
            formValue.UserId = user?.userMasterID
            formValue.HotelBasicId = location?.state?.HotelId
            const roomBasicinfoAndAmenitiesDetails = await getAllData(`/master/v1/getroomsetupinformation`);
            if (roomBasicinfoAndAmenitiesDetails.status) {
                setGetData(roomBasicinfoAndAmenitiesDetails?.data)
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }

    const validateData = () => {

        let status = true;
        let error = {};

        if (formValue?.RoomTypeId === 0 || formValue?.RoomTypeId === null || formValue?.RoomTypeId === undefined) {
            error["RoomTypeId"] = 'Inputs are mandatory.'
            status = false;
        }
        if (formValue?.BedTypeId === 0 || formValue?.BedTypeId === null || formValue?.BedTypeId === undefined) {
            error["BedTypeId"] = 'Inputs are mandatory.'
            status = false;
        }
        if (formValue?.RoomCount === 0 || formValue?.RoomCount <= 0) {
            error["RoomCount"] = 'Inputs are mandatory.'
            status = false;
        }
        if (formValue?.ETMAllocation > formValue?.RoomCount) {
            error["RoomCount"] = 'Total room count cannot be lower than ETM room count.'
            error["ETMAllocation"] = 'ETM room count cannot exceed total room count.'
            status = false;
        }

        if (formValue?.ETMAllocation === 0 || formValue?.ETMAllocation < 1) {
            error["ETMAllocation"] = 'Inputs are mandatory.'
            status = false;
        }
        if (formValue?.ExtraMealRates?.toString()?.length < 2 && formValue?.ExtraMealProvided) {
            error["ExtraMealRates"] = 'Inputs are mandatory.'
            status = false;
        }
        if (!formValue?.RoomAvailabilityFromDate || !formValue?.RoomAvailabilityToDate) {
            error["RoomAvailabilityFromDate"] = 'Inputs are mandatory.'
            status = false;
        }
        if (formValue?.RoomViewsCollection.length <= 0) {
            error["RoomViewsCollection"] = 'Room views are mandatory.'
            status = false;
        }

        if (formValue.HotelRoomRatesCollection.length > 0) {
            formValue.HotelRoomRatesCollection.forEach((am) => {
                if (!am?.Adults1) {
                    error["Adults1" + am.RateTypeId] = 'Inputs are mandatory.'
                    status = false;
                }
                if (!am?.Adults2) {
                    error["Adults2" + am.RateTypeId] = 'Inputs are mandatory.'
                    status = false;
                }
                if (!am?.ExtraAdult && formValue?.ExtraBeddingAllowed) {
                    error["ExtraAdult" + am.RateTypeId] = 'Inputs are mandatory.'
                    status = false;
                }
                if (!am?.ExtraChildWithBed && formValue?.ExtraBeddingAllowed) {
                    error["ExtraChildWithBed" + am.RateTypeId] = 'Inputs are mandatory.'
                    status = false;
                }
                if (!am?.ExtraChildWithoutBed) {
                    error["ExtraChildWithoutBed" + am.RateTypeId] = 'Inputs are mandatory.'
                    status = false;
                }

            })
        }
        // ExtraBeddingAllowed
        if (formValue.HotelRoomAmenitiesCollection.length > 0) {
            formValue.HotelRoomAmenitiesCollection.forEach((am) => {
                if (am.HotelRoomAmenitiesOptionsId === null) {
                    error[am?.HotelRoomAmenitiesId] = 'Inputs are mandatory.'
                    status = false;
                }
            })
            const isRemarks = formValue.HotelRoomAmenitiesCollection.find((val) => val.HotelRoomAmenitiesId === 110)
            if (!isRemarks?.Remarks && isRemarks?.HotelRoomAmenitiesId) {
                error['110'] = 'Inputs are mandatory.'
                status = false;
            }
        }

        setFormError(error)
        return status;
    }

    const hendlesave = async () => {
        const isValid = validateData();
        if (isValid) {
            dispatch(loaderActions.start())
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                const createRooms = await postUsersData(`/master/v1/insertorupdatehotelroom`, formValue);
                if (createRooms.status) {
                    dispatch({ type: 'ALERTINGMESSAGE', message: createRooms.message });
                } else {
                    dispatch({ type: 'ALERTING', message: createRooms.message });
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
            dispatch(loaderActions.end())
        }
    }

    //#region Fetch all API data
    //using Promise.all to run them in parallel
    useEffect(() => {
        const { HotelRoomId } = location;

        if (location?.state?.HotelId === undefined || location?.state?.HotelId === null) {
            navigate(-1);
        }

        const fetchDropDownData = async () => {
            dispatch(loaderActions.start());
            let statusoftoken = await verifytoken();

            if (statusoftoken) {
                try {
                    await Promise.all([
                        getroombasicinfoandamenitiesbyhotelroomid(HotelRoomId)
                    ]);
                } catch (error) {
                    console.error('Error during fetching data:', error);
                } finally {
                    dispatch(loaderActions.end());
                }
            } else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        };

        fetchDropDownData();
    }, []);

    useEffect(() => {
        getroomsetupinformation();
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleBack = () => {
        navigate(`/HotelListing/${type}/rooms`, {
            state: {
                disabled: location.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }
    return (
        <div class="mt-2 heading_color input_mui">
            <div className='row'>
                <div className='col-4'>
                    <h3><i onClick={handleBack} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Rooms</h3>
                </div>

                <div className='col-8 text-end'>
                </div>
            </div>

            <div className='row my-2'>
                <div className='col-8 d-flex'>
                    <h4>{value === 'basic-info' ? "Basic Info" : "Amenities"}</h4>
                    <i class="fa-solid fa-circle-info mx-2" data-toggle="tooltip" data-placement="top" title="Kindly fill basic details and amenities to create room">
                    </i>
                </div>
                <div className='col-4 text-end'>
                    {value === 'amenities' && (<button
                        type="button"
                        className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={hendlesave}
                        disabled={location?.state?.roomDisabled}
                    >Add</button>)}
                </div>
            </div>

            <div className='w-100'>
                <Box sx={{ width: '100%', borderBottom: 2 }}>
                    <Tabs value={value}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab
                            onClick={(e) => { handleChange(e, "basic-info") }}
                            label="Basic Info"
                            value="basic-info"
                            sx={{ width: '100%', textAlign: 'center' }}
                            className='room-tabs'
                        />
                        <Tab
                            onClick={(e) => { handleChange(e, "amenities") }}
                            value="amenities"
                            label="Amenities"
                            sx={{ width: '100%', textAlign: 'center' }}
                            className='room-tabs'
                        />
                    </Tabs>

                </Box>
                {
                    value === 'basic-info' ? (
                        <RoomBasicInfo
                            formDetails={formDetails?.roomBasicInfo}
                            formValue={formValue}
                            getData={getData}
                            setFormValue={setFormValue}
                            formError={formError}
                            setFormError={setFormError}
                            disabled={location?.state?.roomDisabled}
                        />
                    )
                        : (
                            <RoomAmenities
                                roomAmenities={roomAmenities}
                                formValue={formValue}
                                formError={formError}
                                setFormError={setFormError}
                                setFormValue={setFormValue}
                                disabled={location?.state?.roomDisabled}
                            />
                        )
                }
            </div>

        </div>
    )
}

export default memo(CreateRoomForm)
import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Policies from './Partials/Policies';
import HouseAndRules from './Partials/HouseAndRules';
import { getAllData, postUsersData, verifytoken } from '../../../../../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../../../_actions/loader.actions';
import { ClearLocalStorage, GetLocalStorage } from '../../../../../commonClass/Session';

function PoliciesAndRules() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let type = localStorage.getItem('type');
  const userId = GetLocalStorage();

  const [save, setSave] = useState(true);
  const [next, setNext] = useState(true);

  const [value, setValue] = useState('Policies');
  const [houseRulesDetails, setHouseRulesDetails] = useState([])
  const [formValue, setFormValue] = useState(
    {
      HotelPoliciesId: 0,
      HotelBasicId: location?.state?.HotelId,
      Is24HrCheckInOut: false,
      IsSpecificCheckInOut: false,
      StartTime24Hr: null,
      EndTime24Hr: null,
      SpecificStartTime: null,
      SpecificEndTime: null,
      UserId: userId?.userMasterID,
      HotelPolicyCollection: []
    }
  )
  const [houseAndRulesValue, setHouseAndRolesValue] = useState({
    HotelBasicId: location?.state?.HotelId,
    UserId: userId?.userMasterID,
    HotelHouseRulesCollection: []
  })
  const [formError, setFormError] = useState({})

  const ChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const getHotelPolicies = async () => {
    const policies = await getAllData(`/master/v1/gethotelpoliciesbyhotelbasicid?hotelBasicId=${location?.state?.HotelId}`);
    if (policies.status) {
      const { hotelPolicies, hotelPoliciesCollection } = policies.data;
      setFormValue(
        {
          HotelPoliciesId: hotelPolicies?.[0].HotelPoliciesId,
          HotelBasicId: hotelPolicies?.[0].HotelBasicId,
          Is24HrCheckInOut: hotelPolicies?.[0].Is24HrCheckInOut,
          IsSpecificCheckInOut: hotelPolicies?.[0].IsSpecificCheckInOut,
          StartTime24Hr: hotelPolicies?.[0].StartTime24Hr ? hotelPolicies?.[0].StartTime24Hr : null,
          EndTime24Hr: hotelPolicies?.[0].EndTime24Hr ? hotelPolicies?.[0].EndTime24Hr : null,
          SpecificStartTime: hotelPolicies?.[0].SpecificStartTime ? hotelPolicies?.[0].SpecificStartTime : null,
          SpecificEndTime: hotelPolicies?.[0].SpecificEndTime ? hotelPolicies?.[0].SpecificEndTime : null,
          HotelPolicyCollection: hotelPoliciesCollection.map((policy) => ({
            PolicyDay: policy?.PolicyDay,
            Amount: policy.Amount,
            IsPaymentPolicy: policy?.IsPaymentPolicy,
            IsPercentage: policy?.IsPercentage,
            HotelPolicyDetailsId: policy?.HotelPolicyDetailsId
          })),
          UserId: userId?.userMasterID
        }
      )
      policies.data && setNext(false)
      policies.data && setSave(true)
    }
  }

  const getHouseAndRule = async () => {
    const HouseAndRules = await getAllData(`/master/v1/gethotelhouserulesbyhotelbasicid?hotelBasicId=${location?.state?.HotelId}`);
    if (HouseAndRules.status) {
      setHouseRulesDetails(HouseAndRules.data);
      const formValue = HouseAndRules.data.reduce((acc, rule) => {
        rule?.categoryHouseRules?.map((category) => {

          if ((category?.isRuleSelected === true || category?.isRuleSelected === false) && category?.options.length <= 0) {
            acc = [...acc, { HotelHouseRuleMasterId: category?.houseRulesMasterId, IsRuleSelected: category?.isRuleSelected }]
          }

          if (category?.options.length > 0) {
            category?.options.map((op) => {
              if (op?.isOptionSelected) {
                acc = [...acc, {
                  HotelHouseRuleMasterId: category?.houseRulesMasterId,
                  IsRuleSelected: category?.isRuleSelected,
                  HouseRuleOptionsId: op?.optionId

                }]
              }
            })
          }

        })
        return acc;
      }, []);

      setHouseAndRolesValue({
        ...houseAndRulesValue,
        HotelHouseRulesCollection: formValue
      });
    }
  }


  useEffect(() => {
    if (location?.state?.HotelId === undefined || location?.state?.HotelId === null) {
      navigate(-1);
    }

    const fetchData = async () => {
      dispatch(loaderActions.start());
      let statusoftoken = await verifytoken();

      if (statusoftoken) {
        try {
          await Promise.all([getHotelPolicies(), getHouseAndRule()]);
        } catch (error) {
          console.error('Error during fetching data:', error);
        } finally {
          dispatch(loaderActions.end());
        }
      } else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
      }
    };
    fetchData();
  }, []);

  const isValidData = () => {
    let isErrors = true;
    let errorMessage = {}

    if (formValue?.Is24HrCheckInOut) {
      if (!formValue?.StartTime24Hr) {
        errorMessage['StartTime24Hr'] = "Inputs are mandatory."
        isErrors = false;
      }
      if (!formValue?.EndTime24Hr) {
        errorMessage['EndTime24Hr'] = "Inputs are mandatory."
        isErrors = false;
      }
    }

    if (formValue?.IsSpecificCheckInOut) {
      if (!formValue?.SpecificStartTime) {
        errorMessage['SpecificStartTime'] = "Inputs are mandatory."
        isErrors = false;
      }
      if (!formValue?.SpecificEndTime) {
        errorMessage['SpecificEndTime'] = "Inputs are mandatory."
        isErrors = false;
      }
    }
    setFormError(errorMessage);
    return isErrors
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = isValidData();
    dispatch(loaderActions.start());
    if (isValid) {
      let statusoftoken = await verifytoken();
      if (statusoftoken) {
        const policies = await postUsersData('/master/v1/insertorupdatehotelpolicies', formValue);
        const houseAndRules = await postUsersData('/master/v1/insertorupdatehotelhouserules', houseAndRulesValue);
        if (policies.status && houseAndRules.status) {
          dispatch({ type: 'ALERTING', message: policies.message });
          setNext(false);
          setSave(true)
        } else if (!policies.status) {
          dispatch({ type: 'ALERTING', message: policies.message });
        } else if (!houseAndRules.status) {
          dispatch({ type: 'ALERTING', message: houseAndRules.message });
        }
      }
    }
    dispatch(loaderActions.end());
  }

  const handleNavigation = (path) => {
    navigate(path, {
      state: {
        disabled: location?.state?.disabled,
        editdisabled: location?.state?.editdisabled,
        viewtype: location?.state?.viewtype,
        HotelId: location?.state?.HotelId
      }
    })
  }

  return (
    <div class="container-fluid mt-2 heading_color input_mui">
      <div className='row'>
        <div className='col-8 d-flex'>
          <h3>
            <i onClick={() => handleNavigation(`/HotelListing/${type}/rateandinventory`)} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Policies & House Rules
          </h3>
          <i class="fa-solid fa-circle-info mx-2" data-toggle="tooltip" data-placement="top" title="Kindly fill required Policies and House Rules details to proceed further"></i>
        </div>
        <div className='col-4 text-end'>
          <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd"
            onClick={(e) => { handleSubmit(e); }}
            disabled={save || location?.state?.disabled}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-primary mx-1 rounded-pill save_btn"
            id="btnAdd"
            disabled={next}
            onClick={() => handleNavigation(`/HotelListing/${type}/financeandlegal`)}
          >
            Next
          </button>
        </div>
      </div>

      <div className='w-100 mt-3'>
        <Box sx={{ width: '100%', borderBottom: 2 }}>
          <Tabs value={value}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              onClick={(e) => { ChangeTab(e, "Policies") }}
              label="Policies"
              value="Policies"
              sx={{ width: '100%', textAlign: 'center' }}
              className='room-tabs'
            />
            <Tab
              onClick={(e) => { ChangeTab(e, "House Rules") }}
              value="House Rules"
              label="House Rules"
              sx={{ width: '100%', textAlign: 'center' }}
              className='room-tabs'
            />
          </Tabs>

        </Box>
        {
          value === 'Policies' ? (
            <Policies
              policies={formValue}
              setPolicies={setFormValue}
              formError={formError}
              setFormError={setFormError}
              setSave={setSave}
              disabled={location?.state?.disabled}
            />)
            : (
              <HouseAndRules
                houseRulesDetails={houseRulesDetails}
                formValue={houseAndRulesValue}
                setFormValue={setHouseAndRolesValue}
                setSave={setSave}
                setFormError={setFormError}
                disabled={location?.state?.disabled}
              />
            )
        }
      </div>
    </div>
  )
}

export default PoliciesAndRules
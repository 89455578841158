import React, { useEffect, useMemo, useState } from 'react'
import { isImagePngJpegJpg } from '../../../commonClass/CommonValidation';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { useDispatch } from 'react-redux';
import { getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { loaderActions } from '../../../../_actions/loader.actions';
import { ImageUpload } from '../../../../ImageUpload/ImageCommon';
import RichTextBox from '../../../../customControl/RichTextBox';
import { htmlToText } from 'html-to-text';
import { useStaticRangePicker } from '@mui/x-date-pickers-pro/internals/hooks/useStaticRangePicker';

function getDateCombination() {
  var today = new Date();
  let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
  let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
  return date + '_' + time;
}

function NewsLetter({ travelCategoryId, useredit, useradd }) {
  const dispatch = useDispatch();
  const userId = GetLocalStorage();
  const [richboxheight, setRichBoxHeight] = useState(false);

  const [newsLetter, setNewsLetter] = useState({
    NewsLetterId: 0,
    TravelCategoryId: travelCategoryId,
    UserId: userId?.userMasterID,

    SectionImageName: '',
    SectionImagePath: '',
    SectionImageFile: '',

    Content1ImageName: '',
    Content1ImagePath: '',
    Content1ImageFile: '',
    BodyText1: '',

    Content2ImageName: '',
    Content2ImagePath: '',
    Content2ImageFile: '',
    BodyText2: '',
  });
  const [formError, setFormError] = useState({});

  const getNewsLetters = async (travelCategoryId) => {
    const sectionNewLetter = await getAllData(`/master/v1/getnewsletterbytravelcategoryid?travelCategoryId=${travelCategoryId}`);

    if (sectionNewLetter?.status) {
      setNewsLetter({
        NewsLetterId: sectionNewLetter?.data?.NewsLetterId,
        TravelCategoryId: travelCategoryId,
        UserId: userId?.userMasterID,

        SectionImageName: sectionNewLetter?.data?.SectionImageName,
        SectionImagePath: sectionNewLetter?.data?.SectionImagePath,
        SectionImageFile: '',

        Content1ImageName: sectionNewLetter?.data?.Content1ImageName,
        Content1ImagePath: sectionNewLetter?.data?.Content1ImagePath,
        Content1ImageFile: '',
        BodyText1: sectionNewLetter?.data?.BodyText1,

        Content2ImageName: sectionNewLetter?.data?.Content2ImageName,
        Content2ImagePath: sectionNewLetter?.data?.Content2ImagePath,
        Content2ImageFile: '',
        BodyText2: sectionNewLetter?.data?.BodyText2,
      })
    }
    dispatch(loaderActions.end());
  }

  //#region List data
  useEffect(() => {
    dispatch(loaderActions.start());
    const fetchData = async () => {
      let statusoftoken = await verifytoken();
      if (statusoftoken) {
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
          getNewsLetters(travelCategoryId)
        }
      } else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      }
    }
    fetchData();

  }, []);

  const richtextboxconfig = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      height: '400px',
      // minHeight:'400px',
      // maxHeight: richboxheight === true ? '300' : '500',
      width: '100%',
      statusbar: false,
      disablePlugins: ["source"],
      removeButtons: ["about", "image", "link", "select", "video"],
      toolbarAdaptive: false,
      defaultMode: "1",
    }),
    [richboxheight]
  );

  const handleOnChange = (name, value) => {
    const str = value.replace('<p><br></p>', '')
    newsLetter[name] = str
    setFormError({});
  };

  const handleUploadImageLoad = (name, e) => {
    setFormError({});
    if (isImagePngJpegJpg(e.target.files[0].name)) {
      const files = e.target.files[0];
      const filecom = getDateCombination();
      const filename = filecom + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');

      if (name === 'SectionImageName') {
        setNewsLetter({
          ...newsLetter,
          SectionImageName: filename,
          SectionImagePath: 'CMS/NewsLetter/' + filename,
          SectionImageFile: files,
        })
      } else if (name === 'Content1ImageName') {
        setNewsLetter({
          ...newsLetter,
          Content1ImageName: filename,
          Content1ImagePath: 'CMS/NewsLetter/' + filename,
          Content1ImageFile: files,
        })
      } else if (name === 'Content2ImageName') {
        setNewsLetter({
          ...newsLetter,
          Content2ImageName: filename,
          Content2ImagePath: 'CMS/NewsLetter/' + filename,
          Content2ImageFile: files,
        })
      }
    }
    else {
      setFormError({
        ...formError, [
          name === 'SectionImageName' ? 'SectionImagePath'
            : name === 'Content1ImageName' ? 'Content1ImagePath'
              : name === 'Content2ImageName' ? 'Content2ImagePath' : ''
        ]: 'Please upload valid image such as PNG, JPEG, & JPG only.'
      });
    }
    e.target.value = null
  }

  const checkValidation = () => {
    let errorMessages = {};
    let isError = true;

    if (!newsLetter?.SectionImagePath) {
      errorMessages['SectionImagePath'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    } else if (newsLetter.SectionImageFile) {
      const files = newsLetter.SectionImageFile;
      let fileSize = files.size / 1024;
      fileSize = fileSize / 1024
      if (!isImagePngJpegJpg(files.name) && files) {
        errorMessages['SectionImagePath'] = 'Please upload valid image such as PNG, JPEG, & JPG only.'
        isError = false;
      } else if (fileSize > 5) {
        errorMessages['SectionImagePath'] = 'Cannot upload file that exceeds 5 MB.'
        isError = false;
      }
    }

    if (!newsLetter?.Content1ImagePath) {
      errorMessages['Content1ImagePath'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    } else if (newsLetter.Content1ImageFile) {
      const files = newsLetter.Content1ImageFile;
      let fileSize = files.size / 1024;
      fileSize = fileSize / 1024
      if (!isImagePngJpegJpg(files.name) && files) {
        errorMessages['Content1ImagePath'] = 'Please upload valid image such as PNG, JPEG, & JPG only.'
        isError = false;
      } else if (fileSize > 5) {
        errorMessages['Content1ImagePath'] = 'Cannot upload file that exceeds 5 MB.'
        isError = false;
      }
    }

    if (!newsLetter?.Content2ImagePath) {
      errorMessages['Content2ImagePath'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    } else if (newsLetter.Content2ImageFile) {
      const files = newsLetter.Content2ImageFile;
      let fileSize = files.size / 1024;
      fileSize = fileSize / 1024

      if (!isImagePngJpegJpg(files.name) && files) {
        errorMessages['Content2ImagePath'] = 'Please upload valid image such as PNG, JPEG, & JPG only.'
        isError = false;
      } else if (fileSize > 5) {
        errorMessages['Content2ImagePath'] = 'Cannot upload file that exceeds 5 MB.'
        isError = false;
      }
    }

    if (htmlToText(newsLetter?.BodyText1).trim().length <= 0) {
      errorMessages['BodyText1'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    }
    // else if(newsLetter.BodyText1.length>500){
    //   errorMessages['BodyText1'] = 'Max length should be this field 500 character.'
    //   isError = false;
    // }
    // .trim().length <= 0
    if (htmlToText(newsLetter?.BodyText2).trim().length <= 0) {
      errorMessages['BodyText2'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    }
    // else if(newsLetter.BodyText2.length>500){
    //   errorMessages['BodyText2'] = 'Max length should be this field 500 character.'
    //   isError = false;
    // }
    setFormError(errorMessages);
    return isError;
  }

  const addNewsLetters = async () => {
    const isValid = checkValidation();
    let statusoftoken = await verifytoken();
    if (statusoftoken) {
      if (isValid) {
        dispatch(loaderActions.start());
        const updateNewsLetter = await postUsersData('/master/v1/insertorupdatenewsletter', newsLetter);

        if (updateNewsLetter.status) {
          newsLetter?.SectionImageFile && await ImageUpload('CMS/NewsLetter/', newsLetter?.SectionImageName, newsLetter?.SectionImageFile);
          newsLetter?.Content1ImageFile && await ImageUpload('CMS/NewsLetter/', newsLetter?.Content1ImageName, newsLetter?.Content1ImageFile);
          newsLetter?.Content2ImageFile && await ImageUpload('CMS/NewsLetter/', newsLetter?.Content2ImageName, newsLetter?.Content2ImageFile);

          dispatch({ type: 'ALERTING', message: updateNewsLetter.message })
          dispatch(loaderActions.end());
        }
        else {
          dispatch(loaderActions.end());
          dispatch({ type: 'ALERTING', message: updateNewsLetter.message })
          return;
        }

      }
    } else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      dispatch(loaderActions.end());
    }
  }

  const clearData = () => {
    setFormError({});
    setNewsLetter({
      ...newsLetter,
      SectionImageName: '',
      SectionImagePath: '',
      SectionImageFile: '',

      Content1ImageName: '',
      Content1ImagePath: '',
      Content1ImageFile: '',
      BodyText1: '',

      Content2ImageName: '',
      Content2ImagePath: '',
      Content2ImageFile: '',
      BodyText2: '',
    })
  }
  useEffect(() => {
    const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
    if (richtexteboxheight > 1253) {
      setRichBoxHeight(true)
    }
    else {
      setRichBoxHeight(false)
    }
  }, []);
  return (
    <div className='p-4'>
      <div className="container-fluid heading_color mb-3">
        <h4>Newsletters</h4>
      </div>
      <div className="container-fluid heading_color">

        <form action=" " >
          <div class="row mb-3">
            <div className='col-md-6'>
              <div className='row'>
                <div class="col-lg-2 col-md-12 col-sm-12">
                  <label for="inputPassword6" class="col-form-label required">Section Image</label>
                </div>

                <div class=" col-lg-10 col-md-12 col-sm-12">
                  <div class="input-group">
                    <input
                      type="file"
                      name='SectionImageName'
                      id="SectionImageName-id"
                      className="form-control btn_add"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => handleUploadImageLoad('SectionImageName', e)}
                    />
                    <label class="input-group-text" htmlFor="SectionImageName-id">Browse</label>
                  </div>
                  {newsLetter.SectionImageName && <div className=' text-start'>{newsLetter.SectionImageName}</div>}
                  {formError.SectionImagePath && <div className='text-danger text-start'>{formError.SectionImagePath}</div>}
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div className='col-md-6'>
              <div className='row'>

                <div class="col-xl-2  col-12 mb-1">
                  <label for="inputPassword6" class="col-form-label required">Content 1</label>
                </div>

                <div class="col-xl-10 col-12 mb-lg-1 mb-md-1">
                  <div class="input-group">
                    <input
                      type="file"
                      name='Content1ImageName'
                      id="Content1ImageName-id"
                      className="form-control btn_add"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => handleUploadImageLoad('Content1ImageName', e)}
                    />
                    <label class="input-group-text" htmlFor="Content1ImageName-id">Browse</label>
                  </div>
                  {newsLetter.Content1ImageName && <div className=' text-start'>{newsLetter.Content1ImageName}</div>}
                  {formError.Content1ImagePath && <div className='text-danger text-start'>{formError.Content1ImagePath}</div>}
                  <div class="newsletter-richtext mt-2">
                    <RichTextBox
                      field="BodyText1"
                      fieldvalue={newsLetter.BodyText1}
                      tabIndex={1}
                      OnChangeofRichBox={(e) => handleOnChange('BodyText1', e)}
                      textconfig={richtextboxconfig}
                    />
                    {formError.BodyText1 && <div className='text-danger text-start'>{formError.BodyText1}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='row'>

                <div class="col-xl-2 col-lg-12 col-md-12 mb-1">
                  <label for="inputPassword6" class="col-form-label required">Content 2</label>
                </div>

                <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                  <div class="input-group">
                    <input
                      name='Content2ImageName'
                      type="file"
                      id="Content2ImageName-id"
                      className="form-control btn_add"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => handleUploadImageLoad('Content2ImageName', e)}
                    />
                    <label class="input-group-text" htmlFor="Content2ImageName-id">Browse</label>
                  </div>
                  {newsLetter.Content2ImageName && <div className=' text-start'>{newsLetter.Content2ImageName}</div>}
                  {formError.Content2ImagePath && <div className='text-danger text-start'>{formError.Content2ImagePath}</div>}
                  <div class="newsletter-richtext mt-2">
                    <RichTextBox
                      field="BodyText2"
                      fieldvalue={newsLetter.BodyText2}
                      tabIndex={2}
                      OnChangeofRichBox={(e) => handleOnChange('BodyText2', e)}
                      textconfig={richtextboxconfig}
                    />
                    {formError.BodyText2 && <div className='text-danger text-start'>{formError.BodyText2}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center pt-5">
            <button class="btn btn-primary mx-1" id="btnAdd" type='button'
              onClick={() => addNewsLetters()}
              disabled={!useredit && !useradd}
            >{newsLetter?.NewsLetterId ? 'Update' : 'Add'}</button>
            <button type="button" class="btn btn-primary mx-1" id="btnClear"
              disabled={!useredit && !useradd}
              onClick={() => clearData()}>Clear</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewsLetter
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select';
import { getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../_actions/loader.actions';
import { isImagePngJpegJpg } from '../../../commonClass/CommonValidation';
import { ImageDelete, ImageUpload } from '../../../../ImageUpload/ImageCommon';
import CustomTable from '../../../../customControl/CustomTable';
import { useLocation } from 'react-router-dom';

function getDateCombination() {
    var today = new Date();
    let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
    let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
    return date + '_' + time;
}


const PopularFlights = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { useredit, useradd, name } = location.state || {};
    const userId = GetLocalStorage();
    const [countryCity, setCountryCity] = useState();
    const [popularFlightList, setPopularFlighList] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [formError, setFormError] = useState({});
    const [deletedImage, setDeletedImage] = useState([]);

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('4');
    const [serchdisable, setSerachDisable] = useState(false);
    const [clrshow, setsclshow] = useState(false);
    const [formValue, setFormValue] = useState(
        {
            UserId: userId?.userMasterID,
            FromCountryId: 0,
            FromLocationId: 0,
            FromImageName: "",
            FromImagePath: "",
            FromImageFile: "",

            ToCountryId: 0,
            ToLocationId: 0,
            ToImageName: "",
            ToImagePath: "",
            ToImageFile: ""
        }
    )

    const getcountrystatecity = async (travelCategoryId) => {
        const countrystatecity = await getAllData(`/master/v1/getcountrystatecity`);
        if (countrystatecity?.status) {
            setCountryCity({
                countryData: countrystatecity?.data?.countryData?.map((contry) => ({ label: contry?.CountryName, value: contry?.CountryId })),
                cityData: countrystatecity?.data?.cityData?.map((city) => (
                    { label: city?.City, value: city?.LocationId, CountryId: city?.CountryId }
                )),
            });
        }
        dispatch(loaderActions.end());
    }

    const getPopularFlight = async (travelCategoryId) => {

        const popularflightlist = await getAllData(`/master/v1/getpopularflightlist`);

        if (popularflightlist?.status) {
            setPopularFlighList(popularflightlist?.data)
            setFilterData(popularflightlist?.data)
        }
        else {
            setPopularFlighList([])
            setFilterData([])
        }

        dispatch(loaderActions.end());
    }

    //#region List data
    useEffect(() => {
        dispatch(loaderActions.start());

        const fetchData = async () => {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                let token = GetLocalStorage();
                if (token && token.userName && token.token) {
                    getPopularFlight();
                    getcountrystatecity();
                }
            } else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        fetchData();
    }, []);

    const handleDropDown = (name, val) => {
        setFormError({});
        setFormValue((pre) => ({ ...pre, [name]: val.value }));
        if (name === 'FromCountryId') {
            setFormValue((pre) => ({ ...pre, 'FromLocationId': '' }));
        } else if (name === 'ToCountryId') {
            setFormValue((pre) => ({ ...pre, 'ToLocationId': '' }));
        }
    }

    const FromCityOptions = useMemo(() => countryCity?.cityData?.filter((city) => city?.CountryId === formValue?.FromCountryId), [formValue?.FromCountryId]);
    const toCityOptions = useMemo(() => countryCity?.cityData?.filter((city) => city?.CountryId === formValue?.ToCountryId), [formValue?.ToCountryId]);

    const handleUploadImageLoad = (name, e) => {
        setFormError({});
        if (isImagePngJpegJpg(e.target.files[0].name)) {
            const files = e.target.files[0];
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');

            if (name === 'FromImageName') {
                setDeletedImage([...deletedImage, formValue?.FromImagePath])
                setFormValue({
                    ...formValue,
                    FromImageName: filename,
                    FromImagePath: 'CMS/popularFlight/' + filename,
                    FromImageFile: files,
                })
            } else if (name === 'ToImageName') {
                setDeletedImage([...deletedImage, formValue?.ToImagePath])
                setFormValue({
                    ...formValue,
                    ToImageName: filename,
                    ToImagePath: 'CMS/popularFlight/' + filename,
                    ToImageFile: files,
                })
            }
        }
        else {
            setFormError({
                ...formError, [name === 'FromImageName' ? 'FromImageName' : name === 'ToImageName' ? 'ToImageName' : '']: 'Please upload valid image such as PNG, JPEG, & JPG only.'
            });
        }
        e.target.value = null
    }

    const checkValidation = () => {
        let errorMessages = {};
        let isError = true;

        if (!formValue.FromCountryId) {
            errorMessages['FromCountryId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (!formValue.FromLocationId) {
            errorMessages['FromLocationId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (!formValue.ToCountryId) {
            errorMessages['ToCountryId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (!formValue.ToLocationId) {
            errorMessages['ToLocationId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }

        if (formValue.ToLocationId || formValue.FromLocationId) {
            if (formValue.ToLocationId === formValue.FromLocationId) {
                errorMessages['ToLocationId'] = 'From & To Cities name cannot be same! Please choose different city.'
                errorMessages['FromLocationId'] = 'From & To Cities name cannot be same! Please choose different city.'
                isError = false;
            }
        }

        if (!formValue?.FromImagePath) {
            errorMessages['FromImageName'] = 'Can not leave blank, input is mandatory.'
            isError = false;
        } else if (formValue.FromImageFile) {
            const files = formValue.FromImageFile;
            let fileSize = files.size / 1024;
            fileSize = fileSize / 1024
            if (!isImagePngJpegJpg(files.name) && files) {
                errorMessages['FromImageName'] = 'Please upload valid image such as PNG, JPEG, & JPG only.'
                isError = false;
            } else if (fileSize > 5) {
                errorMessages['FromImageName'] = 'Can not upload file that exceeds 5 MB.'
                isError = false;
            }
        }

        if (!formValue?.ToImagePath) {
            errorMessages['ToImageName'] = 'Can not leave blank, input is mandatory.'
            isError = false;
        } else if (formValue.ToImageFile) {
            const files = formValue.ToImageFile;
            let fileSize = files.size / 1024;
            fileSize = fileSize / 1024
            if (!isImagePngJpegJpg(files.name) && files) {
                errorMessages['ToImageName'] = 'Please upload valid image such as PNG, JPEG, & JPG only.'
                isError = false;
            } else if (fileSize > 5) {
                errorMessages['ToImageName'] = 'Can not upload file that exceeds 5 MB.'
                isError = false;
            }
        }

        setFormError(errorMessages);
        return isError;
    }

    const onSubmitPoplarFlight = async () => {
        const isValid = checkValidation();
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (isValid) {
                dispatch(loaderActions.start());
                const updateNewsLetter = await postUsersData('/master/v1/insertorupdatepopularflights', formValue);

                if (updateNewsLetter.status) {
                    formValue?.FromImageFile && await ImageUpload('CMS/popularFlight/', formValue?.FromImageName, formValue?.FromImageFile);
                    formValue?.ToImageFile && await ImageUpload('CMS/popularFlight/', formValue?.ToImageName, formValue?.ToImageFile);
                    deletedImage.length > 0 && deletedImage.forEach(async (deletedImagePath) => await ImageDelete(deletedImagePath));
                    getPopularFlight();
                    onClearButton();
                    dispatch({ type: 'ALERTING', message: updateNewsLetter.message })
                    dispatch(loaderActions.end());
                }
                else {
                    dispatch(loaderActions.end());
                    dispatch({ type: 'ALERTING', message: updateNewsLetter.message })
                    return;
                }
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end());
    }

    const columns = [
        {
            name: 'Sr. No.',
            sortable: true,
            float: "left",
            minWidth: "20px",
            maxWidth: "20px",
            selector: (data, index) => index + 1,
        },
        {
            name: 'Country to Country',
            sortable: true,
            float: "left",
            selector: (data) => data.Countries,
        },
        {
            name: 'City to City',
            sortable: true,
            float: "left",
            selector: (data) => data.Cities,
        },
        {
            name: 'Edit',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr'
                onClick={() => onEditorView(data, 'edit')}
                disabled={!useredit}
            ><i className="fa fa-edit eye-icon"></i></button>
        },
        {
            name: 'View',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'view')} ><i className="fa fa-eye" ></i></button> 
        }
    ]

    const onEditorView = async (data, type) => {
        dispatch(loaderActions.start());
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            setSerachDisable(true)
            const PFD = await getAllData(`/master/v1/getpopularflightbyid?popularFlightsId=${data?.PopularFlightsId}`);
            setFormError({});
            if (PFD?.status) {
                const selectedFromCity = countryCity?.cityData?.find((city) => city?.value === PFD?.data?.FromLocationId);
                const selectedToCity = countryCity?.cityData?.find((city) => city?.value === PFD?.data?.ToLocationId);

                setFormValue(
                    {
                        UserId: userId?.userMasterID,
                        PopularFlightsId: PFD?.data?.PopularFlightsId,

                        FromCountryId: selectedFromCity?.CountryId,
                        FromLocationId: PFD?.data?.FromLocationId,
                        FromImageName: PFD?.data?.FromImageName,
                        FromImagePath: PFD?.data?.FromImagePath,
                        FromImageFile: "",

                        ToCountryId: selectedToCity?.CountryId,
                        ToLocationId: PFD?.data?.ToLocationId,
                        ToImageName: PFD?.data?.ToImageName,
                        ToImagePath: PFD?.data?.ToImagePath,
                        ToImageFile: "",
                        type: type
                    }
                )
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            dispatch(loaderActions.end());
        }
        dispatch(loaderActions.end());
    }


    const onClearButton = () => {
        setSerachDisable(false)
        setFormError({});
        setFormValue(
            {
                UserId: userId?.userMasterID,
                PopularFlightsId: 0,
                FromCountryId: '',
                FromLocationId: '',
                FromImageName: "",
                FromImagePath: "",
                FromImageFile: "",
                ToCountryId: '',
                ToLocationId: '',
                ToImageName: "",
                ToImagePath: "",
                ToImageFile: ""
            }
        )
    }

    const serachoption = [
        {
            label: 'Country To Country',
            value: '1'
        },
        {
            label: 'City To City',
            value: '2'
        },
        {
            label: 'All',
            value: '4'
        },
    ];
    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getPopularFlight();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const matchesSearchValueAll = (issec, fields) => {
        let search = searchvalue
        return fields.some((data) =>
            issec[data]?.toString().toLowerCase().includes(search.toLowerCase())
        );
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            var filteritem = filterData;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec?.Countries.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec?.Cities.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            if (searchby === '4') {
                filterdata = filteritem?.filter((issec) => {
                    let keys = Object.keys(issec)
                    return matchesSearchValueAll(issec, keys) && issec
                });
            }
            setPopularFlighList(filterdata)
        }
    }


    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getPopularFlight();
    }

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Flights Merchandising</h3>
            </div>
            <div class="container-fluid form_border pb-5 pt-4 my-3">
                <div className='px-xl-5'>
                    <div className=" heading_color mb-3">
                        <h4>{name}</h4>
                    </div>

                    <form action=" " className=' mb-5' >
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className='col-12 my-2'><h5>From</h5></div>
                                </div>
                                <div className='row my-1'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Country</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <Select
                                                id='FromCountryId'
                                                className="dropdown w-100"
                                                options={countryCity?.countryData}
                                                value={countryCity?.countryData?.find((serachoption) => serachoption.value === formValue?.FromCountryId) || null}
                                                onChange={(val) => handleDropDown("FromCountryId", val)}
                                                isDisabled={formValue?.type === 'view' ? true : false}
                                            />
                                        </div>
                                        {formError.FromCountryId && <div className='text-danger text-start'>{formError.FromCountryId}</div>}
                                    </div>
                                </div>
                                <div className='row my-2'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label required">City</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <Select
                                                className="dropdown w-100"
                                                id='FromLocationId'
                                                options={FromCityOptions}
                                                value={FromCityOptions?.filter((serachoption) => serachoption.value === formValue?.FromLocationId) || null}
                                                onChange={(val) => handleDropDown('FromLocationId', val)}
                                                isDisabled={formValue?.type === 'view' ? true : false || formValue?.FromCountryId ? false : true}
                                            // isSearchable={false}
                                            />
                                        </div>
                                        {formError.FromLocationId && <div className='text-danger text-start'>{formError.FromLocationId}</div>}
                                    </div>
                                </div>
                                <div className='row my-2'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label required">Images</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <input
                                                type="file"
                                                name='FromImageName'
                                                id="FromImageName-id"
                                                className="form-control btn_add"
                                                accept=".png,.jpg,.jpeg"
                                                onChange={(e) => handleUploadImageLoad('FromImageName', e)}
                                                disabled={formValue?.type === 'view' ? true : false}
                                            />
                                            <label class="input-group-text" htmlFor="FromImageName-id">Browse</label>
                                        </div>
                                        {formValue.FromImageName && <div className=' text-start w-100 text-break'>{formValue.FromImageName}</div>}
                                        {formError.FromImageName && <div className='text-danger text-start'>{formError.FromImageName}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className='col-12 my-2'><h5>To</h5></div>
                                </div>
                                <div className='row my-1'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label required">Country</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <Select
                                                className="dropdown w-100"
                                                id='ToCountryId'
                                                options={countryCity?.countryData}
                                                value={countryCity?.countryData?.filter((serachoption) => serachoption.value === formValue?.ToCountryId) || null}
                                                onChange={(val) => handleDropDown("ToCountryId", val)}
                                                isDisabled={formValue?.type === 'view' ? true : false}
                                            // isSearchable={false}

                                            />
                                        </div>
                                        {formError.ToCountryId && <div className='text-danger text-start'>{formError.ToCountryId}</div>}
                                    </div>
                                </div>
                                <div className='row my-2'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label required">City</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <Select
                                                className="dropdown w-100"
                                                id='ToLocationId'
                                                options={toCityOptions}
                                                value={toCityOptions?.filter((serachoption) => serachoption.value === formValue?.ToLocationId) || null}
                                                onChange={(val) => handleDropDown('ToLocationId', val)}
                                                isDisabled={formValue?.type === 'view' ? true : false || formValue?.ToCountryId ? false : true}

                                            />
                                        </div>
                                        {formError.ToLocationId && <div className='text-danger text-start'>{formError.ToLocationId}</div>}
                                    </div>
                                </div>
                                <div className='row my-2'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label required">Images</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <input
                                                type="file"
                                                name='ToImageName'
                                                id="ToImageName-id"
                                                className="form-control btn_add"
                                                accept=".png,.jpg,.jpeg"
                                                onChange={(e) => handleUploadImageLoad('ToImageName', e)}
                                                disabled={formValue?.type === 'view' ? true : false}
                                            />
                                            <label class="input-group-text" htmlFor="ToImageName-id">Browse</label>
                                        </div>
                                        {formValue.ToImageName && <div className=' text-start w-100 text-break'>{formValue.ToImageName}</div>}
                                        {formError.ToImageName && <div className='text-danger text-start w-100 text-breck'>{formError.ToImageName}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>


                    <div className="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input
                                        type="text"
                                        onKeyDown={onKeyDownHandler}
                                        id="btnSearchText"
                                        className="form-control"
                                        placeholder='Enter keyword'
                                        name="SearchValue"
                                        onChange={handleChangeSearchValue}
                                        value={searchvalue || ''}
                                        disabled={serchdisable ? true : false}
                                        autoComplete='off'
                                    />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div className="p-2 text-center">
                                <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                    </div>
                    <CustomTable
                        columns={columns}
                        data={popularFlightList}
                        PdfExport={false}
                        ExportToExcel={false}
                        disabled={false}
                    />

                    <div class="d-flex justify-content-center">
                        {
                            formValue?.type === 'edit'
                                ? (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
                                    onClick={() => onSubmitPoplarFlight()}
                                    disabled={!useredit}
                                >Update</button>
                                )
                                : (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
                                    onClick={() => onSubmitPoplarFlight()}
                                    disabled={!useradd || popularFlightList?.length >= 8 ? true : false}
                                >Add</button>)
                        }

                        <button type="button" class="btn btn-primary mx-1" id="btnClear"
                            onClick={() => onClearButton({})}
                        // disabled={Object.keys(homePageSection).length === 0 ? true : false || homePageSection?.type === 'view' ? true : false}
                        >Clear</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopularFlights
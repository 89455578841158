import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BulkUpdateModal from './partial/BulkUpdateModal'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { ClearLocalStorage, GetLocalStorage } from '../../../../../commonClass/Session';
import { useDispatch, useSelector } from 'react-redux';
import { getAllData, postUsersData, verifytoken } from '../../../../../../services/ApiCallingUserServices';
import { loaderActions } from '../../../../../../_actions/loader.actions';
import { hotelRateInventoryClose } from '../../../../../../_actions/user.action';

const RateAndInventory = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const hotelType = localStorage.getItem('type')
    const isUpdate = useSelector(store => store?.hotelrateinventory?.isUpdate)

    const BulkUpdateDrodownList = [
        { id: 1, name: 'Inventory', alias: 'Bulk Inventory' },
        { id: 2, name: 'Rates', alias: 'Bulk Rates' },
        { id: 3, name: 'Blackout', alias: 'Blackout' },
        // { id: 5, name: 'Unblock Rooms', alias: 'Unblock Room' },
    ]

    const [formValue, setFormValue] = useState({
        HotelBasicId: 0,
        HotelRoomId: 0,
        SelecteDate: null,
        UserId: 0,
    })

    const [roomlist, setRoomlist] = useState([])
    const [mealId, SetMealId] = useState(0)
    const [mealList, setMealList] = useState([])
    const [dayRangeList, setDayRangeList] = useState([])
    const [selectedRoom, setSelectedRoom] = useState(null)
    const [RoomAvailabilityDates, setRoomAvailabilityDates] = useState({
        StartDate: '',
        EndDate: ''
    })

    // set end date for calander (actual room's end date - 4)
    const lastRequiredDate = new Date(new Date(RoomAvailabilityDates.EndDate).setDate(new Date(RoomAvailabilityDates.EndDate).getDate() - 4))

    const [modalTitle, setModalTitle] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [modalTypeEdit, setModalTypeEdit] = useState(false)
    const [selectedCellDate, setSelectedCellDate] = useState(null)

    const [dateRangeValue, setDateRangeValue] = useState('')
    const [startDate, setStartDate] = useState(moment().startOf('day'));
    const [hotelRateInventory, setHotelRateInventory] = useState([])

    //#region navigation function for Back and Next button
    const handleNavigation = (path) => {
        navigate(path, {
            state: {
                disabled: location?.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }
    //#endregion

    //#region Room, Meal Type and Days Range List API
    const gethotelroomtypeandmealtypelist = async (id) => {
        const hotelroomtypeandmealtypelist = await getAllData(`/master/v1/gethotelroomtypeandmealtypelist?hotelBasicId=${id}`)
        if (hotelroomtypeandmealtypelist?.status) {
            let roomList = hotelroomtypeandmealtypelist?.data?.roomTypeList?.map((obj) => ({
                id: obj?.HotelRoomId,
                label: obj?.RoomTypeName,
                RoomAvailabilityFromDate: obj?.RoomAvailabilityFromDate,
                RoomAvailabilityToDate: obj?.RoomAvailabilityToDate,
                ExtraBeddingAllowed: obj?.ExtraBeddingAllowed,
                isActive: new Date(obj?.RoomAvailabilityToDate) >= new Date(formFormatDate(new Date()) + 'T00:00:00')
            }))

            let meals = hotelroomtypeandmealtypelist?.data?.mealTypeList?.map((obj) => ({
                id: obj?.RateTypeId,
                label: obj?.RateTypeName,
            }))
            let days = hotelroomtypeandmealtypelist?.data?.daysRangeList?.map((obj) => ({
                id: obj?.DayRangesId,
                label: obj?.DaysName,
            }))
            setRoomlist(roomList)
            setMealList(meals)
            setDayRangeList(days)
            setSelectedRoom(roomList?.[0])
            let RoomStartDate = setStartDateOfTable(roomList?.[0]?.RoomAvailabilityFromDate, roomList?.[0]?.RoomAvailabilityToDate)
            setStartDate(RoomStartDate)
            setFormValue((form) => ({ ...form, HotelRoomId: roomList?.[0]?.id }))
        }
    }
    //#endregion

    //#region Rate and Inventory Values API
    const gethotelinventory = async (data) => {
        const hotelinventory = await postUsersData('/master/v1/gethotelinventory', data)
        if (hotelinventory?.status) {
            setHotelRateInventory(hotelinventory?.data)
            dispatch(hotelRateInventoryClose())
        }
    }
    //#endregion

    //#region set Selected Room's Availability Dates and call Rate and Inventory API function
    useEffect(() => {
        async function fetchData(data) {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                try {
                    dispatch(loaderActions.start())
                    gethotelinventory(data)
                } catch (error) {
                    console.error(`Error fetching data: ${error}`)
                } finally {
                    dispatch(loaderActions.end())
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                dispatch(loaderActions.end())
            }
        }
        if (selectedRoom) {
            setRoomAvailabilityDates((roomdate) => ({ ...roomdate, StartDate: selectedRoom?.RoomAvailabilityFromDate, EndDate: selectedRoom?.RoomAvailabilityToDate }))
            let data = {
                HotelBasicId: formValue?.HotelBasicId,
                HotelRoomId: selectedRoom?.id,
                FromDate: `${formFormatDate(startDate)}T00:00:00`,
                ToDate: `${formFormatDate(startDate.clone().add(4, 'days'))}T00:00:00`,
            }
            fetchData(data)
        }
    }, [selectedRoom, startDate, formValue?.HotelBasicId])
    //#endregion

    //#region call Rate and Inventory API function when Inventory or Rate updated through pop-up
    useEffect(() => {
        if (isUpdate) {
            let data = {
                HotelBasicId: formValue?.HotelBasicId,
                HotelRoomId: selectedRoom?.id,
                FromDate: `${formFormatDate(startDate)}T00:00:00`,
                ToDate: `${formFormatDate(startDate.clone().add(4, 'days'))}T00:00:00`,
            }
            dispatch(loaderActions.start())
            gethotelinventory(data).then(() => dispatch(loaderActions.end()))
        }
    }, [isUpdate])
    //#endregion

    //#region call Room and Meal Type List API
    useEffect(() => {

        async function fetchData() {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                const user = GetLocalStorage()
                setFormValue((form) => ({ ...form, HotelBasicId: location?.state?.HotelId, UserId: user?.userMasterID }))
                try {
                    dispatch(loaderActions.start())
                    await Promise.all([
                        gethotelroomtypeandmealtypelist(location?.state?.HotelId)
                    ])

                } catch (error) {
                    console.error(`Error fetching data: ${error}`)
                } finally {
                    dispatch(loaderActions.end())
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                dispatch(loaderActions.end())
            }

        }
        if (location?.state !== null) {
            fetchData()
        }
        else {
            navigate(-1)
        }
    }, [location])
    //#endregion

    //#region Room Type On Change Method
    const onSelectChange = (name, value) => {
        setSelectedRoom(value)
        let RoomStartDate = setStartDateOfTable(value?.RoomAvailabilityFromDate, value?.RoomAvailabilityToDate)
        setStartDate(RoomStartDate)
        setFormValue(form => ({ ...form, [name]: value?.id, SelecteDate: null }))
        setDateRangeValue('')
    }
    //#endregion

    //#region Select Date On Change
    const handleSelectDate = (update) => {
        setFormValue(form => ({ ...form, SelecteDate: update }))
        setDateRangeValue(`${formatDate(update)}`)
        setStartDate(moment(update))
    }
    //#endregion

    //#region Date Icon click function
    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    };
    //#endregion

    //#region Table Start Date depends on room
    const setStartDateOfTable = (startdate, enddate) => {
        return moment().startOf('day') <= new Date(startdate) ?
            moment(new Date(startdate))
            :
            moment().startOf('day') >= moment(new Date(enddate)).clone().subtract(4, 'days') ?
                moment(new Date(enddate)).clone().subtract(4, 'days')
                :
                moment().startOf('day')
    }
    //#endregion

    //#region date formate functions
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = '' + d.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? "" : [day, month, year].join('/');
    }

    function formFormatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? null : [year, month, day].join('-');
    }
    //#endregion

    //#region Table Calander Previous button
    const goToPreviousWeek = (e) => {
        e.preventDefault()
        if (startDate.clone().subtract(5, 'days') <= new Date(RoomAvailabilityDates.StartDate)) {
            setStartDate(moment(new Date(RoomAvailabilityDates.StartDate)));
        }
        else {
            setStartDate(startDate.clone().subtract(5, 'days'));
        }
    };
    //#endregion

    //#region Table Calander Next button
    const goToNextWeek = (e) => {
        e.preventDefault()
        if (startDate.clone().add(9, 'days') > new Date(RoomAvailabilityDates.EndDate)) {
            setStartDate(moment(lastRequiredDate));
        }
        else {
            setStartDate(startDate.clone().add(5, 'days'));
        }
    };
    //#endregion

    //#region Table Calander (Dates)
    const renderCalendar = () => {
        const days = [];
        let currentDate = startDate.clone();

        for (let i = 0; i < 5; i++) {
            days.push(
                <th key={currentDate.format('YYYY-MM-DD')}>
                    {
                        // eslint-disable-next-line no-loop-func
                        (() => {
                            if (i === 0) {
                                return (
                                    <div className="position-relative">
                                        <button
                                            className="slideButton slidePrev"
                                            id="slidePrev"
                                            type="button"
                                            disabled={startDate <= new Date(RoomAvailabilityDates.StartDate) || roomlist?.length <= 0}
                                            onClick={(e) => goToPreviousWeek(e)}
                                        >
                                            <img src={require("../../../../../../assets/img/expand_more.png")} alt="prev" />
                                        </button>
                                        <div className="date-and-day">
                                            <div className="text-nowrap header_date">{Number(currentDate.format('D')) > 9 ? currentDate.format('D') : '0' + currentDate.format('D')}-{currentDate.format('MMM')}-{currentDate.format('YYYY')}</div>
                                            <div className="header_day">{currentDate.format('dddd')}</div>
                                        </div>
                                    </div>
                                )
                            }
                            else if (i === 4) {
                                return (
                                    <div className="position-relative">
                                        <div className="date-and-day">
                                            <div className="text-nowrap header_date">{Number(currentDate.format('D')) > 9 ? currentDate.format('D') : '0' + currentDate.format('D')}-{currentDate.format('MMM')}-{currentDate.format('YYYY')}</div>
                                            <div className="header_day">{currentDate.format('dddd')}</div>
                                        </div>
                                        <button
                                            className="slideButton slideNext"
                                            id="slideNext"
                                            type="button"
                                            disabled={startDate.clone().add(4, 'days') >= new Date(RoomAvailabilityDates.EndDate) || roomlist?.length <= 0}
                                            onClick={(e) => goToNextWeek(e)}
                                        >
                                            <img src={require("../../../../../../assets/img/expand_more.png")} alt="next" />
                                        </button>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="date-and-day">
                                        <div className="text-nowrap header_date">{Number(currentDate.format('D')) > 9 ? currentDate.format('D') : '0' + currentDate.format('D')}-{currentDate.format('MMM')}-{currentDate.format('YYYY')}</div>
                                        <div className="header_day">{currentDate.format('dddd')}</div>
                                    </div>
                                )
                            }
                        })()
                    }

                </th>

            );
            currentDate = currentDate.clone().add(1, 'day');
        }
        return days;
    };
    //#endregion

    //#region set values on rate and inventory charts
    const renderCalendarInventoryStatic = (name, type, mealid) => {
        const days = [];
        let currentDate = startDate.clone();

        for (let i = 0; i < 5; i++) {
            const cellDate = currentDate.format(`yyyy-MM`) + '-' + (Number(currentDate.format('D')) > 9 ? currentDate.format('D') : '0' + currentDate.format('D')) // selcted cell Date for pop-up
            days.push(
                <>
                    {
                        // eslint-disable-next-line no-loop-func
                        hotelRateInventory?.map((item) => {
                            if (item?.inventoryDate === `${currentDate.format('YYYY-MM-DD')}T00:00:00`) {
                                return (
                                    <td
                                        className="inventory_disable"
                                        key={`${name}_${currentDate.format('YYYY-MM-DD')}`}
                                    // onDoubleClick={(e) => handleEdit(e, type, new Date(cellDate), mealid)}
                                    >
                                        <div className={`common-input`}>
                                            {type?.name === 'Inventory' ? item[name] :
                                                item?.ratesCollection?.filter((fil) => fil.rateTypeId === mealid)?.map((meal) => {
                                                    return meal[name];
                                                })
                                            }
                                        </div>
                                    </td>
                                )
                            }
                        })
                    }

                </>

            );
            currentDate = currentDate.clone().add(1, 'day');
        }
        return days;
    };
    //#endregion

    //#region handle Bulk Update Modals
    const handleModal = (name) => {
        setModalTitle(name)
        setOpenModal(true)
        setModalTypeEdit(false)
        setSelectedCellDate(null)
        SetMealId(0)
    }
    //#endregion

    //#region handle Edit Modals
    const handleEdit = (e, name, cellDate, mealid) => {
        e.preventDefault()
        setModalTitle(name)
        setOpenModal(true)
        setModalTypeEdit(true)
        setSelectedCellDate(cellDate)
        SetMealId(mealid)
    }
    //#endregion

    return (
        <>
            {
                openModal &&
                <BulkUpdateModal
                    open={openModal}
                    setOpenModal={setOpenModal}
                    title={modalTitle}
                    isEdit={modalTypeEdit}
                    roomId={formValue.HotelRoomId}
                    roomlist={roomlist}
                    selectedCellDate={selectedCellDate}
                    RoomAvailabilityDates={RoomAvailabilityDates}
                    mealId={mealId}
                    mealList={mealList}
                    dayRangeList={dayRangeList}
                    ExtraBeddingAllowed={selectedRoom?.ExtraBeddingAllowed}
                    HotelBasicId={formValue.HotelBasicId}
                    UserId={formValue.UserId}
                />
            }
            <div className="container-fluid mt-2 heading_color input_mui">
                <div className='row'>
                    <div className='col-8'>
                        <h3><i onClick={() => handleNavigation(`/HotelListing/${hotelType}/images`)} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Rates & Inventory</h3>
                    </div>
                    <div className='col-4 text-end'>
                        {/* <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd">Save</button> */}
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={() => handleNavigation(`/HotelListing/${hotelType}/policiesandrules`)}>Next</button>
                    </div>
                </div>

                {
                    selectedRoom?.isActive ?
                        <div className="d-flex justify-content-end align-items-center mb-4 mt-3" key="bulk-update-en">
                            <div
                                id="bulk_update_id"
                                role="button"
                                className="me-2 dropdown-toggle bulk_update_btn px-3 my-1 text-danger border-start"
                                data-bs-toggle="dropdown" aria-expanded="false"
                            >
                                Bulk Update
                            </div>
                            <div className="dropdown-menu dropdown-menu-end ms-4 dropdown_zindexset bulkupdate_dropdown" aria-labelledby="bulk_update_id">
                                <div className="bulk_dropdown">
                                    {
                                        BulkUpdateDrodownList?.map(element => {
                                            return (
                                                <div key={element.id} className="bulk_dropdown_item" role='button' onClick={() => handleModal(element)}>
                                                    {element.name}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="d-flex justify-content-end align-items-center mb-4 mt-3" key="bulk-update-dis">
                            <div className="me-2 bulk_update_btn px-3 my-1 text-danger border-start"> Bulk Update </div>
                        </div>
                }

                <div className="row rate_invenroty_searching mb-4">

                    <div className="col-md-6 col-12 mb-1">
                        <FormControl fullWidth>
                            <Autocomplete
                                clearIcon={false}
                                options={roomlist?.filter((item) => item?.id !== formValue.HotelRoomId)}
                                id="select-roomlist"
                                value={roomlist?.find((obj) => formValue?.HotelRoomId === obj?.id) || null}
                                name='HotelRoomId'
                                onChange={(e, newValue) => onSelectChange("HotelRoomId", newValue ? newValue : null)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Room Type" variant="filled" />
                                )}
                            />
                        </FormControl>
                    </div>

                    <div className="col-md-6 col-12 mb-1 rates">
                        <div className="text-container">
                            <TextField
                                fullWidth
                                id="inventoryselectdate"
                                variant='filled'
                                label="Select Date"
                                value={dateRangeValue}
                                onClick={(e) => openDatePicker("inventorydaterange")}
                                disabled={roomlist?.length <= 0}
                            />
                            <div>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control d-none'
                                    id='inventorydaterange'
                                    selected={formValue.SelecteDate}
                                    placeholderText="Select Date"
                                    autoComplete='off'
                                    showMonthDropdown
                                    onChange={(e) => handleSelectDate(e)}
                                    minDate={new Date(RoomAvailabilityDates.StartDate)}
                                    maxDate={lastRequiredDate}
                                    showYearDropdown
                                    dropdownMode='select'
                                    onFocus={(e) => e.target.readOnly = true}
                                />
                            </div>
                            <span className="example-custom-input bulkCalendarBtn"
                                onClick={(e) => openDatePicker("inventoryselectdate")}
                            ><i className="fa fa-calendar"></i></span>
                        </div>
                    </div>

                </div>

                {selectedRoom?.isActive === false && <div className="text-danger"> {selectedRoom?.label} room type's validity is expired </div>}

                <div className="table_hotel_individual_rates_inventory">
                    <div className="hotel_individual_rates_inventory">
                        <table className="rates-inventory-table-calender">
                            <thead>
                                <tr>
                                    <th className="rates_inventory_header_title">
                                        <h6 className='fw-bold'>Dates</h6>
                                    </th>
                                    {renderCalendar()}
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                    <div className="accordion hotel_rate_inventory" id='accordionRoom2'>

                        <div className="accordion-item">
                            <div
                                className="inventory-room-name accordion-button collapsed position-relative"
                                data-bs-toggle="collapse"
                                data-bs-target={`#room-type-${selectedRoom?.label?.replace(' ', '-')?.toLowerCase()}`}
                                aria-expanded="false"
                                aria-controls={`room-type-${selectedRoom?.label?.replace(' ', '-')?.toLowerCase()}`}
                            >
                                {selectedRoom?.label}
                                <div className="room_count"> {hotelRateInventory[0]?.roomCount} </div>
                            </div>
                            <div
                                id={`room-type-${selectedRoom?.label?.replace(' ', '-')?.toLowerCase()}`}
                                className="accordion-collapse collapse"
                            >
                                <div className="accordion inventory_data" id={`${selectedRoom?.label?.replace(' ', '-')?.toLowerCase()}`}>

                                    <div className="accordion-item">
                                        <div className="position-relative">
                                            {
                                                selectedRoom?.isActive ?
                                                    <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn edit_option" onClick={(e) => handleEdit(e, { id: 1, name: 'Inventory', alias: 'Inventory' }, null, 0)}>Edit</button>
                                                    :
                                                    <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn edit_option" disabled >Edit</button>
                                            }

                                            <div
                                                className="inventory-room-name-data accordion-button collapsed position-relative"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#deluxInventory'}
                                                aria-expanded="false"
                                                aria-controls={'deluxInventory'}
                                            >
                                                Inventory (Units)
                                                <div className="etm_room_count">{hotelRateInventory[0]?.etmAllocation} </div>
                                            </div>
                                            <div
                                                id={'deluxInventory'}
                                                className="accordion-collapse collapse"
                                            >
                                                <table className="rates-inventory-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="rates_inventory_header_title"></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label>ETM Allocation</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('inventoryStock', { id: 1, name: 'Inventory', alias: 'Inventory' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label>On Hold</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('blackOut', { id: 1, name: 'Inventory', alias: 'Inventory' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label className="text-right">Booked</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('soldOut', { id: 1, name: 'Inventory', alias: 'Inventory' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label className="text-right">Open</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('inventoryCount', { id: 1, name: 'Inventory', alias: 'Inventory' })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <div className="position-relative">
                                            {
                                                selectedRoom?.isActive ?
                                                    <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn edit_option" onClick={(e) => handleEdit(e, { id: 2, name: 'Rates', alias: 'Rates' }, null, 0)}>Edit</button>
                                                    :
                                                    <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn edit_option" disabled >Edit</button>
                                            }

                                            <div
                                                className="inventory-room-name-data accordion-button collapsed position-relative"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#deluxRates'}
                                                aria-expanded="false"
                                                aria-controls={'deluxRates'}
                                            >
                                                Rates (₹)
                                            </div>
                                            <div
                                                id={'deluxRates'}
                                                className="accordion-collapse collapse"
                                            >
                                                <table className="rates-inventory-table">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={2} className="rates_inventory_header_title"></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    {
                                                        mealList?.map((meal, index) => {
                                                            return (
                                                                <tbody key={meal?.id + index} className='colspan_2'>
                                                                    <tr>
                                                                        <td rowSpan={6} className='col_1 text-start'>
                                                                            <label>{meal?.label} </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-start col_2">
                                                                            <label>1 Adult</label>
                                                                        </td>
                                                                        {renderCalendarInventoryStatic('adults1', { id: 2, name: 'Rates', alias: 'Rates' }, meal?.id)}
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-start col_2">
                                                                            <label className="text-right">2 Adult</label>
                                                                        </td>
                                                                        {renderCalendarInventoryStatic('adults2', { id: 2, name: 'Rates', alias: 'Rates' }, meal?.id)}
                                                                    </tr>
                                                                    {
                                                                        selectedRoom?.ExtraBeddingAllowed &&
                                                                        <tr>
                                                                            <td className="text-start col_2">
                                                                                <label>Extra Adult with  bed / Mattress</label>
                                                                            </td>
                                                                            {renderCalendarInventoryStatic('adultWithBed', { id: 2, name: 'Rates', alias: 'Rates' }, meal?.id)}
                                                                        </tr>
                                                                    }
                                                                    <tr>
                                                                        <td className="text-start col_2">
                                                                            <label className="text-right">Extra Child without  bed / Mattress</label>
                                                                        </td>
                                                                        {renderCalendarInventoryStatic('childWithoutBed', { id: 2, name: 'Rates', alias: 'Rates' }, meal?.id)}
                                                                    </tr>
                                                                    {
                                                                        selectedRoom?.ExtraBeddingAllowed &&
                                                                        <tr>
                                                                            <td className="text-start col_2">
                                                                                <label className="text-right">Extra Child with  bed / Mattress</label>
                                                                            </td>
                                                                            {renderCalendarInventoryStatic('childWithBed', { id: 2, name: 'Rates', alias: 'Rates' }, meal?.id)}
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            )
                                                        })
                                                    }


                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default RateAndInventory;

import React, { Fragment, useEffect, useState } from 'react'
import "../../../assets/css/master.css"
import FooterPart1 from './Partial/FooterPart1'
import FooterPart2 from './Partial/FooterPart2'
import FooterPart3 from './Partial/FooterPart3'
import { useDispatch, useSelector } from 'react-redux'
import { loaderActions } from '../../../_actions/loader.actions'
import { getAllData, verifytoken } from '../../../services/ApiCallingUserServices'
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const footertab = [
        {
            id: 1,
            name: "Footer Part 1",
        },
        {
            id: 2,
            name: "Footer Part 2",
        },
        {
            id: 3,
            name: "Footer Part 3",
        },
    ]

    const [sectionId, setSectionId] = useState(1)
    const [footerPart1, setFooterPart1] = useState(null)
    const [footerPart2, setFooterPart2] = useState(null)
    const [filterItems2, setFilterItems2] = useState(null)
    const [footerTitleList, setFooterTitleList] = useState([])
    const [footerPart3, setFooterPart3] = useState(null)
    const [UserId, setUserId] = useState(0)

    const user = useSelector(store => store.userstatus.tba);
    const [userRights, setUserRights] = useState(true);

    //#region handle Rights
    useEffect(() => {
        let pagelink = user?.find(x => x?.PageLink?.toLowerCase() === window?.location?.pathname?.toLowerCase());
        let mid = user?.find(x => x?.MenuId === pagelink?.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserRights(obj);
        });
    }, [user])
    //#endregion

    //#region tab section on Click function
    const handleSections = (id) => {
        setSectionId(id)
    }
    //#endregion

    //#region Footer Part 1 API calling function
    const getfooterpart1 = async () => {
        const footerpart1 = await getAllData('/master/v1/getfooterpart1')
        if (footerpart1?.status) {
            setFooterPart1(footerpart1?.data[0]);
        }
    }
    //#endregion

    //#region Footer Title List API calling function for Footer Part 2
    const gettitlemasterlist = async () => {
        const titlemasterlist = await getAllData("/master/v1/gettitlemasterlist");
        if (titlemasterlist?.status) {
            let titles = titlemasterlist?.data?.filter((fil) => fil?.IsActive)?.map((item) => ({
                label: item?.Title,
                value: item?.TitleId
            }))
            setFooterTitleList(titles);
        }
        else {
            setFooterTitleList([]);
        }
    }
    //#endregion

    //#region Footer Part 2 List API calling function
    const getfooterpart2list = async () => {
        const footerpart2list = await getAllData('/master/v1/getfooterpart2list')
        if (footerpart2list?.status) {
            setFooterPart2(footerpart2list?.data);
            setFilterItems2(footerpart2list?.data);
        }
        else {
            setFooterPart2([]);
            setFilterItems2([]);
        }
    }
    //#endregion

    //#region Footer Part 3 API calling function
    const getfooterpart3 = async () => {
        const footerpart3 = await getAllData('/master/v1/getfooterpart3')
        if (footerpart3?.status) {
            setFooterPart3(footerpart3?.data[0]);
        }
    }
    //#endregion

    //#region Call All API
    useEffect(() => {
        async function fetchData() {
            dispatch(loaderActions.start());
            const statusoftoken = await verifytoken();
            if (statusoftoken) {
                const userdata = GetLocalStorage()
                setUserId(userdata?.userMasterID)
                await Promise.all([
                    getfooterpart1(),
                    gettitlemasterlist(),
                    getfooterpart2list(),
                    getfooterpart3(),
                ])
                dispatch(loaderActions.end());
            }
            else {
                dispatch(loaderActions.end())
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        }
        fetchData()
    }, [])
    //#endregion

    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>Merchandising</h3>
            </div>
            <div className=" row mx-1">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-lg-1">
                    <div className="faq_tab d-flex justify-content-center align-items-center flex-wrap">
                        <div className='container tab_section px-0'>
                            <ul className="nav nav-pills nav-justified mb-3 pt-3" id="pills-tab" role="tablist">
                                {
                                    footertab?.map((item) => {
                                        return (
                                            <li className="nav-item mx-lg-5 mx-md-1 mx-5 mt-1" role="presentation">
                                                <a className={`nav-link ${sectionId === item?.id ? 'active' : ''} `}
                                                    id={item.id} data-bs-toggle="pill"
                                                    type="button" role="tab"
                                                    aria-selected="false" onClick={(e) => handleSections(item?.id)}><strong>{item.name}</strong></a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid form_border pb-5 my-3">
                {
                    sectionId === 1 ?
                        <FooterPart1 footerPart1={footerPart1} UserId={UserId} userRights={userRights} />
                        :
                        sectionId === 2 ?
                            <FooterPart2 footerTitleList={footerTitleList} filterItems2={filterItems2} footerPart2={footerPart2} setFooterPart2={setFooterPart2} UserId={UserId} userRights={userRights} />
                            :
                            sectionId === 3 ?
                                <FooterPart3 footerPart3={footerPart3} UserId={UserId} userRights={userRights} />
                                :
                                <></>

                }
            </div>
        </>
    )
}

export default Footer

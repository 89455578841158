import React, { Suspense, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import { getAllData, postUsersData, verifytoken } from '../../../../../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../../../_actions/loader.actions';
import { ClearLocalStorage, GetLocalStorage } from '../../../../../commonClass/Session';
import { ImageDelete, ImageUpload } from '../../../../../../ImageUpload/ImageCommon';
import Legal from './Partial/Legal';
import Finance from './Partial/Finance';
import { isValidEmail } from '../../../../../commonClass/CommonValidation';
import OkModal from './Partial/OkModal';

const LegalAndFinance = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let hotelType = localStorage.getItem('type')
    const [imageSection, setImageSection] = useState([])
    const [value, setValue] = useState("Legal");
    const [formError, setFormError] = useState({});
    const [deletefile, setDeleteFile] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [formValue, setFormValue] = useState(
        {
            Legal: {
                HotelLegalId: 0,
                HotelBasicId: 0,
                PropertyDocumentFileName: '',
                PropertyDocumentFilePath: '',
                OwnerIdentityProofFileName: '',
                OwnerIdentityProofFilePath: '',
                IsGST: null,
                GSTNo: '',
                GSTFileName: '',
                GSTFilePath: '',
                UserId: 0,
            },
            Finance: {
                HotelFinanceId: 0,
                HotelBasicId: 0,
                IsAccountStatus: false,
                CompanyName: "",
                AccountEmail: "",
                AccountNumber: "",
                AccountName: "",
                BankName: "",
                IFSCCode: "",
                BranchName: "",
                SwiftCode: "",
                PANNumber: "",
                MICRNumber: "",
                VATNumber: "",
                MobileNumber: "",
                UserId: 0
            }
        }
    )

    const [save, setSave] = useState(false)
    const [next, setNext] = useState(true);

    //#region Back button function
    const handleBack = () => {
        navigate(`/HotelListing/${hotelType}/policiesandrules`, {
            state: {
                disabled: location?.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }
    //#endregion

    //#region Finish button function
    const handleBackBtnConfirmation = (action) => {
        if (action) {
            navigate('/Hotellistmaster');
        } else {
            setModalShow(false);
        }
    };
    //#endregion

    //#region Tab change function
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //#endregion

    //#region Room List API
    const gethotellegalbyhotelbasicid = async (id) => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            dispatch(loaderActions.start())
            const data = await getAllData(`/master/v1/gethotellegalbyhotelbasicid?hotelbasicid=${id}`)
            if (data?.status) {
                setFormValue(prevState => ({
                    ...prevState,
                    Legal: {
                        ...prevState.Legal,
                        PropertyDocumentFilePath: data?.data?.PropertyDocumentFilePath,
                        PropertyDocumentFileName: data?.data?.PropertyDocumentFileName,
                        OwnerIdentityProofFilePath: data?.data?.OwnerIdentityProofFilePath,
                        OwnerIdentityProofFileName: data?.data?.OwnerIdentityProofFileName,
                        IsGST: data?.data?.IsGst,
                        GSTNo: data?.data?.GSTNo,
                        GSTFilePath: data?.data?.GSTFilePath,
                        GSTFileName: data?.data?.GSTFileName,
                        HotelLegalId: data?.data?.HotelLegalId,
                    }
                }));
                let colllection = [
                    {
                        FileName: data?.data?.PropertyDocumentFileName,
                        fieldname: "PropertyDocumentFileName",
                        FilePath: data?.data?.PropertyDocumentFilePath,
                        needToUpload: false
                    },
                    {
                        FileName: data?.data?.OwnerIdentityProofFileName,
                        fieldname: "OwnerIdentityProofFileName",
                        FilePath: data?.data?.OwnerIdentityProofFilePath,
                        needToUpload: false
                    },
                    {
                        FileName: data?.data?.GSTFileName,
                        fieldname: "GSTFileName",
                        FilePath: data?.data?.GSTFilePath,
                        needToUpload: false
                    }
                ]
                setImageSection(colllection.filter(er => er.FileName !== ""))
                setDeleteFile(colllection.filter(er => er.FileName !== ""))
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }
    const gethotelfinancebyhotelbasicid = async (id) => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            dispatch(loaderActions.start())
            const data = await getAllData(`/master/v1/gethotelfinancebyhotelbasicid?hotelbasicid=${id}`)
            if (data?.status) {
                setSave(true)
                setNext(false)
                setFormValue(prevState => ({
                    ...prevState,
                    Finance: {
                        ...prevState.Finance,
                        AccountEmail: data?.data?.AccountEmail,
                        AccountName: data?.data?.AccountName,
                        AccountNumber: data?.data?.AccountNumber,
                        BankName: data?.data?.BankName,
                        BranchName: data?.data?.BranchName,
                        CompanyName: data?.data?.CompanyName,
                        HotelFinanceId: data?.data?.HotelFinanceId,
                        IFSCCode: data?.data?.IFSCCode,
                        IsAccountStatus: data?.data?.IsAccountStatus,
                        MICRNumber: data?.data?.MICRNumber,
                        MobileNumber: data?.data?.MobileNumber,
                        PANNumber: data?.data?.PANNumber,
                        SwiftCode: data?.data?.SwiftCode,
                        VATNumber: data?.data?.VATNumber,
                    }
                }));

            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }
    //#endregion

    //#endregion
    //#region Calling API function in side useEffect with Hotel Basic ID
    useEffect(() => {
        if (location?.state !== null) {
            if (location?.state?.HotelId) {
                const user = GetLocalStorage()
                setFormValue(prevState => ({
                    ...prevState,
                    Legal: {
                        ...prevState.Legal,
                        HotelBasicId: location?.state?.HotelId,
                        UserId: user?.userMasterID
                    }
                    , Finance: {
                        ...prevState.Finance,
                        HotelBasicId: location?.state?.HotelId,
                        UserId: user?.userMasterID
                    }
                }));
                gethotellegalbyhotelbasicid(location?.state?.HotelId)
                gethotelfinancebyhotelbasicid(location?.state?.HotelId)
            }
        }
        else {
            navigate(-1)
        }
    }, [location])
    //#endregion

    //#region Delete File function and API
    const handleDeleteFile = (filedname, filedpath) => {
        setFormError({});
        setImageSection(imageSection?.filter(er => er.fieldname !== filedname))
        setFormValue(prevState => ({ ...prevState, Legal: { ...prevState.Legal, [filedname]: "", [filedpath]: "" } }));
    }

    const deletemediabyhotelmediaid = (deletefile) => {
        let deleteImage = deletefile?.filter(obj => !imageSection.map(image => image.FileName)?.includes(obj.FileName))
        if (deleteImage?.length > 0) {
            deleteImage?.map(async (el) => {
                try {
                    const deleted = await ImageDelete(el?.FilePath);
                    if (deleted) {
                        dispatch(loaderActions.end())
                    }
                } catch (error) {
                    console.error(`Error Delete Image: ${error}`)
                }
            })

        }
    }
    //#endregion
    const validateData = () => {
        let status = true;
        let error = {};
        let ifcregex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
        let panregex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        let swiftregex = new RegExp(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/)
        let micr = new RegExp(/^[0-9]{1,9}$/)
        let vatregex = new RegExp(/[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$/)
        if (formValue?.Legal?.GSTNo?.trim()?.length === 0) {
            error["GSTNo"] = 'Inputs are mandatory.'
            status = false;
        }
        else if (formValue?.Legal?.GSTNo?.length > 0 && formValue?.Legal?.IsGST) {
            let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[0-9A-Z]{1}$/);
            if (regex.test(formValue?.Legal?.GSTNo) === false) {
                error["GSTNo"] = "Please provide correct input.";
                status = false;
            }
        }
        if (formValue?.Finance?.CompanyName?.trim()?.length === 0) {
            error["CompanyName"] = 'Inputs are mandatory.'
            status = false;
        }
        if (formValue?.Finance?.AccountEmail?.trim().length <= 0) {
            error['AccountEmail'] = 'Inputs are mandatory.';
            status = false;
        }
        else if (formValue?.Finance?.AccountEmail?.trim().length > 0 && !isValidEmail(formValue?.Finance?.AccountEmail)) {
            error['AccountEmail'] = 'Please provide correct input';
            status = false;
        }
        if (formValue?.Finance?.AccountNumber?.trim()?.length === 0) {
            error["AccountNumber"] = 'Inputs are mandatory.'
            status = false;
        }
        else if ((formValue?.Finance?.AccountNumber?.trim().length > 0 && formValue?.Finance?.AccountNumber?.trim()?.length < 9) || /[^A-Za-z0-9]/.test(formValue?.Finance?.AccountNumber)) {
            error['AccountNumber'] = 'Please provide correct input';
            status = false;
        }
        if (formValue?.Finance.AccountName?.trim()?.length === 0) {
            error["AccountName"] = 'Inputs are mandatory.'
            status = false;
        }
        if (formValue?.Finance.BankName?.trim()?.length === 0) {
            error["BankName"] = 'Inputs are mandatory.'
            status = false;
        }
        if (formValue?.Finance?.IFSCCode?.trim()?.length === 0) {
            error["IFSCCode"] = 'Inputs are mandatory.'
            status = false;
        }
        else if (formValue?.Finance?.IFSCCode?.trim()?.length > 0 && ifcregex.test(formValue?.Finance?.IFSCCode) === false) {
            error["IFSCCode"] = "Please provide correct input";
            status = false;
        }
        if (formValue?.Finance?.BranchName?.trim()?.length === 0) {
            error["BranchName"] = 'Inputs are mandatory.'
            status = false;
        }
        // if (formValue?.Finance?.SwiftCode?.trim()?.length > 0 && swiftregex.test(formValue?.Finance?.SwiftCode) === false) {
        //     error["SwiftCode"] = "Please provide correct input";
        //     status = false;
        // }
        if (formValue?.Finance?.PANNumber?.trim()?.length === 0) {
            error["PANNumber"] = 'Inputs are mandatory.'
            status = false;
        }
        else if (formValue?.Finance?.PANNumber?.trim()?.length > 0 && panregex.test(formValue?.Finance?.PANNumber) === false) {
            error["PANNumber"] = "Please provide correct input";
            status = false;
        }
        // if (formValue?.Finance?.MICRNumber?.trim()?.length > 0 && micr.test(formValue?.Finance?.MICRNumber) === false) {
        //     error["MICRNumber"] = "Please provide correct input";
        //     status = false;
        // }
        // if (formValue?.Finance?.VATNumber?.trim()?.length > 0 && vatregex.test(formValue?.Finance?.VATNumber) === false) {
        //     error["VATNumber"] = "Please provide correct input";
        //     status = false;
        // }
        if (formValue?.Finance?.MobileNumber?.trim()?.length > 0 && formValue?.Finance?.MobileNumber?.trim()?.length < 5) {
            error["MobileNumber"] = "Please provide correct input";
            status = false;
        }
        setFormError(error)
        return status;
    }

    //#region Save Button function (insert API)
    const handleSave = async (e) => {
        e.preventDefault()
        const isValid = validateData();
        if (isValid) {
            dispatch(loaderActions.start())
            const Legal = await postUsersData('/master/v1/insertorupdatehotellegal', formValue?.Legal)
            const Finance = await postUsersData('/master/v1/insertorupdatehotelfinance', formValue?.Finance)
            if (Legal?.status && Finance?.status) {
                setFormError({})
                setNext(false)
                setSave(true)
                deletemediabyhotelmediaid(deletefile)
                await Promise.all(
                    imageSection?.map(async (ele) => {
                        if (ele?.needToUpload) {
                            try {
                                await ImageUpload(ele?.Path, ele?.FileName, ele?.File);
                            } catch (error) {
                                console.error(`Error Upload Doc: ${error}`)
                                dispatch(loaderActions.end())
                            }
                        }
                        return ele;
                    })
                )
                dispatch({ type: 'ALERTING', message: Legal.message });
                setNext(false)
                setSave(true)
            }
            else if (!Legal?.status) {
                dispatch({ type: 'ALERTING', message: Legal.message });
            }
            else if (!Finance?.status) {
                dispatch({ type: 'ALERTING', message: Finance.message });
            }
        }
        dispatch(loaderActions.end())

    }
    //#endregion

    return (
        <Suspense fallback={<div> ...Loading </div>}>
            <OkModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                yesconfirm={() => handleBackBtnConfirmation(true)}
            />
            <div className="mt-2 heading_color input_mui">
                <div className='row my-3'>
                    <div className='col-6'>
                        <h3><i onClick={() => handleBack()} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Legal & Finance</h3>
                    </div>
                    <div className='col-6 text-end'>
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={(e) => handleSave(e)} disabled={save || location?.state?.disabled} >Save</button>
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={() => setModalShow(true)} disabled={next} >Finish</button>
                    </div>
                </div>
                <div>
                    <div className='w-100 '>
                        <Box sx={{ width: '100%', borderBottom: 2 }}>
                            <Tabs value={value}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                <Tab
                                    onClick={(e) => { handleChange(e, "Legal") }}
                                    label="Legal"
                                    value="Legal"
                                    sx={{ width: '100%', textAlign: 'center' }}
                                    className='room-tabs'
                                />

                                <Tab
                                    onClick={(e) => { handleChange(e, "Finance") }}
                                    value="Finance"
                                    label="Finance"
                                    sx={{ width: '100%', textAlign: 'center' }}
                                    className='room-tabs'
                                />
                            </Tabs>
                        </Box>
                        <div className=''>
                            {
                                value === 'Legal' ?
                                    (
                                        <Legal
                                            formError={formError}
                                            setFormError={setFormError}
                                            imageSection={imageSection}
                                            setImageSection={setImageSection}
                                            formValue={formValue?.Legal}
                                            setFormValue={setFormValue}
                                            handleDeleteFile={handleDeleteFile}
                                            hotelId={location?.state?.HotelId}
                                            disabled={location?.state?.disabled}
                                            setSave={setSave}
                                        />
                                    ) : (
                                        <Finance
                                            formError={formError}
                                            setFormError={setFormError}
                                            setFormValue={setFormValue}
                                            formValue={formValue?.Finance}
                                            hotelId={location?.state?.HotelId}
                                            disabled={location?.state?.disabled}
                                            setSave={setSave}
                                        />
                                    )
                            }
                        </div>
                    </div>

                </div>
            </div>
        </Suspense>
    )
}

export default LegalAndFinance
import React, { useEffect, useState } from 'react'
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { useDispatch } from 'react-redux';
import { getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { loaderActions } from '../../../../_actions/loader.actions';
import { isImagePngJpegJpg, onlyAlphabets, onlyNumeric } from '../../../commonClass/CommonValidation';
import { ImageDelete, ImageUpload } from '../../../../ImageUpload/ImageCommon';


function getDateCombination() {
    var today = new Date();
    let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
    let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
    return date + '_' + time;
}

function KeyAchievement({ useredit, useradd }) {
    const userId = GetLocalStorage();
    const dispatch = useDispatch();
    const [formvalue, setFormValue] = useState({
        UserId: userId?.userMasterID,
        KeyAchievementId: 1,

        HappyCustomer: 0,
        TitleOfSection: "",
        Tour: 0,
        Itinerary: 0,
        Hotel: 0,
        Adventure: 0,
        BackGroundImageName: "",
        BackGroundImagePath: "",
        BackGroundImageFile: ""
    });
    const [formError, setFormError] = useState({});
    const [deleteImages, setDeleteImages] = useState([])

    const getKeyAchievement = async () => {
        const keyAchievement = await getAllData(`/master/v1/getkeyachievement`);

        if (keyAchievement?.status) {
            const { KeyAchievementId, Tour, Hotel, Adventure, HappyCustomer, Itinerary, TitleOfSection } = keyAchievement?.data
            setFormValue({
                UserId: userId?.userMasterID,
                KeyAchievementId: KeyAchievementId,

                HappyCustomer: HappyCustomer ? HappyCustomer : '',
                TitleOfSection: TitleOfSection,
                Tour: Tour ? Tour : '',
                Itinerary: Itinerary ? Itinerary : '',
                Hotel: Hotel ? Hotel : '',
                Adventure: Adventure ? Adventure : '',
                BackGroundImageName: keyAchievement?.data?.BackGroundImageName,
                BackGroundImagePath: keyAchievement?.data?.BackGroundImagePath,
            })
        }
        dispatch(loaderActions.end());
    }

    //#region List data
    useEffect(() => {
        dispatch(loaderActions.start());
        const fetchData = async () => {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                dispatch(loaderActions.start());
                let token = GetLocalStorage();
                if (token && token.userName && token.token) {
                    getKeyAchievement()
                }
            } else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        fetchData();
    }, []);

    const storeDataInState = (name, value) => {
        setFormError({});
        setFormValue((prev) => ({ ...prev, [name]: value }))
    }

    const handleOnChange = (e) => {
        storeDataInState(e.target.name, onlyNumeric(e.target.value) === '' ? "" : Number(onlyNumeric(e.target.value)));
    }

    const handleUploadImageLoad = (e) => {
        setFormError({});
        if (isImagePngJpegJpg(e.target.files[0].name)) {
            const files = e.target.files[0];
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');
            setDeleteImages([...deleteImages, formvalue?.BackGroundImagePath])
            setFormValue({
                ...formvalue,
                BackGroundImageName: filename,
                BackGroundImagePath: 'CMS/KeyAchievement/' + filename,
                BackGroundImageFile: files,
            })
            e.target.value = null
        }
        else {
            setFormError({
                ...formError, ['BackGroundImagePath']: 'Please upload valid img file such as JPG, JPEG, & PNG.'
            });
        }
        e.target.value = null
    }

    const checkValidation = () => {
        let errorMessage = {}
        let isError = true


        if (formvalue?.BackGroundImageFile) {
            const files = formvalue?.BackGroundImageFile;
            let fileSize = files.size / 1024;
            fileSize = fileSize / 1024

            if (fileSize > 5) {
                errorMessage['BackGroundImagePath'] = 'Cannot upload file that exceeds 5 MB.'
                isError = false;
            }
        }
        setFormError(errorMessage);
        return isError;
    }

    const onSubmitKeyAchievement = async () => {
        const isValid = checkValidation();
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (isValid) {
                dispatch(loaderActions.start());
                const updatePitching = await postUsersData('/master/v1/insertorupdatekeyachievement', {
                    ...formvalue,
                    KeyAchievementId: formvalue?.KeyAchievementId ? formvalue?.KeyAchievementId : 0,
                    HappyCustomer: formvalue?.HappyCustomer ? formvalue?.HappyCustomer : 0,
                    Tour: formvalue.Tour ? formvalue.Tour : 0,
                    Itinerary: formvalue.Itinerary ? formvalue.Itinerary : 0,
                    Hotel: formvalue.Hotel ? formvalue.Hotel : 0,
                    Adventure: formvalue.Adventure ? formvalue.Adventure : 0,
                });

                if (updatePitching.status) {
                    formvalue?.BackGroundImageFile && await ImageUpload('CMS/KeyAchievement/', formvalue?.BackGroundImageName, formvalue?.BackGroundImageFile);
                    deleteImages.length > 0 && deleteImages.forEach(async (deletedImagePath) => await ImageDelete(deletedImagePath));
                    dispatch(loaderActions.end());
                    dispatch({ type: 'ALERTING', message: updatePitching.message })
                }
                else {
                    dispatch(loaderActions.end());
                    dispatch({ type: 'ALERTING', message: updatePitching.message })
                    return;
                }
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            dispatch(loaderActions.end());
        }
    }

    const clearData = () => {
        setDeleteImages([...deleteImages, formvalue?.BackGroundImagePath])
        setFormError({});
        setFormValue({
            ...formvalue,
            HappyCustomer: "",
            TitleOfSection: "",
            Tour: "",
            Itinerary: "",
            Hotel: "",
            Adventure: "",
            BackGroundImageName: "",
            BackGroundImagePath: "",
        })
    }

    return (
        <div className='p-4'>
            <div className="container-fluid heading_color mb-3">
                <h4>Key Achievement</h4>
            </div>
            <div className="container-fluid heading_color">
                <form action=" " >
                    <div className='row'>
                        <div className='row col-6 my-1'>
                            <div className="col-xl-3 col-12 mb-1">
                                <label className="col-form-label" htmlFor='title-of-section'>Title of the Section</label>
                            </div>
                            <div className="col-xl-9 mb-1 col-12">
                                <div class="input-group">
                                    <input
                                        type="text" id="title-of-section"
                                        class="form-control btn_add"
                                        placeholder="Title Of Section"
                                        maxLength={150}
                                        name='TitleOfSection'
                                        value={formvalue?.TitleOfSection || ''}
                                        onChange={(e) => storeDataInState(e.target.name, onlyAlphabets(e.target.value))}
                                    // disabled={homePageSection?.type === 'view' ? true : false}
                                    />
                                </div>
                                {formError.TitleOfSection && <div className='text-danger text-start'>{formError.TitleOfSection}</div>}
                            </div>
                        </div>
                        <div className='row col-6 my-1'>
                            <div className="col-xl-3 col-12 mb-1">
                                <label className="col-form-label" htmlFor='happy-customer'>Happy Customer</label>
                            </div>
                            <div className="col-xl-9 mb-1 col-12">
                                <div class="input-group">
                                    <input
                                        type="text" id="happy-customer"
                                        class="form-control btn_add"
                                        placeholder="Happy Customer"
                                        maxLength={6}
                                        name='HappyCustomer'
                                        value={formvalue.HappyCustomer || ''}
                                        onChange={(e) => handleOnChange(e)}
                                    // disabled={homePageSection?.type === 'view' ? true : false}
                                    />
                                </div>
                                {formError.HappyCustomer && <div className='text-danger text-start'>{formError.HappyCustomer}</div>}
                            </div>
                        </div>
                        <div className='row col-6 my-1'>
                            <div className="col-xl-3 col-12 mb-1">
                                <label className="col-form-label" htmlFor='tour'>Tour</label>
                            </div>
                            <div className="col-xl-9 mb-1 col-12">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        id="tour"
                                        class="form-control btn_add"
                                        placeholder="Tour"
                                        maxLength={6}
                                        name='Tour'
                                        value={formvalue.Tour || ''}
                                        onChange={(e) => handleOnChange(e)}
                                    // disabled={homePageSection?.type === 'view' ? true : false}
                                    />
                                </div>
                                {formError.Tour && <div className='text-danger text-start'>{formError.Tour}</div>}
                            </div>
                        </div>
                        <div className='row col-6 my-1'>
                            <div className="col-xl-3 col-12 mb-1">
                                <label className="col-form-label" htmlFor='Itinerary'>Itinerary</label>
                            </div>
                            <div className="col-xl-9 mb-1 col-12">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        id="Itinerary"
                                        class="form-control btn_add"
                                        placeholder="Itinerary"
                                        maxLength={6}
                                        name='Itinerary'
                                        value={formvalue.Itinerary || ''}
                                        onChange={(e) => handleOnChange(e)}
                                    // disabled={homePageSection?.type === 'view' ? true : false}
                                    />
                                </div>
                                {formError.Itinerary && <div className='text-danger text-start'>{formError.Itinerary}</div>}
                            </div>
                        </div>
                        <div className='row col-6 my-1'>
                            <div className="col-xl-3 col-12 mb-1">
                                <label className="col-form-label" htmlFor='Hotel'>Hotel</label>
                            </div>
                            <div className="col-xl-9 mb-1 col-12">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        id="Hotel"
                                        class="form-control btn_add"
                                        placeholder="Hotel"
                                        maxLength={6}
                                        name='Hotel'
                                        value={formvalue.Hotel || ''}
                                        onChange={(e) => handleOnChange(e)}
                                    // disabled={homePageSection?.type === 'view' ? true : false}
                                    />
                                </div>
                                {formError.Hotel && <div className='text-danger text-start'>{formError.Hotel}</div>}
                            </div>
                        </div>
                        <div className='row col-6 my-1'>
                            <div className="col-xl-3 col-12 mb-1">
                                <label className="col-form-label" htmlFor='Adventure'>Adventure</label>
                            </div>
                            <div className="col-xl-9 mb-1 col-12">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        id="Adventure"
                                        class="form-control btn_add"
                                        placeholder="Adventure"
                                        maxLength={6}
                                        name='Adventure'
                                        value={formvalue.Adventure || ''}
                                        onChange={(e) => handleOnChange(e)}
                                    // disabled={homePageSection?.type === 'view' ? true : false}
                                    />
                                </div>
                                {formError.Adventure && <div className='text-danger text-start'>{formError.Adventure}</div>}
                            </div>
                        </div>
                        <div className='row col-6 my-1'>
                            <div className="col-xl-3 col-12 mb-1">
                                <label className="col-form-label">Background image</label>
                            </div>
                            <div className="col-xl-9 mb-1 col-12">
                                <div class="input-group">
                                    <input
                                        type="file"
                                        name='BackGroundImagePath'
                                        id="BackGroundImagePath-id"
                                        className="form-control btn_add"
                                        accept=".png,.jpg,.jpeg"
                                        onChange={(e) => handleUploadImageLoad(e)}
                                    />
                                    <label className="input-group-text" htmlFor="BackGroundImagePath-id">Browse</label>
                                </div>
                                {formvalue.BackGroundImageName && (
                                    <div className='d-flex justify-content-center align-item-center w-100 '>
                                        {/* <span className='me-3'>X</span> */}
                                        <div className='text-start w-100 text-break'>{formvalue.BackGroundImageName}</div>
                                    </div>
                                )}
                                {formError.BackGroundImagePath && <div className='text-danger text-start'>{formError.BackGroundImagePath}</div>}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-5">
                        <button class="btn btn-primary mx-1" id="btnAdd" type='button'
                            onClick={() => onSubmitKeyAchievement()}
                            disabled={!useredit && !useradd}
                        >Add</button>
                        <button type="button" class="btn btn-primary mx-1" id="btnClear"
                            disabled={!useredit && !useradd}
                            onClick={() => clearData()}
                        >Clear</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default KeyAchievement
import React, { useMemo } from 'react';
import { useEffect, useState } from "react";
import "../../../../../../../assets/css/master.css";
import "../../../Hotelmaster.css"
import { useNavigate } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, Radio, Tab, Tabs } from '@mui/material';

const HouseAndRules = ({ houseRulesDetails, formValue, setFormValue, setSave, disabled, setFormError }) => {
    const navigate = useNavigate();
    const [value, setValue] = useState("1");
    const { HotelHouseRulesCollection: rulesValue } = formValue

    useEffect(() => {
        setValue(houseRulesDetails?.[0]?.categoryName)
    }, [houseRulesDetails]);

    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const selectedRules = useMemo(() => houseRulesDetails?.find((rules) => rules?.categoryName === value), [value]);

    const handeRadioOnChanges = (id, checked) => {
        const isCheck = rulesValue.some((rValue) => rValue?.HotelHouseRuleMasterId === id);

        if (isCheck) {
            setFormValue((pre) => ({
                ...pre, HotelHouseRulesCollection: pre.HotelHouseRulesCollection.map((opValue) => {
                    if (opValue?.HotelHouseRuleMasterId === id) {
                        opValue = { ...opValue, IsRuleSelected: checked }
                    }
                    return opValue;
                })
            }))
        } else {
            const obj = {
                HotelHouseRuleMasterId: id,
                IsRuleSelected: checked
            }
            setFormValue((pre) => ({ ...pre, HotelHouseRulesCollection: [...pre?.HotelHouseRulesCollection, obj] }))
        }
        setSave(false);
        setFormError({});
    }

    const handleCheckBoxOnChange = (ruleId, optionId, checked) => {
        let cloneRuleValue = [...rulesValue];

        if (checked) {
            cloneRuleValue = [...cloneRuleValue,
            {
                HotelHouseRuleMasterId: ruleId,
                IsRuleSelected: checked,
                HouseRuleOptionsId: optionId
            }
            ]
        } else {
            cloneRuleValue = cloneRuleValue.filter((opValue) => opValue.HouseRuleOptionsId !== optionId)
        }

        setFormValue((pre) => ({ ...pre, HotelHouseRulesCollection: cloneRuleValue }))
        setSave(false);
        setFormError({});
    }

    return (
        <div className="container-fluid mt-2 heading_color input_mui">
            <div className='row'>
                <div className='col-lg-2 col-3'>
                    <Box className='Hotel_listing'
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                    >
                        <Tabs
                            className='w-100'
                            orientation="vertical"
                            value={value}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            {houseRulesDetails.map((rules) => (
                                <Tab label={rules?.categoryName} value={rules.categoryName} onClick={() => setValue(rules.categoryName)} {...a11yProps(houseRulesDetails?.length - 1)} />
                            ))}

                        </Tabs>
                    </Box>
                </div>

                <div className='col-lg-10 col-9'>
                    <form action="" className='py-3 my-1'>
                        {selectedRules?.categoryName && <h5 className='fw-bold room_header'>{selectedRules?.categoryName}</h5>}

                        <div className=' mt-3 w-100'>
                            {selectedRules?.categoryHouseRules.length > 0 && selectedRules?.categoryHouseRules.map((rule) => (
                                <div className='row my-2 align-items-center' key={rule?.ruleName}>
                                    <div className={`col-lg-${rule?.options?.length > 0 ? 12 : 9} col-${rule?.options?.length > 0 ? 12 : 7} ${rule?.options?.length > 0 && 'mt-2'}`}>
                                        <h6 className=' fw-bold room_header mb-0'>{rule?.ruleName}</h6>
                                    </div>
                                    {rule?.options?.length <= 0 ? (
                                        <div className='col-lg-3 col-5'>
                                            <FormControlLabel className=''
                                                control={
                                                    <Radio
                                                        name='ExtraMealProvided'
                                                        onChange={(e) => handeRadioOnChanges(rule?.houseRulesMasterId, true)}
                                                        checked={rulesValue.find((rValue) => rValue?.HotelHouseRuleMasterId === rule?.houseRulesMasterId)?.IsRuleSelected === true}
                                                        value="true"
                                                        id={`HotelHouseRuleMasterId${rule?.houseRulesMasterId}+yes`}
                                                        inputProps={{ 'aria-label': 'true' }}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label="Yes"
                                            />
                                            <FormControlLabel className=''
                                                control={
                                                    <Radio
                                                        id={`HotelHouseRuleMasterId${rule?.houseRulesMasterId}+no`}
                                                        name='ExtraMealProvided'
                                                        checked={rulesValue.find((rValue) => rValue?.HotelHouseRuleMasterId === rule?.houseRulesMasterId)?.IsRuleSelected === false}
                                                        onChange={(e) => handeRadioOnChanges(rule?.houseRulesMasterId, false)}
                                                        value="false"
                                                        inputProps={{ 'aria-label': 'false' }}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label="No"
                                            />
                                        </div>
                                    ) :
                                        rule?.options?.map((op) => (
                                            <div className='col-xl-3 col-lg-4 col-md-6 col-6' key={op.optionName}>
                                                <FormControlLabel className='mx-0 w-100'
                                                    control={
                                                        <Checkbox
                                                            checked={rulesValue.some((rValue) => rValue?.HotelHouseRuleMasterId === rule?.houseRulesMasterId && rValue.HouseRuleOptionsId === op?.optionId)}
                                                            onChange={(e) => handleCheckBoxOnChange(rule?.houseRulesMasterId, op?.optionId, e.target.checked)}
                                                            name={op.optionName}
                                                            disabled={disabled}
                                                        />
                                                    }
                                                    label={op.optionName}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            ))}
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}
export default HouseAndRules
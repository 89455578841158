import React, { Fragment, useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import UploadButton from './UploadButton';
import Item from 'antd/es/list/Item';
import { Grid } from '@mui/material';

function HotelAndRoomVideos(props) {

    const { videoSection,
        setVideoSection,
        roomlist,
        mediaType,
        handleDeleteFile,
        hotelId,
        disabled,
        setSave } = props;

    const [expanded, setExpanded] = React.useState([]);

    const [onPhotoHover, setOnPhotoHover] = useState(0);

    const handleChange = (event, isExpanded, id) => {
        if (isExpanded) {
            setExpanded([...expanded, id]);
        } else {
            setExpanded((pre) => (pre.filter((Aid) => Aid !== id)));
        }
    };

    return (
        <>
            {roomlist?.length > 0
                && roomlist?.map((list, index) => (
                    <div key={list?.HotelRoomId + list?.RoomTypeName}>
                        <Accordion
                            key={list?.HotelRoomId + list?.RoomTypeName + index}
                            className='image_video_list'
                            expanded={expanded?.includes(list?.HotelRoomId) ? true : false}
                            onChange={(e, isExpanded) => handleChange(e, isExpanded, list?.HotelRoomId)}
                        >

                            <AccordionSummary
                                className='images-accordian'
                                expandIcon={expanded.includes(list?.HotelRoomId) ? (
                                    <i className="fa-solid fa-minus" style={{ color: "#B22500" }}></i>
                                ) : (
                                    <i className="fa-solid fa-plus" style={{ color: "#B22500" }}></i>
                                )}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                expanded={true}
                            >
                                <Typography >{list?.RoomTypeName}</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography>
                                    <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <Item >
                                                <div className='h-241px'>
                                                    <UploadButton
                                                        hotelRoomId={list?.HotelRoomId}
                                                        mediaType={mediaType}
                                                        formValue={videoSection}
                                                        setFormValue={setVideoSection}
                                                        hotelId={hotelId}
                                                        disabled={disabled}
                                                        setSave={setSave}
                                                    />
                                                </div>
                                            </Item>
                                        </Grid>
                                        {
                                            videoSection?.map((vid, index) => {
                                                return (
                                                    <Fragment key={vid?.HotelRoomId + vid?.FileName + index}>
                                                        {(() => {
                                                            if (vid?.HotelRoomId === list?.HotelRoomId) {
                                                                return (
                                                                    <Grid key={vid?.HotelRoomId + vid?.FileName} item xs={12} sm={6} md={4} lg={3}
                                                                        onMouseEnter={() => setOnPhotoHover(index + 1)}
                                                                        onMouseLeave={() => setOnPhotoHover(0)}
                                                                    >
                                                                        <Item>
                                                                            <div className='h-241px photo-images-card position-relative '>
                                                                                <video
                                                                                    controls
                                                                                    className='rounded multipleUploader object-fit-fill'
                                                                                    src={
                                                                                        vid?.needToUpload ?
                                                                                            URL.createObjectURL(vid?.File) :
                                                                                            `https://static-ethics.sgp1.digitaloceanspaces.com/etm/${vid?.FilePath}`
                                                                                    }
                                                                                    alt={vid?.FileName}
                                                                                    height={'241'}
                                                                                    width={'100%'}
                                                                                    aspect-ratio={3 / 2}
                                                                                />
                                                                                {
                                                                                    !disabled && onPhotoHover === index + 1 && (
                                                                                        <div className="image_video_hover position-absolute rounded py-3 text-center align-content-center">
                                                                                            <i className="fa-solid fa-trash-can" role='button' onClick={() => handleDeleteFile(index, vid?.HotelMediaId, mediaType)}></i>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </Item>
                                                                    </Grid>
                                                                )
                                                            }
                                                        })()}

                                                    </Fragment>
                                                )
                                            })
                                        }


                                    </Grid>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div >
                ))
            }

        </>
    )
}

export default HotelAndRoomVideos
import React, { useEffect, useState } from 'react'
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { loaderActions } from '../../../../_actions/loader.actions';
import { ImageDelete, ImageUpload } from '../../../../ImageUpload/ImageCommon';
import { deleteUserData, getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { isImagePngJpegJpg } from '../../../commonClass/CommonValidation';

function getDateCombination() {
  var today = new Date();
  let date =
    today?.getFullYear()?.toString() +
    (today?.getMonth() + 1)?.toString() +
    today?.getDate()?.toString();
  let time =
    today?.getHours()?.toString() +
    (today?.getMinutes() + 1)?.toString() +
    today?.getSeconds()?.toString();
  return date + "_" + time;
}

function Banners({ travelCategoryId, useredit, useradd }) {

  const dispatch = useDispatch();
  const maxFileSize = 25;
  const folderName = travelCategoryId === 1 ? 'Flights' : 'Holidays';
  const subFolderName = 'Banners';
  const [imageError, setImageError] = useState('');
  const [userId, setUserId] = useState(0);
  const [imageList, setImageList] = useState([]);
  const [deletImageList, setDeleteImageList] = useState([]);

  //#region Call GET API Function
  const gethomepagebannerbytravelcategoryid = async () => {
    const homepagebannerbytravelcategoryid = await getAllData(`/master/v1/gethomepagebannerbytravelcategoryid?travelCatergoryId=${travelCategoryId}`)
    if (homepagebannerbytravelcategoryid?.status) {
      setImageList(homepagebannerbytravelcategoryid?.data);
      setDeleteImageList(homepagebannerbytravelcategoryid?.data);
    }
  }
  //#endregion

  //#region Check Token and Call GET API Function in useEffect with travelCategoryId
  useEffect(() => {

    async function fetchData() {
      let statusoftoken = await verifytoken();
      if (statusoftoken) {
        const user = GetLocalStorage();
        setUserId(user?.userMasterID);
        gethomepagebannerbytravelcategoryid();
      }
      else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
        dispatch(loaderActions.end());
      }
    }

    if (travelCategoryId) {
      fetchData();
    }
  }, [travelCategoryId])
  //#endregion

  //#region check valid image formate and image size
  const filterValidImage = (newFiles, type) => {
    if (type === "filetype") {
      return Object.entries(newFiles)?.map((obj) => obj.pop(0))?.filter((file) => !isImagePngJpegJpg(file?.name));
    }
    else if (type === "filesize") {
      return Object.entries(newFiles)?.map((obj) => obj.pop(0))?.filter((file) => ((file?.size / 1024) / 1024) > maxFileSize);
    }
  };
  //#endregion

  //#region Image Upload Function
  const handleUploadDoc = (e) => {
    let newFileType = filterValidImage(e?.target?.files, 'filetype')
    let newFileSize = filterValidImage(e?.target?.files, 'filesize')
    if (newFileType?.length > 0) {
      setImageError('Please upload valid image such as PNG, JPEG, & JPG only.');
    }
    else if (newFileSize?.length > 0) {
      setImageError('Cannot upload file that exceeds 25 MB.');
    }
    else if (e?.target?.files?.length > 0) {
      setImageError('');
      const tempImageList = Object.entries(e?.target?.files)?.map((obj) => obj.pop(0))?.reduce((acc, file, index) => {
        const filecom = getDateCombination();
        let filename = filecom + "_1_" + file?.name
        acc.push({
          HomePageBannerId: 0,
          ImageName: filename,
          ImagePath: `CMS/Merchandising/${folderName}/${subFolderName}/${filename}`,
          Path: `CMS/Merchandising/${folderName}/${subFolderName}/`,
          File: file,
          needToUpload: true,
          uploaded: false,
        });

        return acc;
      }, []);
      setImageList(tempImageList)
    }
  }
  //#endregion

  //#region Delete Image 
  const handleDeleteFile = (mediaId, indexId) => {
    setImageError('');
    setImageList((prev) => prev.filter((_item, index) => index !== indexId));
    document.getElementById('imageUpload').value = '';
    if (mediaId !== 0) {
      deletehomepagebannerbyid(mediaId, 'oldImage')
    }
  }

  const deletehomepagebannerbyid = async (mediaid, type) => {
    dispatch(loaderActions.start())
    let statusoftoken = await verifytoken();
    if (statusoftoken) {
      const deleteData = await deleteUserData(`/master/v1/deletehomepagebannerbyid?homePageBannerId=${mediaid}`)
      if (deleteData?.status) {
        let delColl = type === 'newImage' ? deletImageList : imageList

        let deleteImage = delColl?.find((obj) => obj?.HomePageBannerId === mediaid)
        try {
          const deleted = await ImageDelete(deleteImage?.ImagePath);
          if (deleted) {
            dispatch(loaderActions.end())
            dispatch({ type: 'ALERTING', message: deleteData?.message })
          }
        } catch (error) {
          console.error(`Error Delete Image: ${error}`)
        }
      }
      else {
        dispatch(loaderActions.end())
      }
    }
    else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      dispatch(loaderActions.end());
    }
  }
  //#endregion

  //#region Add Button Function
  const AddBanner = async () => {
    if (imageList?.length <= 0) {
      setImageError('Cannot leave blank, input is mandatory.');
    }
    else {
      setImageError('');
      dispatch(loaderActions.start());
      let statusoftoken = await verifytoken();
      if (statusoftoken) {
        let newImageList = imageList?.filter((obj) => obj?.needToUpload)
        const insertupdatehomepagebanner = await postUsersData('/master/v1/insertupdatehomepagebanner',
          {
            TravelCategoryId: travelCategoryId,
            ImageList: newImageList,
            UserId: userId
          }
        );

        if (insertupdatehomepagebanner.status) {
          await Promise.all(
            newImageList?.map(async (ele) => {
              if (ele?.needToUpload) {
                try {
                  let uploaded = await ImageUpload(ele?.Path, ele?.ImageName, ele?.File);
                  if (deletImageList?.length > 0) {
                    await deletehomepagebannerbyid(deletImageList[0]?.HomePageBannerId, 'newImage');
                  }
                  ele.uploaded = uploaded
                } catch (error) {
                  console.error(`Error Upload Image: ${error}`)
                  dispatch(loaderActions.end())
                }
              }
              return ele;
            })
          )
          gethomepagebannerbytravelcategoryid();
          dispatch({ type: 'ALERTING', message: insertupdatehomepagebanner.message });
          dispatch(loaderActions.end());
        }
        else {
          dispatch({ type: 'ALERTING', message: insertupdatehomepagebanner.message })
          dispatch(loaderActions.end());
          return;
        }
      } else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        dispatch(loaderActions.end());
      }
    }
  }
  //#endregion

  //#region Clear Button Function
  const clearData = () => {
    document.getElementById('imageUpload').value = '';
    setImageError('');
    setImageList([]);
  }
  //#endregion

  return (
    <div className='p-4'>
      <div className="container-fluid heading_color mb-3">
        <h4>Banners</h4>
      </div>
      <div className="container-fluid heading_color">

        <form action=" " >
          <div class="row mb-3">

            <div className="col-6">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                  <label htmlFor="imageUpload" className="col-form-label required">Header Banner</label>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                  <div className="input-group">
                    <input type="file"
                      className="form-control btn_add"
                      id="imageUpload"
                      name='imageUpload'
                      accept='.jpg,.jpeg,.png'
                      disabled={!useredit && !useradd}
                      onChange={(e) => handleUploadDoc(e)}
                    />
                    <label className="input-group-text" htmlFor="imageUpload">Browse</label>
                  </div>
                  {imageError && <p className="text-start text-danger mb-0"> {imageError} </p>}
                </div>
              </div>
            </div>
            <div className="col-6"></div>

            {
              imageList?.map((img, index) => {
                return (
                  <div className='mt-2 text-center' key={index}>
                    <img
                      src={
                        img?.needToUpload ?
                          URL.createObjectURL(img?.File) :
                          `https://static-ethics.sgp1.digitaloceanspaces.com/etm/${img?.ImagePath}`
                      }
                      key={index}
                      style={{ width: '50%', height: '100%', objectFit: 'fill' }}
                      alt={img?.ImageName}
                      loading='lazy'
                    />
                    <button className='closebtnimg'
                      onClick={(e) => handleDeleteFile(img?.HomePageBannerId, index)}
                      type="button"
                      data-close style={{ objectposition: "right top", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                      disabled={!useredit && !useradd}
                    >&times;</button>
                  </div>
                )
              })
            }

          </div>

          <div class="d-flex justify-content-center pt-5">
            <button class="btn btn-primary mx-1" id="btnAdd" type='button'
              onClick={() => AddBanner()}
              disabled={(!useredit && !useradd) || imageList?.[0]?.HomePageBannerId !== 0}
            >Add</button>
            <button type="button" class="btn btn-primary mx-1" id="btnClear"
              disabled={!useredit && !useradd}
              onClick={() => clearData()}>Clear</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Banners
import React, { memo } from 'react'
import { FormControl, Radio, TextField, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';
import CountComponent from '../../../../../../../customControl/CountComponent';
import "react-datepicker/dist/react-datepicker.css";
import RoomDefaultRates from './RoomDefaultRates';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { alphaNumericspace, onlyNumeric } from '../../../../../../commonClass/CommonValidation';
import dayjs from 'dayjs';

const RoomBasicInfo = ({ formValue, setFormValue, getData, formError, disabled, setFormError }) => {
  const handleChange = (name, value) => {
    setFormError({})
    setFormValue((presValue) => ({ ...presValue, [name]: value }));

    if (name === 'ExtraBeddingAllowed' && value === false) {
      setFormValue((prevs) => ({
        ...prevs,
        HotelRoomRatesCollection: prevs.HotelRoomRatesCollection.map((roomMeal) => {
          roomMeal = {
            ...roomMeal,
            ExtraChildWithBed: null,
            ExtraAdult: null
          }
          return roomMeal
        })
      }))
    } else if (name === 'ExtraMealProvided' && value === false) {
      setFormValue((prevs) => ({ ...prevs, ExtraMealRates: null }))
    }
  };


  const handleCheckBoxChange = (name, check, Id) => {
    if (check) {
      setFormValue({ ...formValue, [name]: Id === 1 ? [{ RoomViewId: Id }] : [...formValue?.[name], { RoomViewId: Id }] })
    } else {
      setFormValue({ ...formValue, [name]: formValue?.[name].filter((view) => view.RoomViewId !== Id) })
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return date ? [year, month, day].join('-') + 'T00:00:00' : null;
  }

  return (
    <div className="container-fluid">
      <form action="" className='py-3 my-1'>
        <div className='row mt-3'>
          <div className='col-lg-4 col-md-6 col-12 mb-1'>
            <FormControl className='w-100' variant="filled">
              <TextField id='RoomDisplayNameid' label='Room Display Name'
                variant="filled"
                className={`w-100`}
                type="text"
                name='RoomDisplayName'
                inputProps={{ maxLength: 50 }}
                value={formValue?.RoomDisplayName}
                onChange={(e) => handleChange(e.target.name, alphaNumericspace(e.target.value))}
                disabled={disabled}
              />
            </FormControl>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mb-1'>
            <FormControl className='w-100' variant="filled">
              <Autocomplete
                disabled={disabled}
                id='RoomTypeId'
                options={getData?.roomTypeMaster?.filter((op) => op?.RoomTypeId !== formValue?.RoomTypeId)}
                value={getData?.roomTypeMaster?.find((ch) => ch?.RoomTypeId === formValue?.RoomTypeId) || null}
                name='RoomTypeId'
                getOptionLabel={(option) => option?.RoomTypeName}
                onChange={(e, newValue) => handleChange('RoomTypeId', newValue?.RoomTypeId ? newValue?.RoomTypeId : null)}
                renderInput={(params) => (
                  <TextField {...params} label='Room Type' variant="filled"
                    className={`w-100  ${formValue?.RoomTypeId <= 0 ? 'red_border' : ''}`}
                    helperText={formError?.RoomTypeId}
                    error={formError?.RoomTypeId ? true : false}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mb-1'>
            <FormControl className='w-100' variant="filled">
              <Autocomplete
                disabled={disabled}
                id='BedTypeId'
                options={getData?.bedTypeMaster?.filter((op) => op?.BedTypeId !== formValue?.BedTypeId)}
                value={getData?.bedTypeMaster?.find((ch) => ch?.BedTypeId === formValue?.BedTypeId) || null}
                name='BedTypeId'
                getOptionLabel={(option) => option?.BedTypeName}
                onChange={(e, newValue) => handleChange('BedTypeId', newValue?.BedTypeId ? newValue?.BedTypeId : null)}
                renderInput={(params) => (
                  <TextField {...params} label='Bed Type' variant="filled"
                    className={`w-100  ${formValue?.BedTypeId <= 0 ? 'red_border' : ''}`}
                    helperText={formError?.BedTypeId}
                    error={formError?.BedTypeId ? true : false}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className='col-lg-12 col-md-12 col-12 mb-2 mt-2 '>
            <TextField
              label='Room Description'
              inputProps={{ maxLength: 500 }}
              rows={4}  // Number of visible rows
              value={formValue?.RoomDescription}
              name='RoomDescription'
              onChange={(e) => handleChange('RoomDescription', alphaNumericspace(e.target.value))}
              variant="filled"
              fullWidth
              disabled={disabled}
              multiline
            />
          </div>
          <div className='row mt-3'>
            <div className='col-xl-4 col-lg-6 col-md-6 col-12 mb-1'>
              <h5 className='mt-3'>Total Room Count</h5>
              <h6 className='text-muted'>Quantity of room in this category</h6>
            </div>
            <div className='col-xl-2 col-lg-4 col-md-6 col-12 mb-1'>
              <FormControl className='w-100' variant="filled">
                <TextField id='RoomCount' label='Total Room Count' variant="filled"
                  className={`w-100  ${formValue?.RoomCount <= 0 ? 'red_border' : ''}`}
                  type="text"
                  name='RoomCount'
                  inputProps={{ maxLength: 4 }}
                  value={formValue?.RoomCount}
                  onChange={(e) => handleChange(e.target.name, onlyNumeric(e.target.value) === '' ? "" : Number(onlyNumeric(e.target.value)))}
                  error={formError?.RoomCount ? true : false}
                  helperText={formError?.RoomCount}
                  disabled={disabled}
                />
              </FormControl>
            </div>
          </div>

          <div className='row mt-2'>
            <div className='col-xl-4 col-lg-6 col-md-6 col-12 mb-1'>
              <h5 className='mt-3'>ETM Room Count</h5>
              <h6 className='text-muted'>ETM Quantity of room in this category</h6>
            </div>
            <div className='col-xl-2 col-lg-4 col-md-6 col-12 mb-1'>
              <FormControl className='w-100' variant="filled">
                <TextField id='ETMAllocation' label='ETM Room Count' variant="filled"
                  className={`w-100  ${formValue?.ETMAllocation <= 0 ? 'red_border' : ''}`}
                  type="text"
                  name='ETMAllocation'
                  inputProps={{ maxLength: 4 }}
                  value={formValue?.ETMAllocation}
                  onChange={(e) => handleChange(e.target.name, onlyNumeric(e.target.value) === '' ? "" : Number(onlyNumeric(e.target.value)))}
                  error={formError?.ETMAllocation ? true : false}
                  helperText={formError?.ETMAllocation}
                  disabled={disabled}
                />
              </FormControl>
            </div>
          </div>

          <div className='row mt-2'>
            <div className='col-lg-12 col-md-12 col-12 mb-1'>
              <h5 className='mt-3'>Room Occupancy</h5>
            </div>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-12 align-content-center'>
                <h6 className='text-muted mb-0'>Adult - Age - 12+ Years</h6>
              </div>
              <div className='col-sm-4 col-4'>
                <CountComponent
                  count={formValue?.AdultCount}
                  getCount={(count) => { handleChange('AdultCount', count) }}
                  id={1}
                  option={{ MinValue: 1, MaxValue: 10, Id: 'AdultCount', defaultValue: 1 }}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-4 col-md-6 col-12 align-content-center'>
                <h6 className='text-muted mb-0'>Child - Age Below 12 years</h6>
              </div>
              <div className='col-sm-4 col-4'>
                <CountComponent
                  count={formValue?.ChildCount}
                  getCount={(count) => { handleChange('ChildCount', count) }}
                  id={2}
                  disabled={disabled}
                  option={{ MinValue: 0, MaxValue: 3, Id: 'ChildCount', defaultValue: 0 }}
                />
              </div>
            </div>
          </div>

          <div className='row mt-3' >
            <div className='col-lg-4 col-md-6 col-sm-6 col-12 align-content-center'>
              <h6 className='fw-bold room_header'>Extra Bedding</h6>
            </div>
            <div className='col-md-6 col-sm-4 col-12 d-flex'>
              <FormControlLabel className=''
                control={
                  <Radio
                    checked={formValue?.ExtraBeddingAllowed === true}
                    name='ExtraBeddingAllowed'
                    onChange={(e) => handleChange(e.target.name, true)}
                    value="true"
                    id='Allowedid'
                    inputProps={{ 'aria-label': 'true' }}
                    disabled={disabled}
                  />
                }
                label="Allowed"
              />
              <FormControlLabel className=''
                control={
                  <Radio
                    checked={formValue?.ExtraBeddingAllowed === false}
                    onChange={(e) => {
                      handleChange(e.target.name, false)
                    }}
                    value="false"
                    id='NotAllowedid'
                    name='ExtraBeddingAllowed'
                    inputProps={{ 'aria-label': 'false' }}
                    disabled={disabled}
                  />
                }
                label="Not Allowed"
              />
            </div>
          </div>

          {/* //default room rate */}
          <RoomDefaultRates
            HotelRoomRatesCollection={formValue?.HotelRoomRatesCollection}
            setFormValue={setFormValue}
            isExtraBeddingAllowed={formValue?.ExtraBeddingAllowed}
            disabled={disabled}
            formError={formError}
            setFormError={setFormError}
          />

          <div className='row mt-3' >
            <div className='col-lg-4 col-md-6 col-sm-6 col-12 align-content-center'>
              <h6 className='fw-bold room_header'>Extra Meal is Provided</h6>
            </div>
            <div className='col-md-6 col-sm-4 col-12 d-flex'>
              <FormControlLabel className=''
                control={
                  <Radio
                    checked={formValue?.ExtraMealProvided === true}
                    name='ExtraMealProvided'
                    onChange={(e) => handleChange(e.target.name, true)}
                    value="true"
                    id='Allowedid+ExtraMealProvided'
                    inputProps={{ 'aria-label': 'true' }}
                    disabled={disabled}
                  />
                }
                label="Yes"
              />
              <FormControlLabel className=''
                control={
                  <Radio
                    id='NotAllowedid+ExtraMealProvided'
                    name='ExtraMealProvided'
                    checked={formValue?.ExtraMealProvided === false}
                    onChange={(e) => {
                      handleChange(e.target.name, false)
                    }}
                    value="false"
                    inputProps={{ 'aria-label': 'false' }}
                    disabled={disabled}
                  />
                }
                label="No"
              />
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-lg-4 col-md-6 col-12 mb-1'>
              <FormControl className='w-100' variant="filled">
                <TextField id='ExtraMealRateid' label='Extra Meal Rate'
                  variant="filled"
                  className={`w-100 ${formValue?.ExtraMealRates <= 0 && formValue?.ExtraMealProvided ? 'red_border' : ''}`}
                  type="text"
                  name='ExtraMealRates'
                  inputProps={{ maxLength: 5 }}
                  value={formValue?.ExtraMealRates || ''}
                  onChange={(e) => handleChange(e.target.name, onlyNumeric(e.target.value) === '' ? "" : Number(onlyNumeric(e.target.value)))}
                  error={formError?.ExtraMealRates ? true : false}
                  helperText={formError?.ExtraMealRates}
                  disabled={!formValue?.ExtraMealProvided || disabled}
                />
              </FormControl>
            </div>
          </div>
          <div className='row mt-3' >
            <div className='col-lg-4 col-md-6 col-sm-6 col-12 align-content-center'>
              <h6 className='fw-bold room_header'>Smoking</h6>
            </div>
            <div className='col-md-6 col-sm-4 col-12 d-flex'>
              <FormControlLabel className=''
                control={
                  <Radio
                    checked={formValue?.SmokingAllowed === true}
                    name='SmokingAllowed'
                    onChange={(e) => handleChange(e.target.name, true)}
                    value="true"
                    id='Allowedid+SmokingAllowed'
                    inputProps={{ 'aria-label': 'true' }}
                    disabled={disabled}
                  />
                }
                label="Allowed"
              />

              <FormControlLabel className=''
                control={
                  <Radio
                    name='SmokingAllowed'
                    id='NotAllowedid+SmokingAllowed'
                    checked={formValue?.SmokingAllowed === false}
                    onChange={(e) => {
                      handleChange(e.target.name, false)
                    }}
                    value="false"
                    inputProps={{ 'aria-label': 'false' }}
                    disabled={disabled}
                  />
                }
                label="Not Allowed"
              />
            </div>
          </div>
          <div className='row mt-3' >
            <div className='col-lg-4 col-md-6 col-sm-6 col-12 align-content-center'>
              <h6 className='fw-bold room_header'>Visitor Entry</h6>
            </div>
            <div className='col-md-6 col-sm-4 col-12 d-flex'>
              <FormControlLabel className=''
                control={
                  <Radio
                    id='Allowedid+VisitorEntry'
                    name='VisitorEntry'
                    checked={formValue?.VisitorEntry === true}
                    onChange={(e) => handleChange(e.target.name, true)}
                    value="true"
                    inputProps={{ 'aria-label': 'true' }}
                    disabled={disabled}
                  />
                }
                label="Allowed"
              />
              <FormControlLabel className=''
                control={
                  <Radio
                    id='NotAllowedid+VisitorEntry'
                    name='VisitorEntry'
                    checked={formValue?.VisitorEntry === false}
                    onChange={(e) => {
                      handleChange(e.target.name, false)
                    }}
                    value="false"
                    inputProps={{ 'aria-label': 'false' }}
                    disabled={disabled}
                  />
                }
                label="Not Allowed"
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12 align-content-center'>
              <h6 className='fw-bold room_header'>Room Availability Dates</h6>
            </div>
            <div className='col-lg-8 col-md-6 col-12 mb-1'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  className={`w-100 range ${!formValue?.RoomAvailabilityFromDate || !formValue?.RoomAvailabilityToDate ? 'red_border' : ''}`}
                  format='DD/MM/YYYY'
                  value={[
                    formValue.RoomAvailabilityFromDate ? dayjs(formValue.RoomAvailabilityFromDate) : null,
                    formValue.RoomAvailabilityToDate ? dayjs(formValue.RoomAvailabilityToDate) : null,
                  ]}
                  onChange={(newDate) => {
                    handleChange('RoomAvailabilityFromDate', formatDate(newDate[0]));
                    handleChange('RoomAvailabilityToDate', formatDate(newDate[1]));
                  }}
                  error={!formError?.RoomAvailabilityFromDate || formValue?.RoomAvailabilityToDate ? true : false}
                  helperText={formError?.RoomAvailabilityFromDate}
                  slotProps={{
                    textField: {
                      variant: 'filled',
                      endAdornment: <button type="button" className="border-0 bg-transparent btn pt-0">
                        <img className="pt-0 mt-0" alt="" src={require('../../../../../../../assets/img/calendar_month.png')} />
                      </button>
                    },
                  }}
                  disabled={disabled}
                />
                {formError?.RoomAvailabilityFromDate && (<p className='pb-0 text-danger mb-0'>{formError?.RoomAvailabilityFromDate}</p>)}
              </LocalizationProvider>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12 align-content-center'>
              <h6 className='fw-bold room_header'>Restriction Days</h6>
            </div>
            <div className='col-lg-4 col-md-6 col-12 mb-1'>
              <FormControl className='w-100' variant="filled">
                <TextField id='ExtraMealRateid' label='Min Days'
                  variant="filled"
                  className={`w-100`}
                  type='text'
                  name='RestrictionMinDays'
                  inputProps={{ maxLength: 2, min: 20, max: 30 }}
                  value={formValue?.RestrictionMinDays || ''}
                  onChange={(e) => {
                    let newValue = onlyNumeric(e.target.value) === '' ? null : Number(onlyNumeric(e.target.value));

                    if (newValue === null || newValue <= 30 && newValue >= 1) {
                      handleChange(e.target.name, newValue);
                    }

                  }}
                  error={formError?.RestrictionMinDays ? true : false}
                  helperText={formError?.RestrictionMinDays}
                  disabled={disabled}
                />
              </FormControl>
            </div>
            <div className='col-lg-4 col-md-6 col-12 mb-1'>
              <FormControl className='w-100' variant="filled">
                <TextField id='ExtraMealRateid' label='Max Days'
                  variant="filled"
                  className={`w-100`}
                  type="text"
                  name='RestrictionMaxDays'
                  inputProps={{ maxLength: 2, MinValue: 1, MaxValue: 30 }}
                  value={formValue?.RestrictionMaxDays || ''}
                  onChange={(e) => {
                    let newValue = onlyNumeric(e.target.value) === '' ? null : Number(onlyNumeric(e.target.value));

                    if (newValue === null || newValue <= 30 && newValue >= 1) {
                      handleChange(e.target.name, newValue);
                    }

                  }}
                  error={formError?.RestrictionMaxDays ? true : false}
                  helperText={formError?.RestrictionMaxDays}
                  disabled={disabled}
                />
              </FormControl>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12 align-content-center'>
              <h6 className='fw-bold room_header'>Room View</h6>
            </div>
            {formError?.RoomViewsCollection && (<p className='pb-0 text-danger mb-0 text-center'>{formError?.RoomViewsCollection}</p>)}
            <div className='col-lg-12 col-md-12 col-12 mb-1'>
              <div className='row'>
                {getData?.roomViewsMaster?.map((view) => (
                  <div className='col-4 '>
                    <FormControlLabel className='mx-0'
                      control={
                        <Checkbox
                          id='view.RoomViewName'
                          checked={formValue?.RoomViewsCollection?.some((option) => option.RoomViewId === view?.RoomViewID)}
                          onChange={(e) => { handleCheckBoxChange('RoomViewsCollection', e.target.checked, view?.RoomViewID) }}
                          name={view?.RoomViewName}
                          disabled={formValue?.RoomViewsCollection?.some((option) => option.RoomViewId === 1) && view?.RoomViewID !== 1 ? true : false || disabled}
                        />
                      }
                      label={view.RoomViewName}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form >
    </div>
  )
}

export default memo(RoomBasicInfo)
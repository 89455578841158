import React, { useState } from 'react'
import Select from 'react-select';
import { onlyAlphabets } from '../../../commonClass/CommonValidation';
import Switch from 'react-switch';
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import CustomTable from '../../../../customControl/CustomTable';
import { getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../_actions/loader.actions';
import { ClearLocalStorage } from '../../../commonClass/Session';

const FooterPart2 = (props) => {
    const { footerTitleList, filterItems2, footerPart2, setFooterPart2, UserId, userRights } = props;

    const dispatch = useDispatch();
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [editdisabled, setEditDisabled] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(false);

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('4');
    const [serchdisable, setSerachDisable] = useState(false);
    const [clrshow, setsclshow] = useState(false);

    const [formValue, setFormValue] = useState(
        {
            TitleDetailsId: 0,
            TitleId: 0,
            DisplayName: "",
            Link: "",
            OpenInNewTab: false,
            IsActive: false,
            UserId: UserId,
        }
    )
    const [formError, setFormError] = useState([]);

    //#region Footer Part 2 List API calling function when any record add or update
    const getfooterpart2list = async () => {
        const footerpart2list = await getAllData('/master/v1/getfooterpart2list')
        if (footerpart2list?.status) {
            setFooterPart2(footerpart2list?.data);
        }
    }
    //#endregion

    //#region Title On Change function
    const handleSelect = (e) => {
        setFormError([]);
        setEditDisabled(true);
        setFormValue((form) => ({ ...form, TitleId: e?.value }))
    }
    //#endregion

    //#region input box On Change function
    const handleOnChange = (name, value) => {
        setFormError([]);
        setEditDisabled(true);
        setFormValue((form) => ({ ...form, [name]: value }))
    }
    //#endregion

    //#region Active toggle button on Change function
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            setFormError([])
            setEditDisabled(true);
            setFormValue((form) => ({ ...form, IsActive: e }))
            setActiveId(!activeId)
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (name, e) => {
        if (e === true) {
            setFormError([])
            setEditDisabled(true);
            if (name === "Active") {
                setFormValue((form) => ({ ...form, IsActive: !activeId }))
                setActiveId(!activeId)
                setModalShow(false);
            }
        }
        else {
            setModalShow(false);
        }
    }
    //#endregion

    //#region checkbox On Change function
    const handleCheck = (name, e) => {
        setFormError([])
        setEditDisabled(true);
        setFormValue((form) => ({ ...form, [name]: e.target.checked }))
    }
    //#endregion

    //#region Validate Data
    const validateData = () => {
        let status = true;
        let error = {};

        if (formValue.TitleId <= 0) {
            error.TitleId = "Kindly select title."
            status = false;
        }
        if (formValue.Link === "") {
            error.Link = "Cannot leave blank."
            status = false;
        }
        if (formValue.DisplayName === "") {
            error.DisplayName = "Kindly enter display name."
            status = false;
        }

        if (Object.keys(error)?.length > 0) {
            let id = Object.keys(error)[0];
            document.getElementById(id)?.focus();
        }
        setFormError(error);
        return status;
    }
    //#endregion

    //#region Add button function
    const handleAddClick = async () => {
        if (validateData()) {
            dispatch(loaderActions.start());
            const statusoftoken = await verifytoken();
            if (statusoftoken) {
                const insertorupdatefooterpart2 = await postUsersData('/master/v1/insertorupdatefooterpart2', formValue)
                dispatch({ type: 'ALERTING', message: insertorupdatefooterpart2?.message })
                dispatch(loaderActions.end());
                if (insertorupdatefooterpart2?.status) {
                    handleClearClick();
                    getfooterpart2list();
                }
            }
            else {
                dispatch(loaderActions.end());
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        }

    }
    //#endregion

    //#region Clear button function
    const handleClearClick = () => {
        setFormError([]);
        setButtonAddEditText("Add");
        setSerachDisable(false);
        setActiveId(false);
        setDisabled(false);
        setEditDisabled(true);
        setFormValue((form) => ({
            ...form,
            TitleDetailsId: 0,
            TitleId: 0,
            DisplayName: "",
            Link: "",
            OpenInNewTab: false,
            IsActive: false,
        }));
    }
    //#endregion

    //#region Table Columns
    const columns = [
        {
            name: 'Title',
            sortable: true,
            float: "left",
            selector: (data) => data?.Title,
        },
        {
            name: 'Display Name',
            sortable: true,
            float: "left",
            selector: (data) => data?.DisplayName
        },
        {
            name: 'Status',
            sortable: true,
            float: "left",
            selector: (data) => data?.Status
        },
        {
            name: 'Edit',
            selector: (data) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!userRights?.URightEdit} ><i className="fa fa-edit" onClick={() => handleEditMode(data?.TitleDetailsId)}></i></button>
        },
        {
            name: 'View',
            selector: (data) => <i className="fa fa-eye" onClick={() => handleViewMode(data?.TitleDetailsId)}></i>
        }
    ];
    //#endregion

    //#region Edit Button Function
    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setButtonAddEditText("Update");
        RetriveData(id);
    }
    //#endregion

    //#region View Button Function
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        RetriveData(id);
    }
    //#endregion

    //#region Retrive Data on Edit/View
    async function RetriveData(id) {
        setFormError([])
        setSerachDisable(true)
        const selectRecord = await getAllData(`/master/v1/getfooterpart2byid?titleDetailsId=${id}`)
        if (selectRecord?.status) {
            setFormValue((form) => ({
                ...form,
                TitleDetailsId: selectRecord?.data?.TitleDetailsId,
                TitleId: selectRecord?.data?.TitleId,
                DisplayName: selectRecord?.data?.DisplayName,
                Link: selectRecord?.data?.Link,
                OpenInNewTab: selectRecord?.data?.OpenInNewTab,
                IsActive: selectRecord?.data?.IsActive,
            }))
            setActiveId(selectRecord?.data?.IsActive);
        }

    }
    //#endregion

    //#region handle search value
    const serachoption = [
        {
            label: 'Title',
            value: '1'
        },
        {
            label: 'Display Name',
            value: '2'
        },
        {
            label: 'Status',
            value: '3'
        },
        {
            label: 'All',
            value: '4'
        },
    ];

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getfooterpart2list();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const matchesSearchValueAll = (issec, fields) => {
        let search = searchvalue
        return fields.some((data) =>
            issec[data]?.toString().toLowerCase().includes(search.toLowerCase())
        );
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            var filteritem = filterItems2;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec?.Title.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec?.DisplayName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec?.Status.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            if (searchby === '4') {
                filterdata = filteritem?.filter((issec) => {
                    let keys = Object.keys(issec)
                    return matchesSearchValueAll(issec, keys) && issec
                });
            }
            setFooterPart2(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getfooterpart2list();
    }
    //#endregion

    return (
        <>
            <div className="mt-5 mb-3 row mx-1">
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">

                                <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12">
                                            <label htmlFor="TitleId" className="col-form-label required" >Title</label>
                                        </div>
                                        <div className=" col-lg-8 col-md-12 col-sm-12">
                                            <Select
                                                id='TitleId'
                                                className="dropdown select_dropdown"
                                                options={footerTitleList}
                                                value={footerTitleList?.filter(function (item) {
                                                    return item?.value === formValue?.TitleId;
                                                })}
                                                isSearchable
                                                onChange={(e) => handleSelect(e)} // assign onChange function
                                                isDisabled={disabled}
                                            />
                                            {formError?.TitleId && <p className="text-danger mb-0"> {formError?.TitleId} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-8"></div>

                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label htmlFor="DisplayName" className="col-form-label required">Display Name</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <input
                                                type='text'
                                                className='form-control'
                                                name="DisplayName"
                                                id="DisplayName"
                                                maxLength={30}
                                                value={formValue?.DisplayName}
                                                onChange={(e) => handleOnChange(e.target.name, onlyAlphabets(e.target.value))}
                                                disabled={disabled}
                                            />
                                            {formError?.DisplayName && <p className="text-danger mb-0"> {formError?.DisplayName} </p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label htmlFor="Link" className="col-form-label required">Link</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <input
                                                type='text'
                                                className='form-control'
                                                name="Link"
                                                id="Link"
                                                value={formValue?.Link}
                                                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                                                disabled={disabled}
                                            />
                                            {formError?.Link && <p className="text-danger mb-0"> {formError?.Link} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                                    <div className='row align-items-center'>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <label className="col-form-label">Active</label>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12" disabled={false}>
                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation("Active", false)}
                                            yesconfirm={() => handleConfirmation("Active", true)}
                                        />
                                    </div>
                                </div>

                                <div className='col-lg-2 col-md-2 col-sm-12 col-12'>
                                    <div className='row align-items-center'>
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor="OpenInNewTab">
                                                Open In New Tab
                                            </label>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="OpenInNewTab"
                                                checked={formValue?.OpenInNewTab}
                                                onChange={(e) => handleCheck("OpenInNewTab", e)}
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                        {
                            buttonaddeditText === "Add" ?
                                <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!userRights?.URightAdd || disabled}>{buttonaddeditText}</button> :
                                <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}

                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>

                    <div className="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input
                                        type="text"
                                        onKeyDown={onKeyDownHandler}
                                        id="btnSearchText"
                                        className="form-control"
                                        placeholder='Enter keyword'
                                        name="SearchValue"
                                        onChange={handleChangeSearchValue}
                                        value={searchvalue || ''}
                                        disabled={serchdisable ? true : false}
                                        autoComplete='off'
                                    />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div className="p-2 text-center">
                                <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                    </div>

                    {
                        footerPart2 &&
                        <CustomTable
                            columns={columns}
                            data={footerPart2}
                        />
                    }
                </form>
            </div>
        </>
    )
}

export default FooterPart2

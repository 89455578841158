import React, { useEffect, useState } from 'react'
import { getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../_actions/loader.actions';
import { alphaNumericspace, isImagePngJpegJpg, onlyAlphabets } from '../../../commonClass/CommonValidation';
import { ImageDelete, ImageUpload } from '../../../../ImageUpload/ImageCommon';
import CustomTable from '../../../../customControl/CustomTable';
import { Rating } from 'react-simple-star-rating';
import Select from 'react-select';

function getDateCombination() {
  var today = new Date();
  let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
  let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
  return date + '_' + time;
}

function Testimonial({ useredit, useradd }) {
  const dispatch = useDispatch();
  const userId = GetLocalStorage();
  const [testimonialList, setTestimonialList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [formError, setFormError] = useState({});
  const [deletedImage, setDeletedImage] = useState([]);

  const [searchvalue, setSearchValue] = useState();
  const [searchby, setSerachBy] = useState('4');
  const [serchdisable, setSerachDisable] = useState(false);
  const [clrshow, setsclshow] = useState(false);


  const [formValue, setFormValue] = useState({
    UserId: userId?.userMasterID,
    CustomerId: 0,
    CustomerName: "",
    PackageName: "",
    Ratings: 0,
    Review: "",
    CustomerImageName: "",
    CustomerImagePath: "",
    CustomerImageFile: "",
  })

  const getTestimoniallist = async (travelCategoryId) => {
    const testimoniallists = await getAllData(`/master/v1/gettestimoniallist`);

    if (testimoniallists?.status) {
      setTestimonialList(testimoniallists?.data)
      setFilterData(testimoniallists?.data)
    }
    else {
      setTestimonialList([])
      setFilterData([])
    }
    dispatch(loaderActions.end());
  }

  //#region List data
  useEffect(() => {
    const fetchData = async () => {
      dispatch(loaderActions.start());
      let statusoftoken = await verifytoken();
      if (statusoftoken) {
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
          getTestimoniallist();
        }
      } else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      }
    }
    fetchData();
  }, []);

  const storeDataInState = (name, value) => {
    setFormError({})
    setFormValue({ ...formValue, [name]: value })
  }

  const handleUploadImageLoad = (e) => {
    setFormError({});
    if (isImagePngJpegJpg(e.target.files[0].name)) {
      const files = e.target.files[0];
      const filecom = getDateCombination();
      const filename = filecom + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');
      setDeletedImage([...deletedImage, formValue.CustomerImagePath]);
      setFormValue({
        ...formValue,
        CustomerImageName: filename,
        CustomerImagePath: 'CMS/testimonial/' + filename,
        CustomerImageFile: files,
      })
      e.target.value = null
    }
    else {
      setFormError({
        ...formError, ['CustomerImagePath']: 'Please upload valid image such as PNG, JPEG, & JPG only.'
      });
    }
    e.target.value = null
  }

  const checkValidation = () => {
    let errorMessages = {};
    let isError = true;

    if (formValue.CustomerName?.trim().length <= 0) {
      errorMessages['CustomerName'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    }
    if (formValue.PackageName?.trim().length <= 0) {
      errorMessages['PackageName'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    }
    if (formValue.Review?.trim().length <= 0) {
      errorMessages['Review'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    }
    if (formValue.Ratings <= 0) {
      errorMessages['Ratings'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    }

    if (!formValue?.CustomerImagePath) {
      errorMessages['CustomerImagePath'] = 'Cannot leave blank, input is mandatory.'
      isError = false;
    } else if (formValue.CustomerImageFile) {
      const files = formValue.CustomerImageFile;
      let fileSize = files.size / 1024;
      fileSize = fileSize / 1024
      if (!isImagePngJpegJpg(files.name) && files) {
        errorMessages['CustomerImagePath'] = 'Please upload valid image such as PNG, JPEG, & JPG only.'
        isError = false;
      } else if (fileSize > 5) {
        errorMessages['CustomerImagePath'] = 'Cannot upload file that exceeds 5 MB.'
        isError = false;
      }
    }

    setFormError(errorMessages);
    return isError;
  }

  const onSubmitTestimonial = async () => {
    const isValid = checkValidation();
    let statusoftoken = await verifytoken();
    if (statusoftoken) {
      if (isValid) {
        dispatch(loaderActions.start());
        const updateTestimonial = await postUsersData('/master/v1/insertorupdatetestimonial', formValue);

        if (updateTestimonial.status) {
          formValue?.CustomerImageFile && await ImageUpload('CMS/testimonial/', formValue?.CustomerImageName, formValue?.CustomerImageFile);
          deletedImage.length > 0 && deletedImage.forEach(async (deletedImagePath) => await ImageDelete(deletedImagePath));
          getTestimoniallist();
          onClearButton();
          dispatch({ type: 'ALERTING', message: updateTestimonial.message })
          dispatch(loaderActions.end());
        }
        else {
          dispatch(loaderActions.end());
          dispatch({ type: 'ALERTING', message: updateTestimonial.message })
          return;
        }
      }
    } else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      dispatch(loaderActions.end());
    }
  }

  const columns = [
    {
      name: 'Sr. No.',
      sortable: true,
      float: "left",
      minWidth: "20px",
      maxWidth: "20px",
      selector: (data, index) => index + 1,
    },
    {
      name: 'Customer Name',
      sortable: true,
      float: "left",
      selector: (data) => data.CustomerName,
    },
    {
      name: 'Package Name',
      sortable: true,
      float: "left",
      selector: (data) => data.PackageName,
    },
    {
      name: 'Ratings',
      sortable: true,
      float: "left",
      selector: (data) => data.Ratings,
    },
    {
      name: 'Edit',
      selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr'
        onClick={() => onEditorView(data, 'edit')}
        disabled={!useredit}
      ><i className="fa fa-edit eye-icon"></i></button>
    },
    {
      name: 'View',
      selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'view')} ><i className="fa fa-eye" ></i></button> 
    }
  ]

  const onEditorView = async (data, type) => {
    dispatch(loaderActions.start());
    let statusoftoken = await verifytoken();
    if (statusoftoken) {
      setSerachDisable(true)
      const review = await getAllData(`/master/v1/gettestimonialbycustomerid?customerId=${data?.CustomerId}`);
      setFormError({});
      if (review?.status) {
        setFormValue(
          {
            UserId: userId?.userMasterID,
            CustomerId: review?.data?.CustomerId,
            CustomerName: review?.data?.CustomerName,
            PackageName: review?.data?.PackageName,
            Ratings: review?.data?.Ratings,
            Review: review?.data?.Review,
            CustomerImageName: review?.data?.CustomerImageName,
            CustomerImagePath: review?.data?.CustomerImagePath,
            CustomerImageFile: "",
            type: type
          }
        )
      }
    } else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      dispatch(loaderActions.end());
    }
    dispatch(loaderActions.end());
  }


  const onClearButton = () => {
    setSerachDisable(false)
    setFormError({});
    setFormValue(
      {
        UserId: userId?.userMasterID,
        CustomerId: 0,
        CustomerName: "",
        PackageName: "",
        Ratings: "",
        Review: "",
        CustomerImageName: "",
        CustomerImagePath: "",
        CustomerImageFile: "",
      }
    )
  }


  //#region handle search value
  const serachoption = [
    {
      label: 'Customer Name',
      value: '1'
    },
    {
      label: 'Package Name',
      value: '2'
    },
    {
      label: 'Ratings',
      value: '3'
    },
    {
      label: 'All',
      value: '4'
    },
  ];

  const handleSearchBy = (e) => {
    setSerachBy(e.value);
  }
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value)
    setsclshow(true)
    if (e.target.value === '') {
      getTestimoniallist();
      setsclshow(false)
    }
  }

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const matchesSearchValueAll = (issec, fields) => {
    let search = searchvalue
    return fields.some((data) =>
      issec[data]?.toString().toLowerCase().includes(search.toLowerCase())
    );
  };

  const handleSearch = () => {
    if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
      var filteritem = filterData;
      var filterdata;
      if (searchby === '1') {
        filterdata = filteritem?.filter(issec => issec?.CustomerName.toLowerCase().includes(searchvalue.toLowerCase()));
      }
      if (searchby === '2') {
        filterdata = filteritem?.filter(issec => issec?.PackageName.toLowerCase().includes(searchvalue.toLowerCase()));
      }
      if (searchby === '3') {
        filterdata = filteritem?.filter(issec => issec?.Ratings?.toString()?.toLowerCase().includes(searchvalue.toLowerCase()));
      }
      if (searchby === '4') {
        filterdata = filteritem?.filter((issec) => {
          let keys = Object.keys(issec)
          return matchesSearchValueAll(issec, keys) && issec
        });
      }
      setTestimonialList(filterdata)
    }
  }


  const clearsearch = () => {
    setSearchValue("");
    setsclshow(false);
    getTestimoniallist();
  }

  return (
    <div className='px-xl-5 py-4 p-xl-4'>
      <div className=" heading_color mb-3">
        <h4>Testimonial</h4>
      </div>

      <form action=" " className=' mb-5' >
        <div className='row'>
          <div className='row col-6 my-1'>
            <div className="col-xl-3 col-12 mb-1">
              <label className="col-form-label required" htmlFor='customer-name'>Customer Name</label>
            </div>
            <div className="col-xl-9 mb-1 col-12">
              <div class="input-group">
                <input
                  type="text" id="customer-name"
                  class="form-control btn_add"
                  placeholder="Customer Name"
                  maxLength={150}
                  name='CustomerName'
                  value={formValue?.CustomerName || ''}
                  onChange={(e) => storeDataInState(e.target.name, onlyAlphabets(e.target.value))}
                  disabled={formValue?.type === 'view' ? true : false}
                />
              </div>
              {formError.CustomerName && <div className='text-danger text-start'>{formError.CustomerName}</div>}
            </div>
          </div>
          <div className='row col-6 my-1'>
            <div className="col-xl-3 col-12 mb-1">
              <label className="col-form-label required" htmlFor='package-name'>Package Name</label>
            </div>
            <div className="col-xl-9 mb-1 col-12">
              <div class="input-group">
                <input
                  type="text" id="package-name"
                  class="form-control btn_add"
                  placeholder="Package Name"
                  maxLength={150}
                  name='PackageName'
                  value={formValue.PackageName || ''}
                  onChange={(e) => storeDataInState(e.target.name, alphaNumericspace(e.target.value))}
                  disabled={formValue?.type === 'view' ? true : false}
                />
              </div>
              {formError.PackageName && <div className='text-danger text-start'>{formError.PackageName}</div>}
            </div>
          </div>
          <div className='row col-6 my-1'>
            <div className="col-xl-3 col-12 mb-1 align-content-center">
              <label className="col-form-label required" htmlFor='Ratings'>Ratings</label>
            </div>
            <div className="col-xl-9 mb-1 col-12 align-content-center">
              <div class="input-group">
                <Rating
                  size={50}
                  allowFraction
                  readonly={formValue?.type === 'view' ? true : false}
                  initialValue={formValue.Ratings}
                  id="Ratings"
                  onClick={(rat) => storeDataInState('Ratings', rat)}
                />
              </div>
              {formError.Ratings && <div className='text-danger text-start'>{formError.Ratings}</div>}
            </div>
          </div>
          <div className='row col-6 my-1'>
            <div className="col-xl-3 col-12 mb-1 align-content-center">
              <label className="col-form-label required" htmlFor='review'>Review</label>
            </div>
            <div className="col-xl-9 mb-1 col-12">
              <div class="input-group">
                <textarea
                  id="review"
                  class="form-control btn_add"
                  placeholder="Review"
                  maxLength={500}
                  name='Review'
                  rows={4}
                  value={formValue.Review || ''}
                  onChange={(e) => storeDataInState(e.target.name, alphaNumericspace(e.target.value))}
                  disabled={formValue?.type === 'view' ? true : false}
                />
              </div>
              {formError.Review && <div className='text-danger text-start'>{formError.Review}</div>}
            </div>
          </div>

          <div className='row col-6 my-1'>
            <div className="col-xl-3 col-12 mb-1">
              <label className="col-form-label required" htmlFor='CustomerImage-id'>Customer Image</label>
            </div>
            <div className="col-xl-9 mb-1 col-12">
              <div class="input-group">
                <input
                  type="file"
                  name='CustomerImage'
                  id="CustomerImage-id"
                  className="form-control btn_add"
                  accept=".png,.jpg,.jpeg"
                  onChange={(e) => handleUploadImageLoad(e)}
                  disabled={formValue?.type === 'view' ? true : false}
                />
                <label className="input-group-text" htmlFor="CustomerImage-id">Browse</label>
              </div>
              {formValue.CustomerImageName && (
                <div className='d-flex justify-content-center align-item-center w-100 '>
                  <div className='text-start w-100 text-break'>{formValue.CustomerImageName}</div>
                </div>
              )}
              {formError.CustomerImagePath && <div className='text-danger text-start'>{formError.CustomerImagePath}</div>}
            </div>
          </div>
        </div>
      </form>

      {/* <div class="row">
        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>

          <div class="p-2 col-lg-2 col-md-4 col-sm-12">
            <div className='clrbutton'>
              <input
                type="text" id="btnSearchText" class="form-control" name="SearchValue" placeholder='Enter keyword'
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              //disabled={serchdisable ? true : false} 
              />
              <button className={`${searchValue.length > 0 ? "d-block" : "d-none"}`} onClick={() => { setSearchValue('') }} type="reset">&times;</button>
            </div>
          </div>

        </div>
      </div> */}

      <div className="row">
        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
          <div className="p-2 col-lg-2 col-md-4 col-sm-12">
            <Select
              className="dropdown"
              options={serachoption}
              value={serachoption?.filter(function (serachoption) {
                return serachoption.value === searchby;
              })}// set list of the dataalue 
              onChange={handleSearchBy} // assign onChange function
              isDisabled={serchdisable ? true : false}
              isSearchable={false}

            />
          </div>
          <div className="p-2 col-lg-2 col-md-4 col-sm-12">
            <div className='clrbutton'>
              <input
                type="text"
                onKeyDown={onKeyDownHandler}
                id="btnSearchText"
                className="form-control"
                placeholder='Enter keyword'
                name="SearchValue"
                onChange={handleChangeSearchValue}
                value={searchvalue || ''}
                disabled={serchdisable ? true : false}
                autoComplete='off'
              />
              <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
            </div>
          </div>
          <div className="p-2 text-center">
            <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
          </div>
        </div>
      </div>

      <CustomTable
        columns={columns}
        // data={searchValue?.trim()?.length > 0 ? tableData : testimonialList}
        data={testimonialList}
        PdfExport={false}
        ExportToExcel={false}
        disabled={false}
      />

      <div class="d-flex justify-content-center">
        {
          formValue?.type === 'edit'
            ? (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
              onClick={() => onSubmitTestimonial()}
              disabled={!useredit}
            >Update</button>
            )
            : (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
              onClick={() => onSubmitTestimonial()}
              disabled={!useradd}
            >Add</button>)
        }

        <button type="button" class="btn btn-primary mx-1" id="btnClear"
          onClick={() => onClearButton({})}
        // disabled={Object.keys(homePageSection).length === 0 ? true : false || homePageSection?.type === 'view' ? true : false}
        >Clear</button>
      </div>
    </div>
  )
}

export default Testimonial
import { Box, FormControlLabel, Radio, Tab, Tabs, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import PolicyTitleAndTabButton from './PolicyTitleAndTabButton';
// import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { onlyNumeric } from '../../../../../../commonClass/CommonValidation';
import { patchUsersData } from '../../../../../../../services/ApiCallingUserServices';
import { useDispatch } from 'react-redux';
import { TimePicker } from '@mui/x-date-pickers';

function Policies({ policies, setPolicies, formError, setFormError, setSave, disabled }) {
  const [cancellationTab, setCancellationTab] = useState('Percent');
  const [paymentTab, setPaymentTab] = useState('Percent');
  const dispatch = useDispatch();

  const changeCancellationTab = (tab) => {
    let clonePolicies = [...policies?.HotelPolicyCollection];
    if (tab === 'Percent') {
      clonePolicies = clonePolicies.filter((policy) => !(policy.IsPaymentPolicy === false && policy.IsPercentage === false));
    } else {
      clonePolicies = clonePolicies.filter((policy) => !(policy.IsPaymentPolicy === false && policy.IsPercentage === true));
    }
    setPolicies({ ...policies, HotelPolicyCollection: clonePolicies.length > 0 ? clonePolicies : [] });
    setCancellationTab(tab);
  };

  const changePaymentTab = (tab) => {
    let clonePolicies = [...policies?.HotelPolicyCollection]
    if (tab === 'Percent') {
      clonePolicies = clonePolicies.filter((policy) => !(policy.IsPaymentPolicy === true && policy.IsPercentage === false))
    } else {
      clonePolicies = clonePolicies.filter((policy) => !(policy.IsPaymentPolicy === true && policy.IsPercentage === true))
    }
    setPolicies({ ...policies, HotelPolicyCollection: clonePolicies.length > 0 ? clonePolicies : [] })
    setPaymentTab(tab)
  };

  useEffect(() => {
    const cancellationTabPercent = policies.HotelPolicyCollection.filter((policy) => (policy.IsPaymentPolicy === false && policy.IsPercentage === true));
    const cancellationFixedAmount = policies.HotelPolicyCollection.filter((policy) => (policy.IsPaymentPolicy === false && policy.IsPercentage === false));
    const PaymentTabPercent = policies.HotelPolicyCollection.filter((policy) => (policy.IsPaymentPolicy === true && policy.IsPercentage === true));
    const PaymentTabFixedAmount = policies.HotelPolicyCollection.filter((policy) => (policy.IsPaymentPolicy === true && policy.IsPercentage === false));

    if (cancellationTabPercent.length > 0) {
      setCancellationTab('Percent');
    } else if (cancellationFixedAmount.length > 0) {
      setCancellationTab('FixedAmount');
    }

    if (PaymentTabPercent.length > 0) {
      setPaymentTab('Percent');
    } else if (PaymentTabFixedAmount.length > 0) {
      setPaymentTab('FixedAmount');
    }
  }, [policies.HotelPolicyCollection])

  const handlePoliciesOnChange = (PolicyDay, Amount, IsPaymentPolicy, IsPercentage) => {
    let numvalue = Amount.replace(/[^0-9]/g, '');
  
    if (IsPercentage && numvalue <= 100 || !IsPercentage) {
      let clonePolicies = [...policies.HotelPolicyCollection];
      const isCheck = clonePolicies.some((policy) => 
        policy.IsPaymentPolicy === IsPaymentPolicy && policy.IsPercentage === IsPercentage && policy.PolicyDay === PolicyDay
      );
  
      if (isCheck) {
        if (numvalue) {
          clonePolicies = clonePolicies.map((policy) => 
            policy.IsPaymentPolicy === IsPaymentPolicy && policy.IsPercentage === IsPercentage && policy.PolicyDay === PolicyDay
              ? { PolicyDay, Amount: Number(numvalue), IsPercentage, IsPaymentPolicy } 
              : policy
          );
        } else {
          clonePolicies = clonePolicies.filter((policy) => 
            !(policy.IsPaymentPolicy === IsPaymentPolicy && policy.IsPercentage === IsPercentage && policy.PolicyDay === PolicyDay)
          );
        }
      } else {
        clonePolicies.push({ PolicyDay, Amount: Number(numvalue), IsPercentage, IsPaymentPolicy });
      };

      setPolicies({ ...policies, HotelPolicyCollection: clonePolicies });
    }
  
    setSave(false);
    setFormError({});
  };

  const handleOnChange = (name, value) => {
    if (name === 'Is24HrCheckInOut') {
      setPolicies({
        ...policies,
        [name]: value,
        IsSpecificCheckInOut: value === true ? false : policies.IsSpecificCheckInOut,
        StartTime24Hr: value === true ? policies.StartTime24Hr : null,
        EndTime24Hr: value === true ? policies.EndTime24Hr : null,

        SpecificStartTime: value === true ? null : policies.SpecificStartTime,
        SpecificEndTime: value === true ? null : policies.SpecificEndTime,
      });
    } else if (name === 'IsSpecificCheckInOut') {
      setPolicies({
        ...policies,
        [name]: value,
        Is24HrCheckInOut: value === true ? false : policies.Is24HrCheckInOut,
        SpecificStartTime: value === true ? policies.SpecificStartTime : null,
        SpecificEndTime: value === true ? policies.SpecificEndTime : null,
        StartTime24Hr: value === true ? null : policies.StartTime24Hr,
        EndTime24Hr: value === true ? null : policies.EndTime24Hr,
      });
    } else {
      setPolicies((pre) => ({ ...pre, [name]: value }));
    }
    setSave(false);
    setFormError({})
  }

  const handlePlicyAmountRemove = async (PolicyDay, IsPaymentPolicy, IsPercentage) => {
    let clonePolicies = [...policies.HotelPolicyCollection];
    const HotelPolicyDetailsId = getPoliciesAmount(IsPaymentPolicy, IsPercentage, PolicyDay)?.HotelPolicyDetailsId

    if (HotelPolicyDetailsId) {
      const deletValue = await patchUsersData(`/master/v1/updatepoliciesbyhotelpolicydetailsid?hotelPolicyDetailsId=${HotelPolicyDetailsId}`);
      if (deletValue.status) {
        clonePolicies = clonePolicies.map((policy) => {
          if (policy.IsPaymentPolicy === IsPaymentPolicy && policy.IsPercentage === IsPercentage && policy.PolicyDay === PolicyDay) {
            policy = { ...policy, Amount: '' }
          }
          return policy
        })

        dispatch({ type: 'ALERTING', message: deletValue.message });
      } else {
        dispatch({ type: 'ALERTING', message: deletValue.message });
      }
    } else {
      clonePolicies = clonePolicies.map((policy) => {
        if (policy.IsPaymentPolicy === IsPaymentPolicy && policy.IsPercentage === IsPercentage && policy.PolicyDay === PolicyDay) {
          policy = { ...policy, Amount: '' }
        }
        return policy
      })
    }
    setPolicies({ ...policies, HotelPolicyCollection: clonePolicies });
  }

  const getPoliciesAmount = (IsPaymentPolicy, IsPercentage, day) => {
    const data = policies.HotelPolicyCollection.find((policy) => (policy.IsPaymentPolicy === IsPaymentPolicy && policy.IsPercentage === IsPercentage && policy.PolicyDay === day))

    return data || ''
  }

  const convertTime = (time) => {
    if (time) {
      const inputDate = new Date(time);

      const hours = inputDate.getHours();
      const minutes = inputDate.getMinutes();
      const formattedTime = `1900-01-01T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

      return formattedTime
    }
    return null
  }

  return (
    <div className='my-2'>
      <div className='policies_border'>
        <h5 className='fw-bold room_header my-3'>Cancellation Policy</h5>

        <PolicyTitleAndTabButton
          title={'You want deduction to be in'}
          ChangeTab={changeCancellationTab}
          value={cancellationTab}
          disabled={disabled}
        />

        <div className='row align-items-center'>
          {cancellationTab === 'Percent' ? (
            <div className='align-items-center justify-content-between my-2' key={'Percent+Cancellation'}>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0 me-3'>1 day before departure, cancellation charges of</p>
                  <TextField
                    placeholder={`Type %`}
                    id='cancellation-Percentage-1'
                    variant="filled"
                    name=''
                    value={getPoliciesAmount(false, true, 1)?.Amount || ''}
                    onChange={(e) => { handlePoliciesOnChange(1, e.target.value, false, true) }}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total cost will be deducted.</p>
                </div>

                {disabled ?
                  <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(1, false, true)}
                  />
                }
              </div>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0'>2 days before departure, cancellation charges of</p>
                  <TextField
                    placeholder={`Type %`}
                    id='cancellation-Percentage-2'
                    variant="filled"
                    value={getPoliciesAmount(false, true, 2)?.Amount || ''}
                    onChange={(e) => handlePoliciesOnChange(2, e.target.value, false, true)}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total cost will be deducted.</p>
                </div>
                {disabled ?
                  <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer"
                    aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(2, false, true)}
                  />
                }
              </div>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0'>3 days before departure, cancellation charges of</p>
                  <TextField
                    placeholder={`Type %`}
                    id='cancellation-Percentage-3'
                    variant="filled"
                    value={getPoliciesAmount(false, true, 3)?.Amount || ''}
                    onChange={(e) => handlePoliciesOnChange(3, e.target.value, false, true)}
                    disabled={disabled}
                  /> <p className='mx-2 mb-0'>
                    % of total cost will be deducted.</p>
                </div>
                {disabled ?
                  <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer"
                    aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(3, false, true)}
                  />
                }
              </div>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0'>4 days before departure, cancellation charges of</p>
                  <TextField
                    placeholder={`Type %`}
                    id='cancellation-Percentage-4'
                    variant="filled"
                    value={getPoliciesAmount(false, true, 4)?.Amount || ''}
                    onChange={(e) => handlePoliciesOnChange(4, e.target.value, false, true)}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total cost will be deducted.</p>
                </div>
                {disabled ?
                  <i class="fa fa-trash text-danger " aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer"
                    aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(4, false, true)}
                  />
                }

              </div>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0'>5 days before departure, cancellation charges of</p>
                  <TextField
                    placeholder={`Type %`}
                    id='cancellation-Percentage-5'
                    variant="filled"
                    value={getPoliciesAmount(false, true, 5)?.Amount || ''}
                    onChange={(e) => handlePoliciesOnChange(5, e.target.value, false, true)}
                    disabled={disabled}
                  /> <p className='mx-2 mb-0'>
                    % of total cost will be deducted.</p>
                </div>
                {disabled ?
                  <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(5, false, true)}
                  />
                }
              </div>
            </div>
          )
            : (
              <div className='align-items-center justify-content-between my-2' key={'fixed-amount+Cancellation'}>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2 me-3'>1 day before departure, cancellation charges of</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='cancellation-fixed-amount-1'
                      variant="filled"
                      value={getPoliciesAmount(false, false, 1)?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(1, e.target.value, false, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'>will be deducted.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(1, false, false)}
                    />}
                </div>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2'>2 days before departure, cancellation charges of</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='cancellation-fixed-amount-2'
                      variant="filled"
                      value={getPoliciesAmount(false, false, 2)?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(2, e.target.value, false, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'>will be deducted.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(2, false, false)}
                    />}
                </div>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2'>3 days before departure, cancellation charges of</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='cancellation-fixed-amount-3'
                      variant="filled"
                      value={getPoliciesAmount(false, false, 3)?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(3, e.target.value, false, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'>will be deducted.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(3, false, false)}
                    />}
                </div>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2'>4 days before departure, cancellation charges of</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='cancellation-fixed-amount-4'
                      variant="filled"
                      value={getPoliciesAmount(false, false, 4)?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(4, e.target.value, false, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'>will be deducted.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(4, false, false)}
                    />}
                </div>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2'>5 days before departure, cancellation charges of</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='cancellation-fixed-amount-5'
                      variant="filled"
                      value={getPoliciesAmount(false, false, 5)?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(5, e.target.value, false, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'> will be deducted.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(5, false, false)}
                    />}
                </div>
              </div>
            )}
        </div>

      </div>

      <div className='policies_border'>
        <h5 className='fw-bold room_header my-3'>Payment Policy</h5>
        <PolicyTitleAndTabButton
          title={'You want Payment to be in'}
          ChangeTab={changePaymentTab}
          value={paymentTab}
          disabled={disabled}
        />

        <div className='row align-items-center'>
          {paymentTab === 'Percent' ? (
            <div className='align-items-center justify-content-between my-2' key={'percent-Payment-Policy'}>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0 me-3'>1 day before departure, </p>
                  <TextField
                    placeholder={`Type %`}
                    id='payment-percentage-1'
                    variant="filled"
                    value={getPoliciesAmount(true, true, 1)?.Amount || ''}
                    onChange={(e) => handlePoliciesOnChange(1, e.target.value, true, true)}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total package cost needs to be paid.</p>
                </div>
                {disabled ?
                  <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(1, true, true)}
                  />}
              </div>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0'>2 days before departure, </p>
                  <TextField
                    placeholder={`Type %`}
                    id='payment-percentage-2'
                    variant="filled"
                    value={getPoliciesAmount(true, true, 2)?.Amount || ''}
                    onChange={(e) => handlePoliciesOnChange(2, e.target.value, true, true)}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total package cost needs to be paid.</p>
                </div>
                {disabled ?
                  <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(2, true, true)}
                  />}
              </div>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0'>3 days before departure, </p>
                  <TextField
                    placeholder={`Type %`}
                    id='payment-percentage-3'
                    variant="filled"
                    value={getPoliciesAmount(true, true, 3)?.Amount || ''}
                    onChange={(e) => handlePoliciesOnChange(3, e.target.value, true, true)}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total package cost needs to be paid.</p>
                </div>
                {disabled ?
                  <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(3, true, true)}
                  />}
              </div>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0'>4 days before departure, </p>
                  <TextField
                    placeholder={`Type %`}
                    id='payment-percentage-4'
                    variant="filled"
                    value={getPoliciesAmount(true, true, 4)?.Amount || ''}
                    onChange={(e) => handlePoliciesOnChange(4, e.target.value, true, true)}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total package cost needs to be paid.</p>
                </div>
                {disabled ?
                  <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(4, true, true)}
                  />}
              </div>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <p className='mx-2 mb-0'>5 days before departure, </p>
                  <TextField
                    placeholder={`Type %`}
                    id='payment-percentage-5'
                    variant="filled"
                    value={getPoliciesAmount(true, true, 5)?.Amount || ''}
                    onChange={(e) => handlePoliciesOnChange(5, e.target.value, true, true)}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total package cost needs to be paid.</p>
                </div>
                {disabled ?
                  <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                  <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(5, true, true)}
                  />}
              </div>
            </div>
          )
            : (
              <div className='align-items-center justify-content-between my-2' key={'fixed-amount-Payment-Policy'} >
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2 me-3'>1 day before departure,</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='payment-fixed-amount-1'
                      variant="filled"
                      value={getPoliciesAmount(true, false, 1)?.Amount || ''}
                      // value={policies.HotelPolicyCollection.find((policy) => (policy.IsPaymentPolicy === true && policy.IsPercentage === false && policy.PolicyDay === 1))?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(1, e.target.value, true, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'> needs to be paid.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(1, true, false)}
                    />}
                </div>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2'>2 days before departure,</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='payment-fixed-amount-2'
                      variant="filled"
                      value={getPoliciesAmount(true, false, 2)?.Amount || ''}
                      // value={policies.HotelPolicyCollection.find((policy) => (policy.IsPaymentPolicy === true && policy.IsPercentage === false && policy.PolicyDay === 2))?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(2, e.target.value, true, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'>needs to be paid.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(2, true, false)}
                    />}
                </div>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2'>3 days before departure,</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='payment-fixed-amount-3'
                      variant="filled"
                      value={getPoliciesAmount(true, false, 3)?.Amount || ''}
                      // value={policies.HotelPolicyCollection.find((policy) => (policy.IsPaymentPolicy === true && policy.IsPercentage === false && policy.PolicyDay === 3))?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(3, e.target.value, true, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'> needs to be paid.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(3, true, false)}
                    />}
                </div>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2'>4 days before departure,</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='payment-fixed-amount-4'
                      variant="filled"
                      value={getPoliciesAmount(true, false, 4)?.Amount || ''}
                      // value={policies.HotelPolicyCollection.find((policy) => (policy.IsPaymentPolicy === true && policy.IsPercentage === false && policy.PolicyDay === 4))?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(4, e.target.value, true, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'> needs to be paid.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(4, true, false)}
                    />}
                </div>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <div className='mx-2'>5 days before departure,</div>
                    <TextField
                      placeholder={`Amount`}
                      inputProps={{ maxLength: 6 }}
                      id='payment-fixed-amount-5'
                      variant="filled"
                      value={getPoliciesAmount(true, false, 5)?.Amount || ''}
                      // value={policies.HotelPolicyCollection.find((policy) => (policy.IsPaymentPolicy === true && policy.IsPercentage === false && policy.PolicyDay === 5))?.Amount || ''}
                      onChange={(e) => handlePoliciesOnChange(5, e.target.value, true, false)}
                      disabled={disabled}
                    />
                    <div className='mx-2'> needs to be paid.</div>
                  </div>
                  {disabled ?
                    <i class="fa fa-trash text-danger" aria-hidden="true" /> :
                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(5, true, false)}
                    />}
                </div>
              </div>
            )}
        </div>
      </div>

      <h5 className='fw-bold room_header my-3'>Check-in & Check-out Policies</h5>
      <div className='row mt-3 align-items-center' >
        <div className='col-6'>
          <h6 className='fw-bold room_header'>Do you have a 24 hours check-in & check-out service ?</h6>
        </div>

        <div className='col-6 d-flex'>
          <FormControlLabel className=''
            control={
              <Radio
                checked={policies?.Is24HrCheckInOut === true ? true : false}
                name='Is24HrCheckInOut'
                onChange={(e) => handleOnChange(e.target.name, true)}
                value="true"
                id='Is24HrCheckInOut-yes'
                inputProps={{ 'aria-label': 'true' }}
                disabled={disabled}
              />
            }
            label="Yes"
          />
          <FormControlLabel className=''
            control={
              <Radio
                checked={policies?.Is24HrCheckInOut === false ? true : false}
                onChange={(e) => handleOnChange(e.target.name, false)}
                value="false"
                id='Is24HrCheckInOut-no'
                name='Is24HrCheckInOut'
                inputProps={{ 'aria-label': 'false' }}
                disabled={disabled}
              />
            }
            label="No"
          />
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col-3 mb-1'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <TimePicker
                id="StartTime24Hr"
                label="Start Time"
                variant="filled"
                value={policies.StartTime24Hr ? dayjs(policies.StartTime24Hr) : null}
                name='StartTime24Hr'
                onChange={(time) => {
                  handleOnChange('StartTime24Hr', convertTime(time));
                  handleOnChange('EndTime24Hr', convertTime(time))
                }}
                ampm={false}
                slotProps={{
                  textField: {
                    className: `w-100  ${policies?.Is24HrCheckInOut && !policies?.StartTime24Hr ? 'red_border' : ''}`,
                    variant: 'filled',
                    endAdornment: <button type="button" className="border-0 bg-transparent btn pt-0">
                      <img className="pt-0 mt-0" alt="" src={require('../../../../../../../assets/img/calendar_month.png')} />
                    </button>,
                    error: formError?.StartTime24Hr ? true : false,
                    helperText: `${formError?.StartTime24Hr ? formError?.StartTime24Hr : ''}`
                  }
                }}
                disabled={!policies?.Is24HrCheckInOut || disabled}
              />
            </div>
          </LocalizationProvider>


        </div>
        <div className='col-3 mb-1'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <TimePicker
                id="EndTime24Hr"
                label="End Time"
                variant="filled"
                value={policies.StartTime24Hr ? dayjs(policies.StartTime24Hr) : null}
                name='EndTime24Hr'
                onChange={(time) => {
                  handleOnChange('StartTime24Hr', convertTime(time));
                  handleOnChange('EndTime24Hr', convertTime(time))
                }}
                ampm={false}
                slotProps={{
                  textField: {
                    className: `w-100  ${policies?.Is24HrCheckInOut && !policies?.StartTime24Hr ? 'red_border' : ''}`,
                    variant: 'filled',
                    endAdornment: <button type="button" className="border-0 bg-transparent btn pt-0">
                      <img className="pt-0 mt-0" alt="" src={require('../../../../../../../assets/img/calendar_month.png')} />
                    </button>,
                    error: formError?.EndTime24Hr ? true : false,
                    helperText: `${formError?.EndTime24Hr ? formError?.EndTime24Hr : ''}`
                  }
                }}
                disabled={true}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>

      <div className='row mt-3 align-items-center' >
        <div className='col-6'>
          <h6 className='fw-bold room_header'>Do you have a specific check-in & check-out service?</h6>
        </div>
        <div className='col-6 d-flex'>
          <FormControlLabel className=''
            control={
              <Radio
                id='IsSpecificCheckInOut-yes'
                name='IsSpecificCheckInOut'
                value="true"
                checked={policies?.IsSpecificCheckInOut === true}
                onChange={(e) => handleOnChange(e.target.name, true)}
                inputProps={{ 'aria-label': 'true' }}
                disabled={disabled}
              />
            }
            label="Yes"
          />
          <FormControlLabel className=''
            control={
              <Radio
                id='IsSpecificCheckInOut-no'
                name='IsSpecificCheckInOut'
                value="false"
                checked={policies?.IsSpecificCheckInOut === false ? true : false}
                onChange={(e) => handleOnChange(e.target.name, false)}
                inputProps={{ 'aria-label': 'false' }}
                disabled={disabled}
              />
            }
            label="No"
          />
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col-3 mb-1'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {/* 24-hour TimePicker with filled variant */}
              <TimePicker
                id="SpecificStartTime"
                label="Start Time"
                variant="filled"
                name='SpecificStartTime'
                value={policies.SpecificStartTime ? dayjs(policies.SpecificStartTime) : null}
                onChange={(time) => {
                  console.log(time, "time")
                  handleOnChange('SpecificStartTime', convertTime(time))
                }}
                ampm={false}
                slotProps={{
                  textField: {
                    className: `w-100  ${policies?.IsSpecificCheckInOut && !policies?.SpecificStartTime ? 'red_border' : ''}`,
                    variant: 'filled',
                    endAdornment: <button type="button" className="border-0 bg-transparent btn pt-0">
                      <img className="pt-0 mt-0" alt="" src={require('../../../../../../../assets/img/calendar_month.png')} />
                    </button>,
                    error: formError?.SpecificStartTime ? true : false,
                    helperText: `${formError?.SpecificStartTime ? formError?.SpecificStartTime : ''}`
                  }
                }}
                disabled={!policies?.IsSpecificCheckInOut || disabled}
              />
            </div>
          </LocalizationProvider>
        </div>
        <div className='col-3 mb-1'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {/* 24-hour TimePicker with filled variant */}
              <TimePicker
                id="SpecificEndTime"
                label="End Time"
                variant="filled"
                name='SpecificEndTime'
                value={policies.SpecificEndTime ? dayjs(policies.SpecificEndTime) : null}
                onChange={(time) => handleOnChange('SpecificEndTime', convertTime(time))}
                ampm={false}
                slotProps={{
                  textField: {
                    className: `w-100  ${policies?.IsSpecificCheckInOut && !policies?.SpecificEndTime ? 'red_border' : ''}`,
                    variant: 'filled',
                    endAdornment: <button type="button" className="border-0 bg-transparent btn pt-0">
                      <img className="pt-0 mt-0" alt="" src={require('../../../../../../../assets/img/calendar_month.png')} />
                    </button>,
                    error: formError?.SpecificEndTime ? true : false,
                    helperText: `${formError?.SpecificEndTime ? formError?.SpecificEndTime : ''}`
                  }
                }}
                disabled={!policies?.IsSpecificCheckInOut || disabled}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  )
}

export default Policies